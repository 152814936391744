import modalNA from '@/components/Modals/modalNoAvalability';
import serviceReservation from '@/helpers/apis/serviceReservation';
import reservationTypes from '@/store/types/reservation';
import whiteLabelTypes from '@/store/types/whiteLabel';
import { ModalProgrammatic as ModalJ } from 'buefy';
import Vue from 'vue';
import serviceBlocking from '@/helpers/apis/serviceBlocking';
import serviceAvailability from '@/helpers/apis/serviceAvailability';

const state = {
  listReservationTypes: [],
  groupVendor: [],
  groupUrlVendor: null,
  blocked: [],
  availabilityCalendar: [],
  reservations: [],
  availability: [],
  type: '',
  schedules: [],
  dataReservationEdit: {},
  minimunPeoples: null,
  sendReservation: false,
  selectedDate: null,
  selectedAdult: 0,
  selectedBoy: 0,
  selectedBaby: 0,
  selectedTypeReservation: 'Normal',
  selectedPeople: null,
  selectedHour: null,
  selectedHourAction: null,
  selectedTable: null,
  selectedZone: false,
  reservationsList: null,
  decorationCosto: 0,
  experienceCost: 0,
  cancelarReservation: false,
  reservationsExecuted: false,
  reservationsUser: (process.browser && window.localStorage.getItem('_reservation')) ? JSON.parse(atob(window.localStorage.getItem('_reservation'))) : null,
  reservationsUserLink: (process.browser && window.localStorage.getItem('_reservation_link')) ? JSON.parse(window.localStorage.getItem('_reservation_link')) : null,
  tip: 0,
  editReservation: false,
  reservationDayUser: false,
  times: null
};

const actions = {
  [reservationTypes.actions.blocked]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceBlocking.post('/blocked/day', vendor.data)
        .then(response => {
          commit(reservationTypes.mutations.setBlocked, response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.availabilityCalendar]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      serviceAvailability.post('/availability/date/range', vendor.data)
        .then(({ data }) => {
          if (vendor.data.unique) {
            resolve(data.data);
          } else {
            commit(reservationTypes.mutations.setAvailabilityCalendar, data.data);
            resolve(data.data);
          }
        })
        .catch(error => {
          commit(reservationTypes.mutations.setAvailabilityCalendar, []);
          reject(error);
        });
    });
  },
  [reservationTypes.actions.schedules]: ({ commit }, vendor) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/schedules', vendor.data)
        .then(response => {
          commit(reservationTypes.mutations.setSchedules, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.reservationNew]: (state, vendor) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('dashboard/reservationId', vendor.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.setUserConfirmReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('dashboard/userConfirmReservation', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.times]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/timeAtentionToday', data.data)
        .then(response => {
          commit(reservationTypes.mutations.setTimes, response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.preReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/preReservation', data.data)
        .then(response => {
          if (parseInt(response.data.code) === 200) {
            data.data.futureReservationsId = parseInt(response.data.futureReservations);
            data.data.vendorAvailability = response.data.vendorAvailability;
            if (data.data.id) {
              data.data.reservationId = data.data.id;
            }
            window.localStorage.setItem('_reservation', btoa(JSON.stringify(data.data)));
            commit(reservationTypes.mutations.setReservationsUser);
          }
          if (parseInt(response.data.code) === 410) {
            ModalJ.open({
              component: modalNA,
              hasModalCard: true,
              canCancel: false,
              scroll: 'keep'
            });
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          } else {
            resolve(response.data);
          }
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.reservationsList]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceReservation.get('reservation/user/' + data.data.userId + '/' + data.data.vendorId)
        .then(({ data }) => {
          commit(reservationTypes.mutations.setReservationsList, data.data);
          resolve(data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.getGiftCardParams]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/params/giftCard', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.validateGiftCard]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/reservation/validate/giftCard', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.addGiftCard]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/add/giftCard', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.updatePreReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/updatePreReservation', data.dat)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.deletePreReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.delete('reservation/preReservation/' + data.data.futureReservationsId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.updateReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.put('reservation/' + data.data.id, data.data)
        .then(response => {
          commit(reservationTypes.mutations.setReservationsUser, response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.cancelarReservation]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.put('reservation/cancelar/' + data.data.id, data.data)
        .then(response => {
          commit(reservationTypes.mutations.setReservationsUser, response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.cancelarReservationEmail]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.put('reservation/cancelarEmail/' + data.data.id)
        .then(response => {
          commit(reservationTypes.mutations.setReservationsUser, response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.reservationUserDate]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('reservation/user/' + data.data.userId + '/' + data.data.vendorId + '/' + data.data.timestamp)
        .then(response => {
          if (parseInt(response.data.code) === 200) {
            commit(reservationTypes.mutations.setReservationDayUser, true);
          }
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.confirmReservation]: (state, data) => {
    return new Promise((resolve, reject) => {
      var url = (data.reservation.isEvent) ? 'reservation/confirmReservationEvent' : 'reservation/confirmReservation';
      Vue.axios.post(url, data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.confirmGiftCard]: (state, data) => {
    return new Promise((resolve, reject) => {
      var url = 'reservation/confirmation/giftCard';
      Vue.axios.post(url, data)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.getMaxPeople]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('reservation/getMaxPeople', data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.reservationDetailById]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('reservation/detail/' + data.data.reservationId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.companions]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.post('global/companions', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.companionList]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.get('global/companions/' + data.data.id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.companionDelete]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.delete('global/companions/' + data.data.id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [reservationTypes.actions.companionUpdate]: (state, data) => {
    return new Promise((resolve, reject) => {
      Vue.axios.put('global/companions/' + data.data.id, data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const getters = {
  [reservationTypes.getters.listReservationTypes]: state => state.listReservationTypes,
  [reservationTypes.getters.blocked]: state => state.blocked,
  [reservationTypes.getters.availabilityCalendar]: state => state.availabilityCalendar,
  [reservationTypes.getters.schedules]: state => state.schedules,
  [reservationTypes.getters.groupUrlVendor]: state => state.groupUrlVendor,
  [reservationTypes.getters.groupVendor]: state => state.groupVendor,
  [reservationTypes.getters.reservations]: state => state.reservations,
  [reservationTypes.getters.selectedDate]: state => state.selectedDate,
  [reservationTypes.getters.reservation]: state => state.reservation,
  [reservationTypes.getters.selectedHour]: state => state.selectedHour,
  [reservationTypes.getters.selectedHourAction]: state => state.selectedHourAction,
  [reservationTypes.getters.selectedTable]: state => state.selectedTable,
  [reservationTypes.getters.selectedZone]: state => state.selectedZone,
  [reservationTypes.getters.decorationCosto]: state => state.decorationCosto,
  [reservationTypes.getters.times]: state => state.times,
  [reservationTypes.getters.getExperienceCost]: state => state.experienceCost,
  [reservationTypes.getters.getTip]: state => state.tip,
  [reservationTypes.getters.reservationsUser]: state => state.reservationsUser,
  [reservationTypes.getters.reservationsUserLink]: state => state.reservationsUserLink,
  [reservationTypes.getters.reservationsExecuted]: state => state.reservationsExecuted,
  [reservationTypes.getters.cancelarReservation]: state => state.cancelarReservation,
  [reservationTypes.getters.selectedAdult]: state => state.selectedAdult,
  [reservationTypes.getters.dataReservationEdit]: state => state.dataReservationEdit,
  [reservationTypes.getters.minimunPeoples]: state => state.minimunPeoples,
  [reservationTypes.getters.sendReservation]: state => state.sendReservation,
  [reservationTypes.getters.selectedBoy]: state => state.selectedBoy,
  [reservationTypes.getters.selectedBaby]: state => state.selectedBaby,
  [reservationTypes.getters.selectedPeople]: state => state.selectedPeople,
  [reservationTypes.getters.reservationDayUser]: state => state.reservationDayUser,
  [reservationTypes.getters.editReservation]: state => state.editReservation,
  [reservationTypes.getters.reservationsList]: state => state.reservationsList,
  [reservationTypes.getters.selectedTypeReservation]: state => state.selectedTypeReservation
};

const mutations = {
  [reservationTypes.mutations.setSelectedAdult] (state, data) {
    state.selectedAdult = data;
  },
  [reservationTypes.mutations.setSelectedTypeReservation] (state, data) {
    state.selectedTypeReservation = data;
  },
  [reservationTypes.mutations.setSelectedHour] (state, selectedHour) {
    state.selectedHour = selectedHour;
  },
  [reservationTypes.mutations.setSelectedHourAction] (state, selectedHour) {
    state.selectedHourAction = selectedHour;
  },
  [reservationTypes.mutations.setDataReservationEdit] (state, dataReservationEdit) {
    state.dataReservationEdit = dataReservationEdit;
  },
  [reservationTypes.mutations.setMinimunPeoples] (state, minimunPeoples) {
    state.minimunPeoples = minimunPeoples;
  },
  [reservationTypes.mutations.setSendReservation] (state, sendReservation) {
    state.sendReservation = sendReservation;
  },
  [reservationTypes.mutations.setSelectedZone] (state, data) {
    state.selectedZone = data;
  },
  [reservationTypes.mutations.setSelectedTable] (state, data) {
    state.selectedTable = data;
  },
  [reservationTypes.mutations.setSelectedPeople] (state, selectedPeople) {
    state.selectedPeople = selectedPeople;
  },
  [reservationTypes.mutations.setSelectedBoy] (state, data) {
    state.selectedBoy = data;
  },
  [reservationTypes.mutations.setSelectedBaby] (state, data) {
    state.selectedBaby = data;
  },
  [reservationTypes.mutations.setReservationTypes] (state, data) {
    state.listReservationTypes = data;
  },
  [reservationTypes.mutations.setTimes] (state, times) {
    state.times = times;
  },
  [reservationTypes.mutations.setEditReservation] (state, editReservation) {
    state.editReservation = editReservation;
  },
  [reservationTypes.mutations.setBlocked] (state, blocked) {
    state.blocked = blocked;
  },
  [reservationTypes.mutations.setAvailabilityCalendar] (state, availabilityCalendar) {
    state.availabilityCalendar = availabilityCalendar;
  },
  [reservationTypes.mutations.setSchedules] (state, schedules) {
    state.schedules = schedules;
  },
  [reservationTypes.mutations.setGroupUrlVendor]: (state, data) => {
    state.groupUrlVendor = data;
  },
  [reservationTypes.mutations.setGroupVendor]: (state, data) => {
    state.groupVendor = data;
  },
  [reservationTypes.mutations.setSelectedDate] (state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  [reservationTypes.mutations.setReservation] (state, reservation) {
    state.reservation = reservation;
  },
  [reservationTypes.mutations.setDecorationCosto] (state, data) {
    state.decorationCosto = data;
  },
  [reservationTypes.mutations.setExperienceCost] (state, data) {
    state.experienceCost = data;
  },
  [reservationTypes.mutations.setTip] (state, data) {
    state.tip = data;
  },
  [reservationTypes.mutations.setReservationsExecuted] (state, reservationsExecuted) {
    state.reservationsExecuted = reservationsExecuted;
  },
  [reservationTypes.mutations.setReservationsList] (state, reservationsList) {
    state.reservationsList = reservationsList;
  },
  [reservationTypes.mutations.setReservationsUser] (state) {
    state.reservationsUser = (process.browser && window.localStorage.getItem('_reservation')) ? JSON.parse(atob(window.localStorage.getItem('_reservation'))) : {};
  },
  [reservationTypes.mutations.setReservationsUserLink] (state) {
    state.reservationsUserLink = (process.browser && window.localStorage.getItem('_reservation_link')) ? JSON.parse(window.localStorage.getItem('_reservation_link')) : {};
  },
  [reservationTypes.mutations.setCancelarReservation] (state, cancelarReservation) {
    state.cancelarReservation = cancelarReservation;
  },
  [reservationTypes.mutations.setReservationDayUser] (state, reservationDayUser) {
    state.reservationDayUser = reservationDayUser;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
