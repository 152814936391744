|<template>
  <b-modal :active.sync="value" :can-cancel="false" id="modal-success-waitinglist">
    <button @click="close()" class="btn-close-modal-plate" type="button">
      <img
        alt="icon point green"
        src="../../assets/images/icons/icon_btn_close.svg"
        style="margin-bottom:-5px"
      />
    </button>
    <div class="modals-bosy" v-if="dateString != null">
      <div class="center-icon">
        <p class="span-text-waitinglist" v-if="vendor.id !== 784 && vendor.id !== 786">{{ $t('waitingList') }}</p>
        <p class="span-text-waitinglist" v-if="vendor.id === 784 || vendor.id === 786">Anuncio de visita</p>
      </div>
      <div class="Content-modal-text text-waitinglist">
        <span v-if="vendor.id !== 784 && vendor.id !== 786">
          <i class="icon-success-waitinglist"></i>
          {{ $t('modalSuccessWaitingList.successWaitingList') }}
        </span>
        <span v-if="vendor.id === 784 || vendor.id === 786">
          <i class="icon-success-waitinglist"></i>
          Han sido ingresados tus datos en el anuncio de visita.
        </span>
      </div>
      <div class="body_data_reservation">
        <div class="container_info_main">
          <div>
            <h6>
              <i class="icons_data icon_people"></i>
              {{ people }} {{ $t('modalSuccessWaitingList.people') }}
            </h6>
          </div>
          <div>
            <h6>
              <i class="icons_data icon_calendar_data"></i>
              {{ new Date(date) | moment('timezone', vendorTimezone, "DD/MM/YYYY") }}
            </h6>
          </div>
          <div>
            <h6>
              <i class="icons_data icon_clock_checkout_data"></i>
              {{ new Date(date) | moment('timezone', vendorTimezone, "hh:mm A") }}
            </h6>
          </div>
        </div>
      </div>
      <div class="bottom-modal-waitinglist" style="margin-top: 20px">
        <p
          class="span-text-waitinglist"
        >{{ $t('modalSuccessWaitingList.thanks') }}</p>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
export default {
  props: ['value'],
  data () {
    return {
      dateString: null
    };
  },
  mounted () {
    this.dateString = this.$moment(this.date).format('YYYY-MM-DD hh:mm a');
  },
  methods: {
    close () {
      this.$store.commit(whiteLabelTypes.mutations.setPopupSuccessWaitingList, false);
      this.$store.commit(reservationTypes.mutations.setSelectedHourAction, null);
    }
  },
  computed: {
    ...mapGetters({
      date: reservationTypes.getters.selectedHourAction,
      people: reservationTypes.getters.selectedPeople,
      vendorId: whiteLabelTypes.getters.vendorId
    })
  }
};
</script>
<style lang="scss">
#modal-success-waitinglist {
  .modals-bosy {
    padding: 30px 20px 40px;
  }
  .container_info_main {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    h6 {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin: 0 5px 0 5px;
      color: var(--bgColorCheckout);
    }
  }
  .icons_data {
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    display: flex;
  }
  .body_data_reservation {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    justify-content: center;
    font-weight: 700;
    color: var(--bgColorCheckout);
    h6 {
      color: var(--bgColorCheckout);
    }
  }
  .icon_clock_checkout_data {
    -webkit-mask-image: url(../../assets/images/icons/icon_clock_checkout.svg);
    mask-image: url(../../assets/images/icons/icon_clock_checkout.svg);
    mask-size: contain;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    background-color: var(--bgColorCheckout);
  }
  .icon_people {
    -webkit-mask-image: url(../../assets/images/icons/icon_guest_users.svg);
    mask-image: url(../../assets/images/icons/icon_guest_users.svg);
    mask-size: contain;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    background-color: var(--bgColorCheckout);
  }
  .icon_calendar_data {
    -webkit-mask-image: url(../../assets/images/icons/icon_calendar_data.svg);
    mask-image: url(../../assets/images/icons/icon_calendar_data.svg);
    mask-size: contain;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    background-color: var(--bgColorCheckout);
  }
  .animation-content.modal-content {
    max-width: 420px !important;
  }

  .span-text-waitinglist {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-family: sourceSansPro;
    font-weight: bold;
    text-align: center;
  }

  .bottom-modal-waitinglist {
    display: flex;
    justify-content: center;
  }
  .icon-success-waitinglist {
    cursor: pointer;
    background-image: url(../../assets/images/icons/successwaitinglist.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 20px;
    margin-bottom: -4px;
    margin-right: 10px;
    width: 20px;
  }
  .text-waitinglist {
    margin-top: 15px;
    display: flex;
    text-align: center;
    align-content: center;
    font-size: 15px;
    font-weight: 700;
  }
}
.btn-close-modal-plate {
  float: right;
  position: absolute;
  right: 22px;
  top: 18px;
  border: 0;
  background: transparent;
  cursor: pointer;
}
</style>
