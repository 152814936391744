<template>
  <div class="Reservation" v-if="design && vendor && params">
    <b-message :active="popupAlertEvent" id="messDelete">
      <div class="cont-info">
        <i class="icon-alert"></i>
        <span>
          <div class="center-flex">
            <span class="text size-14 bold-400 center" v-html="`${getAvailabilityMessage()}`"></span>
          </div>
        </span>
      </div>
    </b-message>
    <div style="width:100%;">
      <div :class="{'mtop60':(design.typeLogo == 'border')}" class="columns Reservation-content">
        <div class="column is-vcentered Reservation-card is-mobile" id="selectPeople">
          <h1 class="Reservation-title">{{ $t('select') }}</h1>
          <People
            :maxPeople="maxPeople"
            @corporative=" corporative= $event"
            @dangerPeopleEvent="dangerPeopleProcess($event)"
            @dangerPeopleMaxEvent="dangerPeopleProcess($event)"
            @people="people = $event"
          ></People>
        </div>
        <div class="column is-vcentered Reservation-card is-mobile" id="selectDate">
          <a
            :style="{color:design.cardBackgroundColor}"
            @click="previusSection"
            class="previus-btn"
            href="#"
          >
            <i class="fa fa-chevron-left d-flex"></i>
          </a>
          <h1 class="Reservation-title">{{ $t('date') }}</h1>
          <p class="Reservation-subtitle">{{ $t('chooseAvailableDate') }}</p>
          <div class="Reservation-divider" style="margin-bottom: 0px;"></div>
          <div class="Reservation-DateTime">
            <dateTime
              :color1="design.color1"
              :editPeople="false"
              :color2="design.color2"
              :colorCalendar="design.calendarColor"
              @closeAlerts="Alerts"
              @experiences="onExperiences"
              @show-modal-gaira-message="showMessageGaira = true, showMessageGairaFin = $event"
              @show-modal-select-zone="selectZone($event)"
              @show-modal-select-zone-map="selectZoneMap($event)"
              @show-modal-tramonti="showTramonti = $event"
              @error="manageError"
            ></dateTime>
          </div>
        </div>
        <div class="column is-vcentered Reservation-card is-mobile" id="selectHour">
          <a
            :style="{color:design.cardBackgroundColor}"
            @click="previusSection"
            class="previus-btn"
            href="#"
          >
            <i class="fa fa-chevron-left d-flex"></i>
          </a>
          <h1 class="Reservation-title">{{ $t('hour') }}</h1>
          <p
            class="Reservation-subtitle"
            v-if="Array.isArray(avalibilityHour) && avalibilityHour.length > 0 || avalibilityHour == null"
          >{{ (!isEventDayGlobal || dateTimeEventGlobal) ? $t('selectAvailableHour') : $t('selectWhereBook') }}</p>

          <p
            class="Reservation-subtitle"
            v-if="loadHour"
          >
            <br />
            {{ $t('loadingHours') }}
          </p>
          <p
            class="Reservation-subtitle"
            v-else-if="errorTimeOut"
          >
            <br />
            {{ $t('connectionError') }}
          </p>
          <p
            class="Reservation-subtitle"
            v-else-if="Array.isArray(avalibilityHour) && avalibilityHour.length == 0 && !loadHour"
          >
            <br />
            {{ $t('noSchedulesAvailable') }}
          </p>
          <div class="Reservation-divider" style="margin-bottom: 0px; width: 70%"></div>
          <div
            class="brad-content"
            v-if="avalibilityHour && !loadHour && isEventDayGlobal && !dateTimeEventGlobal && !errorTimeOut"
          >
            <button
              :style="{ color: design.color1, border:'1px solid '+ design.color1 }"
              @click="changeEventDayCommit"
              class="btn-sm btn-hour bradius3"
              style="margin-bottom: 15px;"
              type="button"
              v-if="avalabilityInEventDay()"
            >{{ $t('normalBooking') }}</button>
            <button
              :key="'event-'+ind"
              :style="{ color: design.color1, border:'1px solid '+ design.color1 }"
              @click="getTimeEventId(ev)"
              class="btn-sm btn-hour bradius3"
              style="margin-bottom: 15px;"
              type="button"
              v-for="(ev, ind) in isEventDayGlobal"
            >{{ $t('bookNow') }} {{ ev.displayName }}</button>
          </div>
          <hour
            :sectionSelect="loadHour"
            @showAction="showActionSelect = $event"
            @hourSelected="handleHourSelected"
            @closeAlerts="Alerts"
            @experiences="onExperiences"
            @show-modal-gaira-message="showMessageGaira = true, showMessageGairaFin = $event"
            @show-modal-select-zone="selectZone($event)"
            @show-modal-select-zone-map="selectZoneMap($event)"
            @show-modal-tramonti="showTramonti = $event"
            v-if=" !loadHour && !isEventDayGlobal && (section === 3 || !isMob)"
          ></hour>
          <hourEvent
            :event="isEventDayGlobal"
            :time="dateTimeEventGlobal"
            @setPeopleEvent="minPeopleEvent"
            v-if="Array.isArray(avalibilityHour) && avalibilityHour.length > 0 && !loadHour && isEventDayGlobal && dateTimeEventGlobal"
          ></hourEvent>
          <i class="icon-mks icon-precompro-loader-new snipper" v-if="loadHour"></i>
        </div>
      </div>
      <div style="width:100%;">
        <avalibilityGroup v-if="params.saleCrusade"></avalibilityGroup>
      </div>
    </div>

    <b-modal :active.sync="corporative" :can-cancel="false">
      <div class="modals-bosy">
        <button @click="corporative = false" class="modal-close-btn" type="button">
          <span class="Reservation-title">&times;</span>
        </button>
        <h3 class="title-modal">{{ $t('information') }}</h3>
        <div
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px; "
        >{{ $t('contactNeedNumberPerson') }}</div>
        <span
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px;"
          v-if="vendor.phoneEvent"
        >
          <b>{{ $t('phone') }}:</b>
          {{ vendor.phoneEvent }}
        </span>
        <span
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px;"
          v-else-if="vendor.phone"
        >
          <b>{{ $t('phone') }}:</b>
          {{ vendor.phone }}
        </span>
        <br />
        <span
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px;"
          v-if="vendor.emailEvent"
        >
          <b>{{$t('email')}}:</b>
          {{ vendor.emailEvent }}
        </span>
        <span
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px;"
          v-else-if="vendor.email"
        >
          <b>{{$t('email')}}:</b>
          {{ vendor.email }}
        </span>
      </div>
    </b-modal>

    <b-modal :active.sync="reservationDayUser" :can-cancel="false">
      <div
        class="modals-bosy-little"
        v-bind:style="getBackgroundColor(design.backgroundColorPopup, 'back')"
      >
        <button @click="closeReservationDayUser" class="modal-close-btn" type="button">
          <span
            class="Reservation-title pt-9"
            v-bind:style="getBackgroundColor(design.backgroundColorPopup,'p')"
          >&times;</span>
        </button>
        <div class="black-label" v-bind:style="getBackgroundColor(design.headerPopup)">
          <div
            class="button-circle"
            v-bind:style="
            getBackgroundColor(
              design.circleBackgroundPopup,
              'btn',
              design.circleContentColor
            )
          "
          >
            <span class="span-text-info">i</span>
          </div>
        </div>
        <div class="Content-modal-text">
          <div
            class="text-info pd-9"
            style="text-align:center;margin-bottom:20px;padding-top:5px;padding-bottom:5px; "
            v-bind:style="getBackgroundColor(design.colorPopup, 'p')"
          >
            <strong>{{ $t('foundReservation') }}</strong>
            <br />
            {{ $t('continueReservation') }}
          </div>
        </div>
        <div class="btns-modal">
          <button @click="goAccontUser" class="full-btn-modal" type="button">{{ $t('seeBooking') }}</button>
          <button
            @click="closeReservationDayUser"
            class="full-btn-modal btn-modal-fill"
            type="button"
            v-bind:style="
            getBackgroundColor(
              design.headerPopup,
              'btn',
              design.colorCheckboxTerms
            )
          "
          >{{ $t('continue') }}</button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="send705" :can-cancel="false">
      <div class="modals-bosy">
        <button @click="close705" class="modal-close-btn" type="button">
          <span class="Reservation-title">&times;</span>
        </button>
        <h3 class="title-modal">{{ $t('information') }}</h3>
        <div
          class="general-subtitle"
          style="text-align:center;margin-bottom:0px;"
        >{{ $t(`noAvailableNumnberPerson`) }}.</div>
        <div class="btns-modal">
          <button
            @click="close705"
            class="full-btn-modal btn-modal-fill"
            style="width:100% !important;"
            type="button"
          >{{ $t('continue') }}</button>
        </div>
      </div>
    </b-modal>

    <!-- Modal max people -->
    <b-modal :active.sync="showMaxPeopleModal" :can-cancel="false" id="more-people-modal">
      <div class="body-modal">
        <i class="icon-mks icon_round_warning"></i>
        <div class="general-subtitle" v-if="params.messageCustomValidatePerson"
          v-html="params.messageCustomValidatePerson">
        </div>
        <div class="general-subtitle" v-else-if="showDefaultMessagePeople">
          <p v-html="$t('morePeopleMessageDefault')"></p>
        </div>
        <div class="general-subtitle" v-else-if="getTypeLimitPeople() === 'hour'">
          <p v-html="$t('morePeopleMessage', {peopleMaxTranslate: maxPeople})"></p>
        </div>
        <div class="general-subtitle" v-else-if="getTypeLimitPeople() === 'date'">
          <p v-html="$t('morePeopleMessageDate', {peopleMaxTranslate: maxPeople})"></p>
        </div>
        <span class="phone-container">
          <a :href="'tel:'+phone.original" class="phone-number" v-for="(phone, index) in phoneNumbers" :key="'phone' + index">
            <i class="icon-mks icon_phone_filled"></i>
            {{phone.formatted}}
          </a>
          <a :href="'https://wa.me/'+phone.original" target="_blank" class="phone-number"
            v-for="(phone, index) in whatsAppNumbers" :key="'wpp' + index">
            <i class="icon-mks icon_whatsapp_outlined"></i>
            {{phone.formatted}}
          </a>
        </span>
        <div class="btns-modal">
          <button
            @click="setCloseModalPeopleMax()"
            class="btn-modal"
            type="button"
          >{{ $t('close') }}</button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="showTramonti" :can-cancel="false">
      <div class="modals-bosy">
        <button @click="showTramonti = false" class="modal-close-btn" type="button">
          <span class="Reservation-title">&times;</span>
        </button>
        <h3 class="title-modal">NAVIDAD EN TRAMONTI</h3>
        <div class="general-subtitle" style="text-align:center;margin-bottom:0px;">
          Este 24 de diciembre celebra Navidad en Tramonti.
          <br />
          <br />Para esta cena especial tendremos los mejores platos de nuestro menú tradicional a la carta, especialidad navideña, villancicos, DJ Queen.
          <br />Puedes traer tus regalos que Papá Noel los entregará en tu mesa.
          <br />Vive la Navidad de una manera diferente.
          <br />Tramonti cobrará un cover que dará acceso a la celebración.
          <br />
          <br />Precio por una persona adulta
          <b>$50.000</b>
          <br />Precio para niños menores de 10 años
          <b>$20.000</b>
          <br />
          <br />
          <b>- No incluye consumos de alimentos ni bebidas</b>
        </div>
        <div class="btns-modal">
          <button
            @click="showTramonti = false"
            class="full-btn-modal btn-modal-fill"
            style="width:100% !important;"
            type="button"
          >{{ $t('continue') }}</button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="showMessageGaira" :can-cancel="false">
      <div class="modals-bosy">
        <button @click="showMessageGaira = false" class="modal-close-btn" type="button">
          <span class="Reservation-title">&times;</span>
        </button>
        <h3 class="title-modal">Para tener en cuenta</h3>
        <div class="general-subtitle" style="text-align:center;margin-bottom:0px;">
          Para los días de lunes a jueves los menores de edad pueden estar en el restaurante hasta las
          <b>9:00pm</b>,
          para los días viernes y sábado no se permite el ingreso de menores de edad en las horas de la noche
        </div>
        <div
          class="general-subtitle"
          style="text-align: center; margin-bottom: 0px;"
          v-if="showMessageGairaFin"
        >
          <br/>
          La modalidad que manejamos para <strong>viernes</strong> y <strong>sábado</strong> es la siguiente:<br/><br/>
          <strong>Cover $ 45.000 mil pesos no consumibles por persona</strong>, donde te garantizamos el ingreso al Cumbia House y a todos los shows de la noche, la ubicación se determinará por orden de registro recibidos.
          El horario de ingreso es a partir de las <strong>7:00 PM</strong> y recuerda que entre más temprano llegues mejor será tu ubicación.<br/><br/>
          Show: Contamos con los artistas de la casa, los cumbieros y DJ durante la noche.
          Nota: Por favor no olvides dejar los menores de edad en casa para que puedas disfrutar de todos los menjurjes y nuestra experiencia gastronómica, no se permite ingreso de alimentos, bebidas ni decoración, recuerda no hacemos devolución de dinero.
          <br/><br/>
          <strong>¡LOS ESPERAMOS!</strong>
        </div>
        <div class="btns-modal">
          <button
            @click="showMessageGaira = false"
            class="full-btn-modal btn-modal-fill"
            style="width:100% !important;"
            type="button"
          >{{ $t('continue') }}</button>
        </div>
      </div>
    </b-modal>

    <ModalInformationAction
      :blockedList="isBlockedList"
      @closeShowAction="showActionSelect = $event"
      v-if="showActionSelect"
      v-model="showActionSelect"
    ></ModalInformationAction>
    <ModalWaitingList v-if="popupWaitingList" v-model="popupWaitingList"></ModalWaitingList>
    <ModalSuccessWaitingList v-if="popupSuccessWaitingList" v-model="popupSuccessWaitingList"></ModalSuccessWaitingList>
    <!-- <PopUp3Cordilleras v-model="popupAlert3Cordilleras" v-if="popupAlert3Cordilleras"></PopUp3Cordilleras>
    <PopUp3Cordilleras v-model="popupAlert3Cordilleras" v-if="popupAlert3Cordilleras"></PopUp3Cordilleras>-->
    <SelectZone :prevDate="prevDate" v-if="showSelectZone" v-model="showSelectZone" @closeModalZone="maxPeople = 999"></SelectZone>
    <GairaMap
      :prevDate="prevDate"
      @hide-select-zone-map="showSelectZoneMap = false"
      @closeModalGairaMap="maxPeople = 999"
      v-if="showSelectZoneMap && params.isMapped && vendor.id == 72 && selectedTypeReservation == 'Rumba'"
    ></GairaMap>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import eventTypes from '@/store/types/event';
import reservationTypes from '@/store/types/reservation';
import hour from '@/components/Select/hour';
import 'hooper/dist/hooper.css';
import hourEvent from '@/components/Select/hourEvent';
import dateTime from '@/components/Select/dateTime';
import avalibilityGroup from '@/components/Select/avalibilityGroup'; // Disponibilidad en otros restaurantes
import SelectZone from './selectZone';
// import PopUp3Cordilleras from "./PopUp3Cordilleras.vue";
import ModalWaitingList from './ModalWaitingList.vue';
import ModalSuccessWaitingList from './ModalSuccessWaitingList.vue';
import { mapGetters } from 'vuex';
import GairaMap from './gairaMap';
import People from '@/components/Select/people';
import ModalInformationAction from '@/components/Select/ModalInformationAction';
import _ from 'lodash';

export default {
  components: {
    hour,
    dateTime,
    hourEvent,
    avalibilityGroup,
    SelectZone,
    GairaMap,
    People,
    ModalWaitingList,
    ModalSuccessWaitingList,
    ModalInformationAction
  },
  data () {
    return {
      now: new Date(),
      people: false,
      isEventDay: false,
      minimunPeople: 0,
      dateTimes: null,
      dateTimeEvent: null,
      corporative: false,
      morePeople: false,
      showSelectZone: false,
      showSelectZoneMap: false,
      showMaxPeopleModal: false,
      prevDate: false,
      experiences: false,
      showExperiences: false,
      maxPeople: 9999,
      eventIdStatic: null,
      showMessageGaira: false,
      showMessageGairaFin: false,
      showActionSelect: false,
      showTramonti: false,
      errorTimeOut: false,
      isMob: false,
      isBlockedList: false,
      showDefaultMessagePeople: true
    };
  },
  mounted () {
    this.isMob = ((parseInt(window.innerWidth) <= 959));
    window.addEventListener('resize', () => {
      this.isMob = ((parseInt(window.innerWidth) <= 959));
    });
    // se cambia el eventIdStatic a global
    /* if (this.selectedDate && this.eventIdStatic) {
      this.getTimeEventId(this.eventIdStatic); */
    if (this.selectedDate && this.eventIdStaticGlobal) {
      this.getTimeEventId(this.eventIdStaticGlobal);
    } else {
      // se cambia el eventIdStatic a global
      /* this.eventIdStatic = null; */
      this.$store.commit(eventTypes.mutations.setEventIdStaticGlobal, null);

      this.$store.commit(eventTypes.mutations.setEventPrice, 0);
    }
    let data = this.$route.query;
    let isQuery = Object.keys(data).length;
    this.$store.commit(reservationTypes.mutations.setSendReservation, false);
    this.$store.commit(reservationTypes.mutations.setReservationDayUser, false);
    this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
    if (!this.params.listReservationType && this.vendor.id !== 170) {
      this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
    }
    this.$store.commit(reservationTypes.mutations.setSelectedTable, false);
    this.minimunPeople = this.params.allowSingleReservations
      ? this.params.allowSingleReservations
      : 1;
    this.people = (data.selectedPeople)
      ? data.selectedPeople
      : this.minimunPeople;
    if (this.selectedTypeReservation === 'Pombo' || this.vendor.id === 118 || this.vendor.id === 231 || this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
      if (this.vendor.id === 72) {
        this.people = 2;
      }
      this.$store.commit(reservationTypes.mutations.setSelectedAdult, this.minimunPeople);
      this.$store.commit(reservationTypes.mutations.setSelectedBoy, 0);
      this.$store.commit(reservationTypes.mutations.setSelectedBaby, 0);
    }
    if (
      this.selectedTypeReservation !== 'Pombo' &&
      this.selectedTypeReservation !== 'Rumba' &&
      this.selectedTypeReservation !== 'Cena'
    ) {
      this.messageAnticipe(this.people, this.params);
    } else {
      let payPombo = {
        minimumChargedPeople: 1,
        purchaseCost: 100
      };
      this.messageAnticipe(this.people, payPombo);
    }
    this.$on('isEventDay', e => {
      this.probeIfEvent(e);
    });
    if (isQuery >= 1) {
      // Si existe el dia seleccionado
      if (data.isEvent) {
        this.$store
          .dispatch({
            type: 'event:allEvents',
            data: { vendorId: this.$store.getters['whiteLabel:vendorId'] }
          })
          .then(() => {
            let dateInit = this.nowInitial();
            let event = this.setSelectedDates(
              this.selectedDate,
              dateInit,
              this.allEvents
            );
            // se cambia el isEventDay a global
            this.isEventDay = event.evento;
            this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, event.evento);
          })
          .then(() => {
            let dateInit = this.nowInitial();
            let event = this.setSelectedDates(
              this.selectedDate,
              dateInit,
              this.allEvents
            );
            // se cambia el isEventDay a global
            this.isEventDay = event.evento;
            this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, event.evento);
          });
      }
    }
  },
  computed: {
    ...mapGetters({
      allEvents: eventTypes.getters.allEvents,
      popupAlertEvent: eventTypes.getters.popupAlertEvent,
      popupAlert3Cordilleras: whiteLabelTypes.getters.popupAlert3Cordilleras,
      popupWaitingList: whiteLabelTypes.getters.popupWaitingList,
      popupSuccessWaitingList: whiteLabelTypes.getters.popupSuccessWaitingList,
      dateTimeEventGlobal: eventTypes.getters.dateTimeEventGlobal,
      eventIdStaticGlobal: eventTypes.getters.eventIdStaticGlobal,
      isEventDayGlobal: eventTypes.getters.isEventDayGlobal
    }),
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
    phoneNumbers () {
      if (this.vendor.phone) {
        return this.formatNumbers(this.vendor.phone);
      }
      return [];
    },
    whatsAppNumbers () {
      if (this.vendor.whatsapp) {
        return this.formatNumbers(this.vendor.whatsapp);
      }
      return [];
    }
  },
  methods: {
    formatNumbers (numbersString) {
      const numbers = numbersString.split(',');
      const formattedNumbers = numbers.map(number => {
        const cleanedNumber = number.replace(/\D/g, '');

        const formatWithCountry = /^(\d{2})(\d{3})(\d{3})(\d{4})$/;
        const formatWithoutCountry = /^(\d{3})(\d{3})(\d{4})$/;

        const partsWithCountry = cleanedNumber.match(formatWithCountry);
        const partsWithoutCountry = cleanedNumber.match(formatWithoutCountry);

        if (partsWithCountry) {
          return {
            original: number,
            formatted: `+${partsWithCountry[1]} (${partsWithCountry[2]}) ${partsWithCountry[3]} ${partsWithCountry[4]}`
          };
        } else if (partsWithoutCountry) {
          return {
            original: number,
            formatted: `(${partsWithoutCountry[1]}) ${partsWithoutCountry[2]} ${partsWithoutCountry[3]}`
          };
        } else {
          return {
            original: number,
            formatted: number
          };
        }
      });

      return formattedNumbers;
    },
    handleHourSelected (event) {
      this.isBlockedList = event.isWaitinglistBlocked !== 0;
    },
    checkDate (date, items = []) {
      const f = this.$moment(date).format('YYYY-MM-DD');
      if (items.find((i) => i === f)) {
        return true;
      } else {
        return false;
      }
    },
    findExperience () {
      let experience = _.map(this.allEvents, item => {
        if (item.type === 'experience' && item.alwaysActive) return item;
      });
      if (experience[0] !== undefined) {
        return experience;
      } else {
        return false;
      }
    },
    probeIfEvent (e) {
      if (e === false) {
        this.minimunPeople = this.params.allowSingleReservations ? this.params.allowSingleReservations : 1;
        this.$store.commit(reservationTypes.mutations.setMinimunPeoples, this.minimunPeople);
      }
      // aquí se reemplaza el isEventDay por una variable global para así poderla utilizar en la nueva sección de eventos

      this.isEventDay = e;
      this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, e);

      // aquí se reemplaza el dateTimeEvent por una variable global para así poderla utilizar en la nueva sección de eventos
      /* this.dateTimeEvent = null; */

      // éste es el commit que guarda los horarios del evento
      this.$store.commit(eventTypes.mutations.setDateTimeEventGlobal, null);

      this.$store.commit(eventTypes.mutations.setReservationEvent, false);
    },
    onSelectedDate (date) {
      this.dateStyle = date;
      const sameDate = (this.selectedDat === date) && true;
      this.$store.commit(eventTypes.mutations.setEventPrice, 0);
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
      this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
      if (!this.params.isActiveSelectedZone) {
        let dayOfWeek = parseInt(
          this.$moment(date)
            .seconds(0)
            .minutes(0)
            .hours(0)
            .milliseconds(0)
            .format('d')
        );
        this.$emit('closeAlerts', true);
        // if (this.vendor.id === 310 &&
        //   (dayOfWeek === 4 || dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0)) {
        //   this.$store.commit(whiteLabelTypes.mutations.setPopupAlert3Cordilleras, true);
        // }
        if (
          this.vendor.id === 72 &&
          this.selectedTypeReservation === 'Rumba' &&
          (dayOfWeek === 5 || dayOfWeek === 6) &&
          this.params.isMapped === 1 && !this.allEvents[date]
        ) {
          if (this.zone !== false && this.editPeople === true) {
            this.$store.commit(reservationTypes.mutations.setSelectedTable, this.dataReservationEdit.tableId);
            this.$store.commit(reservationTypes.mutations.setSelectedZone, this.zone);
            this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
            let response = this.setSelectedDates(date, this.now, this.allEvents);
            this.probeIfEvent(response.evento);
            this.selectedDat = date;
          } else {
            this.selectZoneMap(date);
          }
        } else {
          if (!this.params.listReservationType && this.vendor.id !== 170) {
            this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
          }
          this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
          this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
          let response = this.setSelectedDates(date, this.now, this.allEvents);
          this.$emit('experiences', this.findExperience());
          this.probeIfEvent(response.evento);
          this.selectedDat = date;
        // document.getElementById("hora_disponible").classList.remove("top50");
        }
      } else {
        if (this.selectedZone !== false && this.editPeople === true && this.dataReservationEdit.balancePaid > 0) {
          this.$emit('closeAlerts', true);
          this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
          this.$store.commit(reservationTypes.mutations.setSelectedZone, this.zone);
          this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
          this.$emit('change-date', true);
          let response = this.setSelectedDates(date, this.now, this.allEvents);
          this.$emit('experiences', this.findExperience());
          this.probeIfEvent(response.evento);
          this.selectedDat = date;
        } else {
          this.selectZoneMap(date);
        }
      }
      if (sameDate) {
        this.changeHours();
      }
    },
    manageError (error) {
      if (error.errorTimeOut) {
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
        this.errorTimeOut = true;
      }
    },
    getAvailabilityMessage () {
      if (this.params.activatePhoneAvailability === 1) {
        const phone = `<span><strong>${this.vendor.phone}</strong></span>`;
        const message = this.params.messageAvailability.replace('TELEFONO', phone);
        return message;
      }
      return this.params.messageAvailability;
    },
    getBackgroundColor (color, type = null, color2 = null) {
      if (type === 'back') {
        return {
          background: color
        };
      }
      if (type === 'p') {
        return {
          color: color
        };
      }
      if (type === 'btn') {
        return {
          'background-color': color,
          color: color2
        };
      }
      return {
        'background-color': color
      };
    },
    dangerPeopleProcess (defaultMessage = true) {
      this.showMaxPeopleModal = true;
      this.showDefaultMessagePeople = defaultMessage;
    },
    dangerHour () {
      let newMessage = this.getAvailabilityMessage();
      const icon = "<p><i class='fa fa-exclamation-circle alerticon'></i>";
      newMessage = newMessage.replace('<p>', icon);
      this.$buefy.snackbar.open({
        duration: 89785,
        message:
          `<span>  ${newMessage} </span>`,
        type: 'is-danger',
        position: 'is-top-right',
        actionText: 'X',
        queue: false
      });
    },
    dangerHourChato () {
      let newMessage = this.getAvailabilityMessage();
      const icon = "<p><i class='fa fa-exclamation-circle alerticon'></i>";
      newMessage = newMessage.replace('<p>', icon);
      this.$buefy.snackbar.open({
        duration: 5000,
        message:
          `<span>  ${newMessage} </span>`,
        type: 'is-danger',
        position: 'is-top-right',
        actionText: 'X',
        queue: false
      });
    },
    minPeopleEvent (people) {
      if (people > 0) {
        // this.people = people;
        this.minimunPeople = people;
        this.maxPeople = people;
        // this.morePeople = true;
      }
    },
    selectZone (e) {
      this.prevDate = e;
      this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
      this.showSelectZone = true;
    },
    selectZoneMap (e) {
      this.prevDate = e;
      this.showSelectZoneMap = true;
    },
    Alerts () {
      var elems = document.getElementsByClassName('snackbar');
      for (var i = 0; i !== elems.length; ++i) {
        elems[i].style.visibility = 'hidden'; // hidden has to be a string
      }
    },
    previusSection () {
      if (this.section === 2) {
        this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
        this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
        this.$store.commit(whiteLabelTypes.mutations.setSection, 1);
        document.getElementById('selectPeople').style.display = 'block';
        document.getElementById('selectDate').style.display = 'none';
        document.getElementById('siguienteButton').style.disabled = false;
      } else if (this.section === 3) {
        this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(whiteLabelTypes.mutations.setSection, 2);
        document.getElementById('selectDate').style.display = 'block';
        document.getElementById('selectHour').style.display = 'none';
        document.getElementById('siguienteButton').style.disabled = false;
      }
    },
    closeReservationDayUser () {
      this.$store.commit(
        reservationTypes.mutations.setReservationDayUser,
        false
      );
    },
    goAccontUser () {
      this.$store.commit(
        reservationTypes.mutations.setReservationDayUser,
        false
      );
      window.location.href = '/account';
    },
    close705 () {
      this.$store.commit(whiteLabelTypes.mutations.setSend705, false);
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
    },
    getTimeEventId (event) {
      this.$store
        .dispatch({
          type: 'event:getTimesEvent',
          data: { eventId: Number(event.eventId) ? this.encrypt(event.eventId) : event.eventId, people: this.selectedPeople }
        })
        .then(response => {
          if (event) {
            // se cambia el eventIdStatic a global
            /*  this.eventIdStatic = event; */
            this.$store.commit(eventTypes.mutations.setEventIdStaticGlobal, event);
          }
          // aquí se reemplata el dateTimeEvent por una variable global para así poderla utilizar en la nueva sección de eventos
          /* this.dateTimeEvent = response.data; */

          // éste es el commit que guarda los horarios del evento

          this.$store.commit(eventTypes.mutations.setDateTimeEventGlobal, response.data);

          this.$store.commit(eventTypes.mutations.setEventFull, event);
          this.$store.commit(eventTypes.mutations.setEventMinPeople, event.minPeople);
          if (event.maxPeople > 0) {
            this.maxPeople = event.maxPeople;
            if (this.people > this.maxPeople) {
              this.people = this.maxPeople;
            }
          }
          this.$store.commit(eventTypes.mutations.setShowDescriptionEvent, true);
          if (event.purchaseCommission) {
            this.$store.commit(eventTypes.mutations.setEventPrice, parseInt(event.purchaseCommission));
            this.$store.commit(eventTypes.mutations.setEventTextWithPayment, event.textSelect);
          }
          this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
        });
    },
    incrementPeople (action) {
      if (action === 'add') {
        if (this.people <= this.limitedPeople(this.params)) {
          if (this.people !== this.limitedPeople(this.params)) {
            this.people = parseInt(this.people) + 1;
          } else {
            this.morePeople = true;
          }
        }
      } else {
        this.morePeople = false;
        this.people = parseInt(this.people) - 1;
      }
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
    },
    incrementPeoplePombo (action, type) {
      if (type === 'adult') {
        if (action === 'add') {
          if (this.selectedAdult <= this.limitedPeople(this.params)) {
            if (this.selectedAdult !== this.limitedPeople(this.params)) {
              this.$store.commit(
                reservationTypes.mutations.setSelectedAdult,
                parseInt(this.selectedAdult) + 1
              );
            } else {
              this.morePeople = true;
            }
          }
        } else {
          this.morePeople = false;
          this.$store.commit(
            reservationTypes.mutations.setSelectedAdult,
            parseInt(this.selectedAdult) - 1
          );
        }
        this.people = this.selectedAdult + this.selectedBoy;
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
      }
      if (type === 'boy') {
        if (action === 'add') {
          if (this.selectedBoy <= this.limitedPeople(this.params)) {
            if (this.selectedBoy !== this.limitedPeople(this.params)) {
              this.$store.commit(
                reservationTypes.mutations.setSelectedBoy,
                parseInt(this.selectedBoy) + 1
              );
            } else {
              this.morePeople = true;
            }
          }
        } else {
          this.morePeople = false;
          this.$store.commit(
            reservationTypes.mutations.setSelectedBoy,
            parseInt(this.selectedBoy) - 1
          );
        }
        this.people = this.selectedAdult + this.selectedBoy;
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
      }
      if (type === 'baby') {
        if (action === 'add') {
          if (this.selectedBaby <= this.limitedPeople(this.params)) {
            if (this.selectedBaby !== this.limitedPeople(this.params)) {
              this.$store.commit(
                reservationTypes.mutations.setSelectedBaby,
                parseInt(this.selectedBaby) + 1
              );
            } else {
              this.morePeople = true;
            }
          }
        } else {
          this.morePeople = false;
          this.$store.commit(
            reservationTypes.mutations.setSelectedBaby,
            parseInt(this.selectedBaby) - 1
          );
        }
      }
    },
    nowInitial () {
      let hoy = new Date();
      let dia = hoy.getDate();
      let mes = hoy.getMonth() + 1;
      let anio = hoy.getFullYear();
      let now = String(anio + '/' + mes + '/' + dia);
      return now;
      /* return this.$moment().format('YYYY/MM/DD'); */
    },
    onExperiences (e) {
      this.experiences = e;
      if (e.length > 0) {
        this.showExperiences = true;
      }
    },
    selectExperience (data) {
      this.$store.commit(whiteLabelTypes.mutations.setSelectedExperience, data);
      this.showExperiences = false;
    },
    avalabilityInEventDay () {
      if (this.vendor.id === 393) {
        return false;
      }
      let data = _.find(this.avalibilityHour, { status: true });
      if (data) {
        return true;
      } else {
        return false;
      }
    },
    changeEventDayCommit () {
      this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, false);
    },
    maxPeopleDate (fecha) {
      if (fecha !== false) {
        let hour = this.$moment(fecha).format('HH:mm');
        if (hour === '00:00') {
          hour = null;
        } else {
          hour = parseInt(this.$moment(`1970-01-01 ${hour}`).milliseconds(0).seconds(0).format('x'));
        }
        let date = this.$moment(fecha).format('YYYY-MM-DD');
        let dayOfWeek = this.$moment(fecha).day();
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        this.$store.dispatch({
          type: 'reservation:getMaxPeople',
          data: {
            vendorId: this.$store.getters['whiteLabel:vendorId'],
            fecha: date,
            hora: hour,
            dayOfWeek: dayOfWeek
          }
        })
          .then(response => {
            // eslint-disable-next-line
            if (response.hasOwnProperty(0)) {
              this.maxPeople = response[0].maxPeople;
              if (this.people > this.maxPeople) {
                this.people = this.maxPeople;
              }
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              if (hour === null) {
                window.localStorage.setItem('dayPeopleLimit', this.maxPeople);
                window.localStorage.setItem('dayTypePeopleLimit', 'date');
              } else {
                window.localStorage.setItem('dayTypePeopleLimit', 'hour');
              }
            } else {
              if (this.maxPeople !== 9999 && hour === null) {
                this.maxPeople = 9999;
                window.localStorage.removeItem('dayPeopleLimit');
                window.localStorage.removeItem('dayTypePeopleLimit');
              }
              if (hour != null) {
                const peopleLimitDay = window.localStorage.getItem('dayPeopleLimit');
                if (peopleLimitDay) {
                  this.maxPeople = peopleLimitDay;
                } else {
                  this.maxPeople = 9999;
                  window.localStorage.removeItem('dayPeopleLimit');
                  window.localStorage.removeItem('dayTypePeopleLimit');
                }
              }
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            }
          })
          .catch(() => {
            this.maxPeople = 9999;
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          });
      }
    },
    getTypeLimitPeople () {
      return window.localStorage.getItem('dayTypePeopleLimit');
    },
    setCloseModalPeopleMax () {
      this.showMaxPeopleModal = false;
      if (window.innerWidth <= 959) {
        if (parseInt(this.section) === 2) {
          this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
          this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
          this.$store.commit(whiteLabelTypes.mutations.setSection, 1);
          document.getElementById('selectPeople').style.display = 'block';
          document.getElementById('selectDate').style.display = 'none';
          document.getElementById('selectHour').style.display = 'none';
          document.getElementById('siguienteButton').style.disabled = false;
          window.localStorage.removeItem('dayPeopleLimit');
          window.localStorage.removeItem('dayTypePeopleLimit');
        }
        if (parseInt(this.section) === 3) {
          this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
          this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
          this.$store.commit(whiteLabelTypes.mutations.setSection, 1);
          document.getElementById('selectPeople').style.display = 'block';
          document.getElementById('selectDate').style.display = 'none';
          document.getElementById('selectHour').style.display = 'none';
          document.getElementById('siguienteButton').style.disabled = false;
          window.localStorage.removeItem('dayPeopleLimit');
          window.localStorage.removeItem('dayTypePeopleLimit');
        }
      }
    }
  },
  watch: {
    people () {
      this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      this.$store.commit(reservationTypes.mutations.setSelectedPeople, this.people);
      if (
        this.selectedTypeReservation !== 'Pombo' &&
        this.selectedTypeReservation !== 'Rumba' &&
        this.selectedTypeReservation !== 'Cena'
      ) {
        this.messageAnticipe(this.people, this.params);
      } else {
        let payPombo = {
          minimumChargedPeople: 1,
          purchaseCost: 100
        };
        this.messageAnticipe(this.people, payPombo);
      }
      if (this.selectedDate !== null) {
        // se cambia el eventIdStatic a global
        if (this.eventIdStaticGlobal) {
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
          this.getTimeEventId(this.eventIdStaticGlobal);
        } else {
          let time = new Date(this.selectedDate).getTime();
          this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
          this.$store.dispatch({
            type: 'whiteLabel:avalibilityHour',
            data: {
              timestamp: time,
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              people: this.selectedPeople,
              zone: this.selectedZone,
              typeReservation: this.selectedTypeReservation,
              tableId: this.selectedTable
            }
          }).then(() => {
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
            this.errorTimeOut = false;
          }).catch((e) => {
            this.errorTimeOut = false;
            if (e.type === 'timeOut') {
              this.errorTimeOut = true;
            }
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          });
        }
      } else {
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
      }
    },
    morePeople () {
      var elems = document.getElementsByClassName('snackbar');
      for (var i = 0; i !== elems.length; ++i) {
        elems[i].style.visibility = 'hidden'; // hidden has to be a string
      }
      if (this.morePeople === true) {
        this.dangerPeopleProcess();
      }
    },
    isAvalability () {
      var elems = document.getElementsByClassName('snackbar');
      for (var i = 0; i !== elems.length; ++i) {
        elems[i].style.visibility = 'hidden'; // hidden has to be a string
      }
      if (this.isAvalability && !this.params.saleCrusade) {
        if (this.vendor.id === 16) {
          this.dangerHourChato();
        } else {
          this.dangerHour();
        }
      }
    },
    selectedDate (value) {
      this.onSelectedDate(value);
      this.$store.commit(eventTypes.mutations.setEventPrice, 0);
      // se cambia el eventIdStatic a global
      /* this.eventIdStatic = null; */
      this.$store.commit(eventTypes.mutations.setEventIdStaticGlobal, null);
      this.minimunPeople = this.params.allowSingleReservations
        ? this.params.allowSingleReservations
        : 1;
      this.maxPeopleDate(this.selectedDate);
    },
    selectedHour () {
      this.maxPeopleDate(this.selectedHour);
    },
    selectedPeople () {
      if (this.selectedDate != null && this.selectedHour == null) {
        this.maxPeopleDate(this.selectedDate);
      }
      if (this.selectedDate != null && this.selectedHour != null) {
        this.maxPeopleDate(this.selectedHour);
      }
    },
    // aquí se reemplaza el isEventDay por una variable global para así poderla utilizar en la nueva sección de eventos
    isEventDay () {
      if (!this.isEventDay) {
        this.minimunPeople = this.params.allowSingleReservations
          ? this.params.allowSingleReservations
          : 1;
        this.morePeople = false;
      }
    },
    isEventDayGlobal () {
      if (!this.isEventDayGlobal) {
        this.minimunPeople = this.params.allowSingleReservations
          ? this.params.allowSingleReservations
          : 1;
        this.morePeople = false;
      }
    },
    avalibilityHour (array) {
      if (array) {
        if (array.length > 0) {
          this.errorTimeOut = false;
        }
      }
    }
  }
};
</script>
<style lang="scss" >
#more-people-modal{
  .body-modal{
    padding: 40px 25px;
    border-radius: 20px;
    width: 98%;
    max-width: 415px;
    margin: 0 auto;
    background-color: #fff;
    color: #000;
  }
  .icon_round_warning{
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }
  .general-subtitle{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
  }
  .phone-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 15px 40px;
    flex-wrap: wrap;
    .phone-number{
      color: #000;
      font-size: 15px;
      font-weight: 600;
      text-decoration: underline;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 11px;

      .icon_phone_filled{
        margin: 0;
        width: 12px;
        height: 16px;
      }
      .icon_whatsapp_outlined{
        margin: 0;
        width: 16px;
        height: 16px;
      }
    }
  }
  .btns-modal{
    margin-top: 20px;
    justify-content: center;
    display: flex;
    .btn-modal{
      width: 160px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #000;
      background-color: transparent;
      color: #000;
      transition: .2s ease;
      cursor: pointer;
      &:hover{
        background-color: #000;
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 768px){
    .body-modal{
      padding: 40px 12px;
    }
  }
}
.pt-9 {
  position: relative;
  top: 8px;
  left: -5px;
}

.not-outline {
  outline: 0px !important;
  box-shadow: none !important;
}

.modals-bosy-little .btns-modal {
  display: flex;
  justify-content: center;
  margin-top: 0px !important;
  outline: 0px !important;
  box-shadow: none !important;
}

.modals-bosy-little {
  padding: 0px 0px 20px !important;
  border-radius: 30px;
}

.modals-bosy-little .btns-modal .full-btn-modal {
  min-width: 30% !important;
  height: 40px !important;
  border-radius: 9px !important;
  // font-family: sourceSansPro !important;
  margin-left: 10px !important;
}

.black-label {
  background: black;
  width: inherit;
  max-width: 480px;
  height: 50px;
  max-height: 85px;
  border-radius: 25px 25px 0px 0px;
  top: 0;
}

.pd-9 {
  margin-top: 30px;
}

.button-circle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 55px;
  min-width: 45px;
  height: 55px;
  min-height: 45px;
  margin-left: 30px;
  border-radius: 50px;
  top: 25px;
  box-shadow: 0px 2px 5px 1px rgba(155, 155, 155, 0.5);
}

.span-text {
  display: flex;
  justify-content: center;
  font-size: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: sourceSansPro;
  font-weight: bold;
  text-align: center;
}

.span-text-info {
  font-family: coonfortaBold;
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.Reservation {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  width: 100%;

  .snipper {
    opacity: 0.5;
    width: 60px;
    margin: 40px;
    margin-top: 60px;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &-content {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 0;
    display: flex;
  }
  &-card {
    background: var(--cardBackgroundColor);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 0.7rem !important;
    opacity: 0.9;
    max-width: 380px;
    min-height: 380px;
    &:first-child {
      margin-right: 3%;
    }
    &:last-child {
      margin-left: 3%;
    }
    &.typeReservation {
      width: 100%;
    }
  }
  &-title {
    color: var(--cardTitleColor);
    font-family: Source Sans Pro Light Italic, sans-serif;
    font-size: 2.5rem;
    text-align: center;
  }
  &-big-text {
    color: var(--cardTitleColor);
    font-family: Source Sans Pro Light, sans-serif;
    font-size: 2.5rem;
  }
  &-subtitle {
    color: var(--cardSubTitleColor);
    font-size: 14px;
    text-align: center;
  }
  &-divider {
    background-color: var(--cardDividerColor);
    height: 1px;
    width: 10%;
    margin: 15px auto;
  }
  &-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 25px !important;
    button {
      color: var(--cardButtonColor);
    }

    button:disabled {
      opacity: 0.2 !important;
    }
  }
  &-DateTime {
    width: 100%;
  }
  .sub-text {
    text-align: center;
  }
}

.brad-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.btn-hour:hover {
  color: var(--color2) !important;
  border: 1px solid var(--color2) !important;
  background-color: var(--color1) !important;
}

.separator-v {
  height: 20px;
  width: 1px;
  background: #fff;
  margin-top: -3px;
}

.bradius3 {
  border-radius: 0.3rem !important;
}
.previus-btn {
  z-index: 5;
}
.not-avalibility {
  background-color: #ffd9d9;
  padding: 12px;
  position: fixed;
  top: auto;
  right: auto;
  padding-top: 10px;
  z-index: 999;
  border-radius: 5px;
  padding-right: 20px;
  margin: 15px 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  #selectDate,
  #selectHour {
    display: none;
  }
}
@media (min-width: 1023px) and (max-width: 1025px) {
  .Reservation {
    &-card {
      width: 33.333% !important;
    }
  }
}
@media (max-width: 959px) {
  #selectDate,
  #selectHour {
    display: none;
  }
}
.card-wrapper {
  padding: 0.5rem 1.5rem;
}
@media (max-width: 678px) {
  .mq-top-30 {
    margin-top: 30px;
  }
  .Reservation {
    padding: 0px 0;
    .Reservation-title {
      font-size: 1.9rem;
    }
    &-card {
      margin: 4% !important;
      padding: 5% 5%;
    }
    &-content {
      margin: 0;
    }
  }
  .not-avalibility {
    margin: 10px 15px 10px;
  }
}
.not-avalibility {
  position: relative;
  background-color: #ffd9d9;
  padding: 12px;
  text-align: center;
}
.r-center {
  height: 50px;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-left: 0px;
  display: -webkit-box;
}
.icon-alert {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 35px;
  width: 35px;
  mask-size: contain;
  background: #f30000;
  -webkit-mask-image: url("../../assets/images/icons/alert.svg");
  mask-image: url("../../assets/images/icons/alert.svg");
}
.text {
  cursor: default;
  &.bold-900 {
    font-weight: 900;
  }
  &.bold-400 {
    font-weight: 400;
  }
  &.size-14 {
    font-size: 14px;
  }
  &.center {
    text-align: center;
  }
}
.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
