<template>
  <div class="container">
    <div id="box">
      <div class="centerlog" v-if="design.typeLogo == 'basic'">
        <img :src="vendor.lightLogoURL" id="box-logo" />
      </div>
      <span id="box-title">¡Sentimos mucho que te vayas!</span>
      <span id="box-description">A partir de este momento queda cancelada la suscripción para este correo {{email}} y no vas a recibir más información promocional de parte de nosotros, esperamos más adelante contar contigo de nuevo.</span>
      <button id="box-btn" v-on:click="verifyUnsubscribe">Aceptar</button>
    </div>
  </div>
</template>

<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  data () {
    return {
      token: '',
      email: ''
    };
  },
  metaInfo: {
    title: 'unsubscribe'
  },
  mounted () {
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
    this.token = this.$route.params.token;
    this.email = this.decryptMx(this.$route.params.token);
  },
  methods: {
    verifyUnsubscribe () {
      this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      this.$store
        .dispatch({
          type: 'user:getUnsubscribe',
          data: {
            vendorId: this.$store.getters['whiteLabel:vendorId'],
            token: this.token
          }
        })
        .then((response) => {
          console.log(response);
          if (response.code === 200) {
            window.location.href = '/';
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        });
    }
  }
};
</script>

<style lang="scss">
.content-language {
  display: none!important;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Altura total de la pantalla */
}
#box {
  width: 765px;
  height: 424px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #444B57;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  @media (max-width: 768px) {
    width: 705px;
  }
  @media (max-width: 500px) {
    width: 380px;
    padding: 20px;
    &-logo {
      width: 130px!important;
    }
    body {
      overflow: hidden;
    }
  }
  &-title {
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
  }
  &-description {
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }
  &-btn {
    width: 142px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #000;
    cursor: pointer;
    margin-top: 30px;
    color: #FFF;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &-logo {
    width: 199px;
  }
}
</style>
