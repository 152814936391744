<template>
  <b-modal :active.sync="value" :can-cancel="false" class="ModalTransaction" :id="idShow">
      <div class="modals-bosy">
          <div class="col-12 text-center">
            <IconCancel fill="#f44336" width="20em"></IconCancel>
          </div>
          <div>
            <h3 class="title-modal">{{ $t('checkoutView.components.text25') }}</h3>
            <h3 class="title-modal" v-if="reason != null" style="margin-bottom: 0px!important;">{{ $t('checkoutView.components.text26') }}:</h3>
            <span v-if="reason != null" style="display: flex; justify-content: center; align-items: center; margin-bottom: 15px;">{{ reason }}</span>
            <div class="columns is-mobile">
              <div class="column item-l">
                    <iconPeople
                    fill="#000"
                    width="4em"
                  ></iconPeople>&nbsp;
                  {{reservation.people}}
              </div>
              <div class="column item-l">
                  <iconCalendar
                    fill="#000"
                    width="25px"
                    height="25px"
                  >
                  </iconCalendar>&nbsp;
                  {{reservation.dateTime | moment('timezone', vendorTimezone, 'DD / MMM')}}
              </div>
              <div class="column item-l">
                  <iconTime
                    fill="#000"
                    width="25px"
                    height="25px"
                  ></iconTime>&nbsp;
                  {{reservation.dateTime | moment('timezone', vendorTimezone, 'hh : mm A')}}
              </div>
            </div>
            <div class="col-12 text-lef-messages">
              <h5 class="title-confirm">
                {{ $t('checkoutView.components.text27') }}
              </h5>
              <p>
                {{ $t('checkoutView.components.text28') }}
              </p>
              <b class="final-message">{{ $t('checkoutView.components.text29') }}</b><br>
            </div>
            <div class="btns-modal">
              <button
                type="button"
                @click="reintentar()"
                class="full-btn-modal btn-modal-fill" :class="{'full-w':reservation.isFree }"><i class="fa fa-reply"></i>&nbsp; {{ $t('checkoutView.components.text30') }}</button>
            </div>
          </div>
      </div>
  </b-modal>
</template>
<script>
import iconPeople from '@/components/Icons/iconPeople';
import iconTime from '@/components/Icons/iconTime';
import IconCancel from '@/components/Icons/iconCancel';
import iconCalendar from '@/components/Icons/iconCalendar';

export default {
  props: ['value', 'reservation', 'reason', 'idShow'],
  components: { IconCancel, iconCalendar, iconTime, iconPeople },
  methods: {
    reintentar () {
      window.location.href = '/checkout';
      this.$emit('input', false);
    }
  }
};
</script>
<style lang="scss" scoped>
.ModalTransaction{
  .modals-bosy {
    .btns-modal{
      margin-top: 15px !important;
      button{
        width:none !important;
        padding: 10px !important;
        margin: auto !important;
      }
    }
  }
}
</style>
