<template>
  <div id="modal-companion">
    <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>
    <div class="header">
      <span class="text bold">
        <i class="fa fa-user-plus"></i>
        {{$t('addCompas')}}
      </span>
      <span @click="closePopUp" class="text bold closeCompanion">
        <i class="fa fa-times"></i>
        {{$t('close')}}
      </span>
    </div>
    <div class="table-companion" v-if="!isResponsive()">
      <table>
        <thead>
          <tr>
            <th class="header-table">
              <span class="text gris bold-600">{{$t('name')}}</span>
            </th>
            <th class="header-table">
              <span class="text gris bold-600">{{$t('email')}}</span>
            </th>
            <th class="header-table" v-if="params.enableAddress == 1">
              <span class="text gris bold-600">{{$t('address')}}</span>
            </th>
            <th class="header-table">
              <span class="text gris bold-600">{{$t('phone')}}</span>
            </th>
            <th class="header-table">
              <span class="text gris bold-600">{{$t('idNumber')}}</span>
            </th>
            <th class="header-table" v-if="params.questionCovid == 1">
              <span class="text gris bold-600">{{ $t('checkoutView.components.text8') }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :key="'otemT' + index" v-for=" (item,index) in data">
            <td class="items-table">
              <span class="text gris bold-400">{{item.name}}</span>
            </td>
            <td class="items-table">
              <span class="text gris bold-400">{{item.email || 'N/A'}}</span>
            </td>
            <td class="items-table" v-if="params.enableAddress == 1">
              <span class="text gris bold-400">{{item.address || 'N/A'}}</span>
            </td>
            <td class="items-table">
              <span class="text gris bold-400 nowrap">{{'+' + item.indicative}} {{item.phone}}</span>
            </td>
            <td class="items-table">
              <span class="text gris bold-400">{{item.documentIdentify || 'N/A'}}</span>
              <div class="icons" v-if="params.questionCovid == 0">
                <i
                  @click="modeEdit('edit', item, index)"
                  aria-hidden="true"
                  class="fa fa-pencil-square-o"
                  style="margin-right:5px; cursor:pointer"
                ></i>
                <i
                  @click="deleteItem(index, item)"
                  aria-hidden="true"
                  class="fa fa-trash"
                  style="cursor: pointer"
                ></i>
              </div>
            </td>
            <td class="items-table" v-if="params.questionCovid == 1">
              <img
                alt="precompro.com"
                height="25"
                src="@/assets/images/icons/check-round.svg"
                v-if="(item.question1 == 0 || item.question1 == 1) && (item.question2 == 1 || item.question2 == 0)"
                width="25"
              />
              <div class="icons">
                <i
                  @click="modeEdit('edit', item, index)"
                  aria-hidden="true"
                  class="fa fa-pencil-square-o"
                  style="margin-right:5px; cursor:pointer"
                ></i>
                <i
                  @click="deleteItem(index, item)"
                  aria-hidden="true"
                  class="fa fa-trash"
                  style="cursor: pointer"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="cont-table" v-if="isResponsive() && data.length > 0">
      <div :key="'etIns' + index" class="card-table" v-for=" (item,index) in data">
        <div class="cont">
          <span class="text blueDark size-11 bold-700">{{$t('name')}}:</span>
          <span class="text blueDark size-11 bold-700">{{$t('email')}}:</span>
          <span class="text blueDark size-11 bold-700">{{$t('phone')}}:</span>
          <span
            class="text blueDark size-11 bold-700"
            v-if="params.enableAddress == 1"
          >{{$t('address')}}:</span>
          <span class="text blueDark size-11 bold-700">{{$t('idNumber')}}:</span>
          <span class="text blueDark size-11 bold-700">Temperatura:</span>
          <span class="text blueDark size-11 bold-700" v-if="params.questionCovid == 1">Preguntas:</span>
        </div>
        <div class="cont">
          <span class="text blueDark size-11">{{item.name}}</span>
          <span class="text blueDark size-11">{{item.email || 'N/A'}}</span>
          <span class="text blueDark size-11 nowrap">{{item.indicative}} {{item.phone}}</span>
          <span
            class="text blueDark size-11 nowrap"
            v-if="params.enableAddress == 1"
          >{{item.address || 'N/A'}}</span>
          <span class="text blueDark size-11">{{item.documentIdentify || 'N/A'}}</span>
          <span class="text blueDark size-11">{{item.temperature || 'N/A'}}</span>
          <span class="text blueDark size-11" v-if="params.questionCovid == 1">
            <img
              alt="precompro.com"
              height="15"
              src="@/assets/images/icons/check-round.svg"
              v-if="(item.question1 == 0 || item.question1 == 1) && (item.question2 == 1 || item.question2 == 0)"
              width="15"
            />
          </span>
        </div>
        <div class="btns">
          <i
            @click="deleteItem(index, item)"
            aria-hidden="true"
            class="fa fa-trash"
            id="btn-edit-trash"
          ></i>
          <i
            @click="modeEdit('edit', item, index)"
            aria-hidden="true"
            class="fa fa-pencil-square-o"
            id="btn-edit-trash"
          ></i>
        </div>
      </div>
    </div>
    <div :class="{'overflow': isResponsive() && edit}" class="edit">
      <div class="forms" id="editCompanion">
        <div class="formulario-edit">
          <div class="cont-form wrap">
            <div class="input-edit">
              <span class="text gris size-10 bold-600 margin-r-10 upliner upliner">{{$t('name')}}*</span>
              <input
                class="inp"
                name="name_companion"
                type="text"
                v-model="companion.name"
                v-show="edit"
                v-validate="'min:2|required'"
              />
              <i aria-hidden="true" class="fa fa-user-o"></i>
              <span
                class="text size-10 red error"
                v-if="errors.has('name_companion')"
              >{{ errors.first('name_companion') }}</span>
            </div>
            <div class="input-edit">
              <span
                class="text gris size-10 bold-600 margin-r-10 upliner"
              >{{$t('email')}}{{ $t('checkoutView.components.text9') }}</span>
              <input
                class="inp"
                name="email_companion"
                type="text"
                v-model="companion.email"
                v-show="edit"
                v-validate="'email'"
              />
              <i aria-hidden="true" class="fa fa-envelope-o"></i>
              <span
                class="text size-10 red error"
                v-if="errors.has('email_companion')"
              >{{ errors.first('email_companion') }}</span>
            </div>
          </div>
          <div class="cont-form wrap">
            <div class="input-edit">
              <span :style="{ backgroundImage: 'url('+ bandera +')'}" class="bandera"></span>
              <div class="forbandera companion">
                <b-field
                  :message="errors.first('pais_companion')"
                  :type="{'is-danger': errors.has('pais_companion')}"
                >
                  <b-select
                    :placeholder="$t('country')"
                    @input="changedPaisSelectedNavBar(option)"
                    name="pais_companion"
                    v-model="option"
                    v-show="edit"
                    v-validate="'required'"
                  >
                    <option
                      :key="'optLisC' + key"
                      :label=" '+' + pais.value "
                      :value="pais"
                      v-for="(pais, key) in paises"
                    >{{ pais.label }}</option>
                  </b-select>
                </b-field>
              </div>
              <span class="text gris size-10 bold-600 margin-r-10 upliner">{{$t('mobile')}}*</span>
              <input
                class="inp phone"
                name="phone_companion"
                type="text"
                v-model="companion.phone"
                v-show="edit"
                v-validate="'min:10|max:14|required|numeric'"
              />
              <i aria-hidden="true" class="fa fa-phone"></i>
              <span
                class="text size-10 red error"
                v-if="errors.has('phone_companion')"
              >{{ errors.first('phone_companion') }}</span>
            </div>
            <div class="input-edit">
              <span class="text gris size-10 bold-600 margin-r-10 upliner">{{$t('idNumber')}}</span>
              <input
                class="inp"
                name="identification_companion"
                type="text"
                v-model="companion.documentIdentify"
                v-show="edit"
                v-validate="'required|regex:^[a-zA-Z0-9]*$|min:5'"
              />
              <i aria-hidden="true" class="fa fa-id-card-o"></i>
              <span
                class="text size-10 red error"
                v-if="errors.has('identification_companion')"
              >{{ errors.first('identification_companion') }}</span>
            </div>
          </div>
          <div class="cont-form" v-if="params.enableAddress == 1">
            <div class="input-edit">
              <span
                class="text gris size-10 bold-600 margin-r-10 upliner upliner"
              >{{$t('address')}}*</span>
              <input
                class="inp"
                name="address_companion"
                type="text"
                v-model="companion.address"
                v-show="edit"
                v-validate="'min:2|required'"
              />
              <i aria-hidden="true" class="fa fa-map-marker"></i>
              <span
                class="text size-10 red error"
                v-if="errors.has('address_companion')"
              >{{ errors.first('address_companion') }}</span>
            </div>
          </div>
          <div class="cont-form spaceB" v-if="params.questionCovid == 1">
            <span
              class="t-question"
            >{{ $t('checkoutView.components.text10') }}</span>
            <div class="questions">
              <div
                :class="{'active': companion.question1 == 1 }"
                @click="(companion.question1 == 1) ? companion.question1 = null : companion.question1 = 1"
                class="input-question left"
              >
                <span class="t-input">{{ $t('checkoutView.components.text11') }}</span>
              </div>
              <div
                :class="{'active': companion.question1 == 0 }"
                @click="(companion.question1 == 0) ? companion.question1 = null : companion.question1 = 0"
                class="input-question right"
              >
                <span class="t-input">{{ $t('checkoutView.components.text12') }}</span>
              </div>
            </div>
          </div>
          <div class="cont-form spaceB" v-if="params.questionCovid == 1">
            <span
              class="t-question"
            >{{ $t('checkoutView.components.text13') }}</span>
            <div class="questions">
              <div
                :class="{'active': companion.question2 == 1 }"
                @click="(companion.question2 == 1) ? companion.question2 = null : companion.question2 = 1"
                class="input-question left"
              >
                <span class="t-input">{{ $t('checkoutView.components.text11') }}</span>
              </div>
              <div
                :class="{'active': companion.question2 == 0 }"
                @click="(companion.question2 == 0) ? companion.question2 = null : companion.question2 = 0"
                class="input-question right"
              >
                <span class="t-input">{{ $t('checkoutView.components.text12') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-form">
          <div @click="modeEdit('add')" class="btn-companion">
            <span class="text color-btn size-14">
              <i class="fa fa-times"></i>
              {{$t('cancel')}}
            </span>
          </div>
          <div @click="saveCompanion" class="btn-companion">
            <span class="text color-btn size-14">
              <i class="fa fa-save"></i>
              {{$t('save')}}
            </span>
          </div>
        </div>
      </div>
      <div
        @click="modeEdit('add')"
        class="btn-companion absolute fade"
        v-if="!edit && data.length < count && term"
      >
        <span class="text color-btn size-14">
          <i class="fa fa-user-plus"></i>
          {{$t('addCompa')}}
        </span>
      </div>
    </div>
    <div class="terms-companion">
      <input type="checkbox" v-model="term" />
      <span
        class="terms-companion-left"
      >{{ $t('checkoutView.components.text14') }}</span>
    </div>
  </div>
</template>
<script>
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import _ from 'lodash';
export default {
  props: ['reservationInfo'],
  data () {
    return {
      data: [],
      editIndex: null,
      count: 0,
      term: true,
      companion: {
        id: null,
        reservationId: null,
        name: null,
        email: null,
        temperature: 0,
        phone: null,
        indicative: 57,
        documentIdentify: null,
        address: null,
        question1: null,
        question2: null
      },
      edit: false,
      paises: null,
      option: {
        value: 57,
        label: 'Colombia ( +57 )',
        selected: true
      },
      bandera: 'https://api.precompro.com/banderas/co.png',
      isLoading: false
    };
  },
  mounted () {
    this.paises = this.listCountry();
    if (this.reservationInfo.companions !== undefined) {
      this.data = this.reservationInfo.companions;
    }
    if (this.reservationInfo.id !== undefined) {
      this.getCompanions(this.reservationInfo.id);
    }
    if (this.reservationInfo.reservationId !== undefined) {
      this.getCompanions(this.reservationInfo.reservationId);
    }
    this.count = this.reservationInfo.people - 1;
  },
  methods: {
    closePopUp () {
      if (this.reservationInfo.id === undefined || this.reservationInfo.reservationId === undefined) {
        this.$emit('updateCompanion', this.data);
      }
      this.$parent.close();
    },
    deleteItem (index, item) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar acompañante',
        message: '¿Estás seguro de que deseas <b>eliminar</b> tu acompañante? Esta acción no se puede deshacer.',
        confirmText: 'Si, Eliminar',
        cancelText: 'No',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          if (this.reservationInfo.id !== undefined || this.reservationInfo.reservationId !== undefined) {
            this.$store.dispatch({ type: 'reservation:companionDelete', data: { id: item.id } }).then(() => {
              this.data.splice(index, 1);
            });
          } else {
            this.data.splice(index, 1);
          }
          this.isLoading = false;
        }
      });
    },
    modeEdit (type, item = null, index = null) {
      if (type === 'add') {
        if (!this.edit) {
          document.getElementById('editCompanion').classList.remove('disabled');
          document.getElementById('editCompanion').classList.add('active');
          this.edit = true;
        } else {
          document.getElementById('editCompanion').classList.remove('active');
          document.getElementById('editCompanion').classList.add('disabled');
          this.edit = false;
        }
      } else if (type === 'edit') {
        this.editIndex = index;
        this.companion.id = item.id || null;
        this.companion.name = item.name;
        this.companion.email = item.email;
        this.companion.phone = item.phone;
        this.companion.address = item.address;
        this.companion.documentIdentify = item.documentIdentify;
        this.companion.temperature = item.temperature;
        this.companion.question1 = item.question1;
        this.companion.question2 = item.question2;
        this.companion.indicative = 57;
        document.getElementById('editCompanion').classList.remove('disabled');
        document.getElementById('editCompanion').classList.add('active');
        this.edit = true;
      }
    },
    getCompanions (id) {
      this.$store.dispatch({ type: 'reservation:companionList', data: { id } }).then(({ data }) => {
        this.data = data.companion;
      });
    },
    saveCompanion () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.isLoading = true;
          if ((this.reservationInfo.id !== undefined || this.reservationInfo.reservationId !== undefined) && this.editIndex != null) {
            if (this.reservationInfo.id !== undefined) {
              this.companion.reservationId = this.reservationInfo.id;
            }
            if (this.reservationInfo.reservationId !== undefined) {
              this.companion.reservationId = this.reservationInfo.reservationId;
            }
            let info = JSON.parse(JSON.stringify(this.companion));
            this.$store.dispatch({ type: 'reservation:companionUpdate', data: info }).then(() => {
              this.getCompanions(this.companion.reservationId);
              this.editIndex = null;
            });
          } else if ((this.reservationInfo.id !== undefined || this.reservationInfo.reservationId !== undefined) && this.editIndex == null) {
            if (this.reservationInfo.id !== undefined) {
              this.companion.reservationId = this.reservationInfo.id;
            }
            if (this.reservationInfo.reservationId !== undefined) {
              this.companion.reservationId = this.reservationInfo.reservationId;
            }
            let info = JSON.parse(JSON.stringify(this.companion));
            this.$store.dispatch({ type: 'reservation:companions', data: info }).then(({ data }) => {
              this.data.push(data.companion);
            });
          } else {
            if (this.edit && this.editIndex != null) {
              let info = JSON.parse(JSON.stringify(this.companion));
              this.data[this.editIndex] = info;
              this.editIndex = null;
            } else {
              let info = JSON.parse(JSON.stringify(this.companion));
              this.data.push(info);
            }
          }
          this.companion.name = null;
          this.companion.email = null;
          this.companion.phone = null;
          this.companion.address = null;
          this.companion.documentIdentify = null;
          this.companion.temperature = 0;
          this.companion.indicative = 57;
          this.companion.question1 = null;
          this.companion.question2 = null;
          this.modeEdit('add');
          this.isLoading = false;
        }
      });
    },
    changedPaisSelectedNavBar (indicativo) {
      let aux2 = null;
      let aux3 = indicativo.label;
      this.pais = indicativo.label;
      let ind = indicativo.value;
      _.forEach(BanderaPais, function (item) {
        let data2 = item.country + ' ( +' + ind + ' )';
        if (data2 === aux3) {
          aux2 = item.abbreviation.toLowerCase();
        }
      });
      this.bandera = aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
      this.companion.indicative = ind;
    },
    isResponsive () {
      if (window.innerWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss">
#modal-companion {
  i {
    margin-right: 5px;
  }
  .closeCompanion {
    float: right;
    right: 10px;
    position: absolute;
    cursor: pointer;
  }
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  .header {
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: left;
    height: 40px;
    min-height: 40px;
    align-items: center;
    background: var(--color2);
    padding-left: 15px;
    width: 100%;
  }
  .table-companion {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    max-height: 200px;
    .header-table {
      border: 1px solid #707070;
      border-right: 0px;
      border-left: 0px;
      &:first-child {
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #707070;
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
        border-right: 1px solid #707070;
      }
    }
    .items-table {
      border-right: 0px;
      border-left: 0px;
      margin-bottom: 10px;
      background: #00000014;
      &:first-child {
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        position: relative;
        border-radius: 0 2px 2px 0;
        padding-right: 58px;
      }
      .icons {
        position: absolute;
        right: 12px;
        top: 8px;
      }
    }
    table {
      width: 90%;
      margin-top: 15px;
      border-collapse: separate;
      border-spacing: 0 5px;
    }
    th,
    td {
      text-align: left;
      padding: 8px;
    }
  }
  .edit {
    width: 90%;
    // display: flex;
    // justify-content: flex-end;
    position: relative;
    margin: 12px 0 0 0;
    -webkit-tap-highlight-color: transparent;
    background: transparent;
    &:focus,
    &:active,
    &:hover {
      background: transparent;
    }
    &.overflow {
      overflow: auto;
    }
    .forms {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      -webkit-tap-highlight-color: transparent;
      background: transparent;
      &:focus,
      &:active,
      &:hover {
        background: transparent;
      }
      &.active {
        opacity: 1;
        animation: fadeDown 1s ease-in-out;
      }
      &.disabled {
        transform: translateY(600px);
        animation: fadeDownInverse 1s ease-in-out;
        opacity: 0;
      }
      .formulario-edit {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 2px solid rgba(112, 112, 112, 0.5);
        border-radius: 10px;
        padding: 15px 10px 10px 10px;
        .cont-form {
          display: flex;
          &.wrap {
            flex-wrap: wrap;
          }
          &.spaceB {
            justify-content: space-between;
          }
          .input-edit {
            flex: 1;
            border: 1.2px solid rgba(112, 112, 112, 0.5);
            border-radius: 4px;
            padding: 5px;
            width: calc(100% - 10px);
            margin: 0 7px 10px 7px;
            min-width: 250px;
            display: flex;
            align-items: center;
            position: relative;
            // &:last-child{
            //     margin:0 0px 10px 0;
            // }
            .inp {
              width: 100%;
              border: 0;
              outline: 0;
              &.phone {
                padding-left: 112px;
              }
            }
            &.temp-width {
              max-width: 100px;
              min-width: 100px;
            }
          }
        }
      }
      .btn-form {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 30px;
        margin: 15px 0;
      }
    }
  }
  .text {
    color: var(--colorCheckout);
    &.bold {
      font-weight: bold;
    }
    &.bold-400 {
      font-weight: 400;
    }
    &.bold-600 {
      font-weight: 600;
    }
    &.bold-700 {
      font-weight: 700;
    }
    &.gris {
      color: #707070;
    }
    &.blueDark {
      color: #444b57;
    }
    &.red {
      color: red;
    }
    &.white {
      color: #ffffff;
    }
    &.color-btn {
      color: var(--btnColorReservationFooter);
    }
    &.size {
      &-9 {
        font-size: 9px;
      }
      &-11 {
        font-size: 11px;
      }
      &-12 {
        font-size: 12px;
      }
      &-14 {
        font-size: 14px;
      }
      &-10 {
        font-size: 10px;
      }
    }
    &.margin {
      &-r-10 {
        margin-right: 10px;
      }
    }
    &.center {
      text-align: center;
    }
    &.error {
      position: absolute;
      background: white;
      bottom: -6px;
      padding: 0 2px;
    }
    &.padding-left-cel {
      padding-left: 95px;
    }
    &.upliner {
      position: absolute;
      top: -8px;
      background: #ffffff;
      left: 10px;
    }
    &.nowrap {
      white-space: nowrap;
    }
  }
  .btn-companion {
    background: var(--btnBgReservationFooter);
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
    padding: 0 15px;
    height: 34px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 210px;
    min-width: 125px;
    cursor: pointer;
    &.absolute {
      position: absolute;
      right: 60px;
      top: 0;
    }
    &.fade {
      animation: fade 1s ease-in-out;
    }
  }
  .forbandera {
    margin-bottom: 0;
    height: 31px;
    position: absolute;
    left: 34px;
    top: 0;
    border-right: 1.2px solid rgba(112, 112, 112, 0.5);
    &.col {
      .dropdown-item {
        &:first-child {
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;
          &:after {
            background: #ddd;
            position: absolute;
            bottom: 0px;
            width: 95%;
            height: 2px;
            border-radius: 5px;
            content: " ";
            left: 2.5%;
          }
        }
      }
    }
    .control.has-icons-right .icon.is-right {
      display: none;
    }
    .select {
      select {
        border: inherit;
        padding-right: 32px;
      }
    }
  }
  .bandera {
    background-position: 98%;
    cursor: pointer;
    height: 15px;
    width: 29px;
    background-color: #ccc;
    position: absolute;
    left: 5px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .cont-table {
    width: 100%;
    height: 130px;
    min-height: 130px;
    overflow: auto;
    .card-table {
      display: flex;
      background: #efefef;
      margin: 6px 0;
      position: relative;
      .btns {
        position: absolute;
        top: 12px;
        right: 10px;
        display: flex;
        flex-direction: column;
        #btn-edit-trash {
          color: #000;
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      .cont {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 8px 21px;
      }
    }
  }
  .questions {
    display: flex;
    justify-content: center;
    align-items: center;
    .input-question {
      box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
      width: 30px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.left {
        border-radius: 10px 0 0 10px;
      }
      &.right {
        border-radius: 0 10px 10px 0;
      }
      &.active {
        background: #14274e;
        color: #ffffff;
      }
      .t-input {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .t-question {
    font-size: 15px;
    font-weight: 400;
    max-width: 600px;
    margin-right: 10px;
  }
  @keyframes fadeDown {
    0% {
      overflow: hidden;
      opacity: 0;
      transform: translateY(300px);
    }
    100% {
      overflow: hidden;
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeDownInverse {
    0% {
      overflow: hidden;
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      overflow: hidden;
      opacity: 0;
      transform: translateY(300px);
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @mixin hover-supported {
    @media not all and (hover: none) {
      &:hover {
        @content;
      }
    }
  }
}
@media (max-width: 768px) {
  .forbandera {
    &.companion {
      height: 30px !important;
    }
    .img-phone-country {
      bottom: 8px;
    }
  }
}
@media (max-width: 750px) {
  #modal-companion {
    height: 90vh;
    min-height: 500px;
    padding-bottom: 79px;
    .edit {
      .forms {
        .formulario-edit {
          .cont-form {
            .input-edit {
              margin: 0 0px 10px 0;
            }
          }
        }
      }
    }
  }
}
.terms-companion {
  width: 95%;
  padding: 10px 10px 0 10px;
  bottom: 10px;
  position: absolute;
  &-left {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
