<template>
  <div class="content-language" id="languageChange" :style="getPosition()">
    <b-button
      @click="handleChangeLanguage()"
      class="button-language"
      id="btnLanguage"
      type="button"
    >ES/EN</b-button>
  </div>
</template>
<script>
export default {
  name: 'Language',
  data () {
    return {
      langSelected: this.$i18n.locale,
      boolLanguage: true
    };
  },
  methods: {
    handleChangeLanguage () {
      this.boolLanguage = !this.boolLanguage;
      this.$i18n.locale = this.boolLanguage ? 'es' : 'en';
      this.$moment.locale(this.boolLanguage ? 'es' : 'en');
      this.$validator.locale = this.boolLanguage ? 'es' : 'en';
      window.localStorage.setItem('lang', this.boolLanguage ? 'es' : 'en');
    },
    getPosition () {
      return {
        top: `${this.topBarSize}px`
      };
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Language/styles.scss";
</style>
