<template>
  <div id="precompraPlatform">
    <div class="loadingScreen" v-if="loading">
      <div>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <header
      :style="{
        backgroundColor: design.navBackgroundColor
          ? design.navBackgroundColor
          : transparent,
      }"
      class="topHeader"
    >
      <img :src="vendor.lightLogoURL" alt />
    </header>
    <main class="principalMain">
      <section class="information">
        <header class="information__header">
          <div class="header__leftSide">
            <span class="IconPrecompro"></span>
            <h2 class="leftSide__headertext">{{ $t('prepagoView.text1') }}</h2>
          </div>
          <div class="header__rightSide">
            <p>{{ $t('prepagoView.text2') }}</p>

            <h2 class="rightSide__headertext">{{ timer }}</h2>
            <span class="iconClock"></span>
          </div>
        </header>
        <section class="information__mainContent">
          <div class="mainContent__cardContainer">
            <div class="cardContainer__card">
              <h4>{{ $t('prepagoView.text3') }}</h4>
              <span class="iconPerson"></span>
              <p>{{ people }}</p>
            </div>
            <div class="cardContainer__card">
              <h4>{{ $t('prepagoView.text4') }}</h4>
              <span class="iconCalendar"></span>
              <p>{{ calendar }}</p>
            </div>
            <div class="cardContainer__card">
              <h4>{{ $t('prepagoView.text5') }}</h4>
              <span class="iconClock"></span>
              <p>{{ hour }}</p>
            </div>
          </div>
          <div class="mainContent__detailsCardsContainer">
            <div class="detailsCardsContainer__detail">
              <p>{{ $t('prepagoView.text6') }}</p>
              <span>
                {{
                Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                }).format(balancePaid / people)
                }}
              </span>
            </div>
            <div class="detailsCardsContainer__detail">
              <p>{{ $t('prepagoView.text7') }}</p>
              <span>{{ people }}</span>
            </div>
            <div class="detailsCardsContainer__detail">
              <p>{{ $t('prepagoView.text8') }}</p>
              <span>
                {{
                Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                }).format(balancePaid)
                }}
              </span>
            </div>
          </div>
          <p class="mainContent__smallLetter">{{ $t('prepagoView.text9') }}</p>
          <section class="mainContent__clientData">
            <div class="clientData__title data-client-header">
              <span class="iconPerson"></span>
              <h2>{{ $t('prepagoView.text10') }}</h2>
              <span class="small-text-pay">*<i>{{$t('prepagoView.text38')}}</i></span>
            </div>
            <div class="clientData__form">
              <div class="inputContainer client-name">
                <input
                  placeholder="Juan Carlos Rodriguez Camargo"
                  type="text"
                  v-model="client.name"
                />
              </div>
              <div class="inputContainer client-phone">
                <InputC
                  :flags="true"
                  :initBandera="initBandera"
                  :initCode="initCode ? initCode : 57"
                  :options="[{ name: '', value: '' }]"
                  :placeHolder="$t('prepagoView.text23')"
                  :valueInput="'' + client.phone"
                  disabled
                  selector
                />
              </div>
              <div class="inputContainer client-email" :class="{'large-email': !params.enableIdentityDocument}">
                <span class="iconEmail"></span>
                <input placeholder="Email" type="email" v-model="client.email" />
              </div>
              <div class="inputContainer client-identification" v-if="params.enableIdentityDocument">
                <InputC
                  :options="[{ name: 'CC', value: 'CC' }]"
                  :placeHolder="$t('prepagoView.text21')"
                  :valueInput="client.identification"
                  disabled
                  selector
                />
              </div>

              <div class="form__checkbox">
                <span class="condition-checkbox">
                  <label class="container">
                    <input
                      :disabled="termsConditions"
                      id="termsAndConditions"
                      name="termsAndConditions"
                      type="checkbox"
                      v-model="termsConditions"
                    />
                    <span class="checkmark"></span>
                  </label>

                  <label for="termsAndConditions">
                    {{ $t('prepagoView.text11') }}
                    <b
                      @click="() => (showtermsOne = true)"
                    >{{ $t('prepagoView.text12') }}</b>
                  </label>
                </span>

                <span class="condition-checkbox">
                  <label class="container">
                    <input
                      :disabled="dataTreatment"
                      id="datatreatment"
                      name="datatreatment"
                      type="checkbox"
                      v-model="dataTreatment"
                    />

                    <span class="checkmark"></span>
                  </label>

                  <label for="datatreatment">
                    {{ $t('prepagoView.text13') }}
                    <b
                      @click="() => (showtermsTwo = true)"
                    >{{ $t('prepagoView.text14') }}</b>
                    {{ $t('prepagoView.text15') }}
                  </label>
                </span>
              </div>
            </div>
            <div class="clientData__dataTreatment"></div>
          </section>
        </section>
      </section>
      <section :class="{ ['payInfo--cantUse']: lock }" class="payInfo">
        <h2 class="payInfo__title">{{ $t('prepagoView.text16') }}</h2>
        <nav>
          <div
            :class="{ selected: creditMethod }"
            @click="() => (creditMethod = true)"
            class="payInfo__tabs"
          >
            <h1>{{ $t('prepagoView.text17') }}</h1>
            <div class="paymentsContainer">
              <span :class="{cardSelected:cardType==='Visa'? true :false}" class="VisaIcon"></span>
              <span
                :class="{cardSelected:cardType==='MasterCard'? true :false}"
                class="masterCardIcon"
              ></span>
              <span :class="{cardSelected:cardType==='Diners'? true :false}" class="clubIcon"></span>
              <span
                :class="{cardSelected:cardType==='Amex'? true :false}"
                class="americanExpressIcon"
              ></span>
            </div>
          </div>
          <div
            :class="{ selected: !creditMethod }"
            @click="() => (creditMethod = false)"
            class="payInfo__tabs"
          >
            <h1>{{ $t('prepagoView.text18') }}</h1>
            <span class="PSEIcon"></span>
          </div>
        </nav>
        <form
          @click="canPay"
          @submit.prevent="postCreditCardTransaction"
          class="payInfo__creditInformation"
          id="pay"
          name="pay"
          v-show="creditMethod"
        >
          <input
            :placeholder="$t('prepagoView.text19')"
            data-checkout="cardholderName"
            id="cardholderName"
            required
            type="text"
            v-model.trim="nameLikeCard"
          />
          <div class="selectorContentDocoument">
            <Selector
              :checkout="'docType'"
              :id="'docType'"
              :options="creditOPtionsTypePerson"
              :placeHolder="$t('prepagoView.text20')"
              :required="true"
              @value="(e) => (typePerson = e)"
            />
          </div>

          <input
            :placeholder="$t('prepagoView.text21')"
            data-checkout="docNumber"
            id="docNumber"
            required
            type="text"
            v-model="documentNumber"
          />
          <input
            :placeholder="$t('prepagoView.text22')"
            id="email"
            required
            type="email"
            v-model="email"
          />
          <div class="inputContainer">
            <input
              :flags="true"
              :placeHolder="$t('prepagoView.text23')"
              :type="'number'"
              class="phoneInput"
              v-model="phone"
            />
          </div>

          <h1>{{ $t('prepagoView.text24') }}</h1>
          <!-- Mercado pago V2-->
          <div class="mp_fields" v-if="paymentProviderTC === 'MP'">
            <div
              :class="{ cvvHidden: !showCVV }"
              class="mp_fields-item w50 mr10"
              id="cardNumber"
              v-if="paymentProviderTC === 'MP'"
            ></div>
            <div
              :class="{ cvvHidden: !showCVV }"
              class="mp_fields-item w20 mr10"
              id="securityCode"
              v-if="paymentProviderTC === 'MP'"
            ></div>
            <div
              :class="{ cvvHidden: !showCVV }"
              class="mp_fields-item w20"
              id="expirationDate"
              v-if="paymentProviderTC === 'MP'"
            ></div>
          </div>
          <input
            :maxlength="maxAllowed"
            :placeholder="$t('prepagoView.text25')"
            data-checkout="cardNumber"
            id="CardNumberInput"
            pattern="[0-9 ]{14,21}"
            required
            type="text"
            v-if="paymentProviderTC === 'WOMPI' || paymentProviderTC === 'PAYU'"
            v-model="cardNumber"
          />
          <div style="display: flex; gap: 12px;" v-if="paymentProviderTC === 'WOMPI'">
            <div style="display: flex; flex-direction: column;">
              <input
                style="width: 100%;"
                placeholder="MM"
                id="wompimonth"
                name="wompimonth"
                :maxlength="2"
                v-model="wompiCard.month"
                v-validate="'required|numeric|max:2|min:2|between:1,12'"
              >
              <span
                v-if="errors.has('wompimonth')"
                class="text_error_card ffr fw300"
              > {{ wompiCard.month ? 'El mes es invalido' : 'Este campo es requerido' }}</span>
            </div>
            <div style="display: flex; flex-direction: column;">
              <input
                style="width: 100%;"
                placeholder="YYYY"
                id="wompiyear"
                name="wompiyear"
                v-model="wompiCard.year"
                v-validate="'required|numeric|max:4|min:4'"
              >
              <span
                v-if="errors.has('wompiyear')"
                class="text_error_card ffr fw300"
              > {{ wompiCard.year ? 'El año es invalido' : 'Este campo es requerido' }} </span>
            </div>
            <div style="display: flex; flex-direction: column;">
              <input
                style="width: 100%;"
                placeholder="CVC"
                id="wompicvc"
                name="wompicvc"
                v-model="wompiCard.cvv"
                :maxlength="4"
                v-validate="'required|numeric|min:3|max:4'"
              >
              <span
                v-if="errors.has('wompicvc')"
                class="text_error_card ffr fw300"
              >
                {{ wompiCard.cvv ? 'El codigo cvc es invalido' : 'Este campo es requerido' }}
              </span>
            </div>
          </div>
          <div :class="{ cvvHidden: !showCVV }" class="mesContainer" v-if="paymentProviderTC === 'PAYU'">
            <Selector
              :checkout="'cardExpirationMonth'"
              :id="'cardExpirationMonth'"
              :options="monthOptions"
              :placeHolder="$t('prepagoView.text26')"
              :required="true"
              @value="(e) => (month = e)"
            />
          </div>
          <div :class="{ cvvHidden: !showCVV }" class="anoContainer" v-if="paymentProviderTC === 'PAYU'">
            <Selector
              :checkout="'cardExpirationYear'"
              :id="'cardExpirationYear'"
              :options="yearOptions"
              :placeHolder="$t('prepagoView.text27')"
              :required="true"
              @value="(e) => (year = e)"
            />
          </div>
          <div :class="{ cvvHidden: !showCVV }" class="NoCuotasContainer">
            <Selector
              :disable="paymentProviderTC !== 'PAYU' && !cuotesReady"
              :options="CoutesLimit"
              :placeHolder="$t('prepagoView.text28')"
              :required="true"
              @value="(e) => (cuots = e)"
            />
          </div>
          <div class="securePay">
            <p>{{ $t('prepagoView.text29') }}</p>
            <span
              :class="{
                mercadoPagoImg: paymentProviderTC === 'MP',
                payuImg: paymentProviderTC === 'PAYU',
                wompiImg: paymentProviderTC === 'WOMPI'
              }"
            ></span>
          </div>

          <input
            id="transactionAmount"
            name="transactionAmount"
            type="hidden"
            v-model="cardMercadoPago.net_amount"
          />
          <input id="saveCard" style="display: none" type="checkbox" value="false" />
          <button
            :class="{ readyToSubmit: submitReady }"
            :disabled="submitDisabled"
            type="submit"
          >{{ $t('prepagoView.text30') }}</button>
        </form>
        <form
          @click="canPay"
          @submit.prevent="postPSETransaction"
          class="payInfo__debitInformation"
          v-show="!creditMethod"
        >
          <div class="selectorContent--two">
            <Selector
              :options="banks"
              :placeHolder=" $t('prepagoView.text31') "
              :required="true"
              @value="(e) => (bank = e)"
            />
          </div>
          <input :placeholder="$t('prepagoView.text32')" required type="text" v-model="name" />

          <div class="selectorContentDocoument">
            <Selector
              :options="typePersonOptions"
              :placeHolder="$t('prepagoView.text20')"
              :required="true"
              @value="(e) => (typePerson = e)"
            />
          </div>

          <input placeholder="Numero de documento" required type="text" v-model="documentNumber" />
          <input class="mail-input" placeholder="Correo" required type="email" v-model="email" />
          <div class="inputContainerC">
            <input placeholder="numero de telefono" type="number" v-model="phone" />
          </div>
          <div class="switchesContainer">
            <div>
              <SwitchBool :value="personaNatural" @change="(e) => (personaNatural = e)" />
              <span>{{ $t('prepagoView.text33') }}</span>
            </div>
            <div>
              <SwitchBool :value="personaJuridica" @change="(e) => (personaJuridica = e)" />
              <span>{{ $t('prepagoView.text34') }}</span>
            </div>
          </div>
          <div class="additionalInfo">
            <p>{{ $t('prepagoView.text35') }}</p>
          </div>

          <div class="securePay">
            <p>{{ $t('prepagoView.text36') }}</p>
            <span :class="{ mercadoPagoImg: paymentProvider === 'MP', payuImg: paymentProvider === 'PAYU', wompiImg: paymentProvider === 'WOMPI' }"></span>
          </div>
          <button
            :class="{ readyToSubmit: submitReady }"
            :disabled="submitDisabled"
            type="submit"
          >{{ $t('prepagoView.text37') }}</button>
        </form>
        <transactionPending
          :idShow="'pendingPay'"
          :reservation="reservation"
          v-if="showPending"
          v-model="showPending"
        ></transactionPending>
        <transactionDeclined
          :idShow="'pendingPay'"
          :reason="reasonDeclined"
          :reservation="reservation"
          v-if="showCancel"
          v-model="showCancel"
        ></transactionDeclined>
      </section>
    </main>
    <footer>
      <span class="logoBuildByPrecompro"></span>
    </footer>
    <section
      class="termsC"
      v-click-outside="
        () => {
          showtermsOne = false;
          showtermsTwo = false;
        }
      "
      v-if="showtermsOne || showtermsTwo"
    >
      <terms :fecha="'22 de Noviembre del 2019'" v-if="showtermsOne"></terms>
      <terms2 :fecha="'22 de Noviembre del 2019'" v-else></terms2>
    </section>
    <b-modal :active.sync="showTimeOver" :can-cancel="false" id="time-over-modal">
      <div class="modals-bosy">
        <i class="icon_time_over icon-mks"></i>
        <h3 class="title-over">{{ $t('timeOverReservation') }}</h3>
        <p class="text-over">{{ $t('generateNewLink') }} <strong>{{vendor.phone}}</strong></p>
        <div class="btns-over">
          <button @click="acceptTimeOver" class="btn-prec">{{ $t('agree') }}</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import _ from 'lodash';
import Selector from '@/components/Select/GeneralSelector.vue';
import SwitchBool from '@/components/Select/SwitchBool.vue';
import InputC from '@/components/customInput.vue';
import reservationTypes from '@/store/types/reservation';
import whiteLabelTypes from '@/store/types/whiteLabel';
import globalTypes from '@/store/types/global';
import serviceReservation from '@/helpers/apis/serviceReservation';
import transactionPending from '@/components/Checkout/transactionPending';
import transactionDeclined from '@/components/Checkout/transactionDeclined';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import { mapGetters } from 'vuex';
export default {
  name: 'precompraPlatform',
  components: {
    terms,
    terms2,
    Selector,
    SwitchBool,
    InputC,
    transactionPending,
    transactionDeclined
  },
  data () {
    return {
      showtermsOne: false,
      showtermsTwo: false,
      loading: true,
      timeEnd: false,
      initCode: null,
      cvv: '',
      creditMethod: true,
      showPse: false,
      showPending: false,
      showCancel: false,
      showTimeOver: false,
      reasonDeclined: null,
      banks: [],
      bank: '',
      monthOptions: [],
      yearOptions: [],
      termsConditions: true,
      dataTreatment: true,
      cardType: '',
      pse: {
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ],
        description: null,
        payment_method_id: 'pse',
        payer: {
          email: null,
          entity_type: null,
          identification: {
            type: null,
            number: null
          }
        },
        transaction_details: {
          financial_institution: null
        },
        callback_url: null
      },
      psePAYU: {
        bank: null,
        name: null,
        email: null,
        typeDocument: null,
        identification: null,
        phone: null,
        balance: 0,
        typePeople: null,
        url: null
      },
      typePersonOptions: [],
      typePerson: ' ',
      minDocumentnumbers: 9,
      maxDocumentnumbers: 9,
      people: 4,
      calendar: null,
      hour: '12:30 p.m.',
      valuePerPerson: 0,
      documentNumber: '',
      email: '',
      name: '',
      cardNumber: '',
      nameLikeCard: '',
      client: {
        name: '',
        email: '',
        phone: '',
        identification: ''
      },
      personaNatural: true,
      personaJuridica: false,
      phone: '',
      CoutesLimit: [],
      timer: '',
      reservation: null,
      fetchInformation: {},
      cardMercadoPago: {
        payer: {
          first_name: null,
          identification: {
            type: null,
            number: null
          },
          email: null
        },
        token: null,
        installments: null,
        description: null,
        payment_method_id: null,
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ]
      },
      card: {
        name: null,
        identification: null,
        phone: null,
        address: null,
        city: null,
        card: null,
        mes: null,
        ano: null,
        cuota: null,
        expiration: null,
        balance: 0,
        checked: true
      },
      balancePaid: 0,
      month: '',
      year: '',
      cuots: 0,
      cuotesReady: false,
      lock: false,
      id: null,
      paymentProvider: '',
      paymentProviderTC: '',
      creditOPtionsTypePerson: [],
      showCVV: true,
      submitDisabled: false,
      maxAllowed: 19,
      initBandera: '',
      mercadopago: null,
      serverTimeCurrent: null,
      interval: null,
      acceptanceToken: '',
      wompiCard: {
        cvv: '',
        month: '',
        year: ''
      }
    };
  },
  created () {
    this.id = this.$route.params.id;
    this.paymentProvider = this.params.paymentTypePseLink;
    this.paymentProviderTC = this.params.paymentTypeLink;
    if (this.paymentProviderTC === 'PAYU') {
      this.showCVV = false;
    }
    if (this.paymentProviderTC === 'MP') {
      if (this.integrations.length > 0) {
        const mercadopago = this.integrations.find(int => int.type === 'mercadopago' && int.environment === process.env.NODE_ENV);
        if (mercadopago) {
          // eslint-disable-next-line
          this.mercadopago = new MercadoPago(this.decryptMx(mercadopago.login));
        } else {
          // eslint-disable-next-line
          this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
        }
      } else {
        // eslint-disable-next-line
        this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
      }
    }
    this.initCoutes(36);
    if (['PAYU', 'MP'].includes(this.paymentProvider)) {
      this.getStatusPSE();
      this.getPaymentOptionsPSE();
    } else {
      this.getBanksAcceptanceToken();
    }
    this.initYearOptions();
    this.initMonthOptions();
    this.getIdentification();
    this.getFetchedInfo();
    // Production
    if (process.env.NODE_ENV === 'production') {
      this.pse.callback_url = 'https://' + this.subDomain() + '.precompro.com/prepago/' + this.id + '?payment=mp';
      this.psePAYU.url = 'https://' + this.subDomain() + '.precompro.com/prepago/' + this.id;
    } else {
      // Development
      this.pse.callback_url = 'https://dev.precompro.co/prepago/' + this.id + '?payment=mp';
      this.psePAYU.url = 'https://dev.precompro.co/prepago/' + this.id;
    }
    this.loadScript(this.integrations);
  },
  mounted () {
    let trans = this.$route.query;
    if (Object.keys(trans).length > 0) {
      if (trans.payment) {
        this.showPending = true;
      }
      this.showStatusTransactionPayU(trans);
    }
    if (this.vendor !== undefined) {
      if (this.vendor.timezone) {
        if (this.vendor.timezone !== 'America/Bogota') {
          this.getHourTimezone().then((data) => {
            this.serverTimeCurrent = this.$moment(data.datetime);
          });
        } else {
          this.$store.dispatch({
            type: 'global:getServerTime'
          }).then(({ serverTime }) => {
            this.serverTimeCurrent = this.$moment(serverTime);
          });
        }
      }
    }
    // Mercadopago V2
    if (this.paymentProviderTC === 'MP') {
      this.setConfigMercadoPago();
    }
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
    this.checkRedirectionWompi();
  },
  methods: {
    acceptTimeOver () {
      window.location.href = '/select';
      this.showTimeOver = false;
    },
    setConfigMercadoPago () {
      const cardNumberElement = this.mercadopago.fields.create('cardNumber', {
        placeholder: 'Número de la tarjeta'
      }).mount('cardNumber');
      this.mercadopago.fields.create('expirationDate', {
        placeholder: 'MM/YY'
      }).mount('expirationDate');
      this.mercadopago.fields.create('securityCode', {
        placeholder: 'CVV'
      }).mount('securityCode');
      cardNumberElement.on('binChange', this.getPaymentMethods);
    },
    async getPaymentMethods (data) {
      const { bin } = data;
      if (bin != null && bin !== undefined) {
        await this.mercadopago.getPaymentMethods({ bin }).then(({ results }) => {
          this.setPaymentMethod(results);
        });
        await this.getInstallments(bin);
      } else {
        this.cardMercadoPago.payment_method_id = null;
        this.maxNumber = 19;
        this.cuotas = this.cuotasDefault;
      }
    },
    setPaymentMethod (response) {
      let paymentMethodId = response[0].id;
      this.cardMercadoPago.payment_method_id = paymentMethodId;
    },
    async getInstallments (bin) {
      const installments = await this.mercadopago.getInstallments({
        amount: document.getElementById('transactionAmount').value,
        bin,
        paymentTypeId: 'credit_card'
      });
      let data = [];
      installments[0].payer_costs.forEach(installment => {
        let opt = {
          label: installment.recommended_message,
          value: installment.installments
        };
        data.push(opt);
      });
      this.cuotas = data;
    },
    setCardTokenAndPay (token) {
      let form = document.getElementById('pay');
      let card = document.createElement('input');
      card.setAttribute('name', 'token');
      card.setAttribute('type', 'hidden');
      card.setAttribute('value', token);
      form.appendChild(card);
      this.doSubmit = true;
      this.postMercadopagoCreditCard();
    },
    creditCardFormatter (number) {
      let formattedValue = '';
      const spacesNeeded = number.split(/\d{4,4}/);
      for (let index = 0; index < spacesNeeded.length - 1; index++) {
        formattedValue =
          formattedValue + number.slice(index * 4, (index + 1) * 4) + ' ';
        if (number.length % 4 !== 0 && index === spacesNeeded.length - 2) {
          formattedValue = formattedValue + spacesNeeded[spacesNeeded.length - 1];
        }
      }

      if (number.length < 5) {
        formattedValue = number;
      }
      return formattedValue.trim();
    },
    showStatusTransactionPayU (transaction) {
      if (
        parseInt(transaction.polTransactionState) === 4 &&
        parseInt(transaction.polResponseCode) === 1
      ) {
        if (this.reservation) {
          this.loading = true;
          window.localStorage.setItem('_reservationOk', true);
          this.$store.commit(reservationTypes.mutations.setReservationsUserLink);
          setTimeout(() => {
            this.loading = false;
            clearInterval(this.interval);
            this.timeEnd = true;
            window.location.href = '/confirmation?id=' + this.id;
          }, 600);
        }
      }
      if (
        parseInt(transaction.polTransactionState) === 6 &&
        (parseInt(transaction.polResponseCode) === 4 ||
          parseInt(transaction.polResponseCode) === 5)
      ) {
        this.showCancel = true;
      }
      if (
        (parseInt(transaction.polTransactionState) === 12 ||
          parseInt(transaction.polTransactionState) === 14) &&
        (parseInt(transaction.polResponseCode) === 9994 ||
          parseInt(transaction.polResponseCode) === 25)
      ) {
        this.showPending = true;
      }
    },
    canPay () {
      if (!this.dataTreatment && !this.termsConditions) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>" +
            'debes aceptar los terminos y condiciones , al igual que el tratamiento de datos personales',
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
        this.lock = true;
      }
    },
    getFetchedInfo () {
      const thes = this;
      thes.loading = true;
      thes.$http.post('/dashboard/reservationId', {
        reservationId: thes.id
      }).then(({ data }) => {
        thes.loading = false;
        if (data.length > 0) {
          const reservation = data[0];
          reservation.isFree = false;
          reservation.dateTime = data[0].date;
          window.localStorage.setItem(
            '_reservation_link',
            JSON.stringify(reservation)
          );
          thes.$store.commit(reservationTypes.mutations.setReservationsUserLink);
          if (reservation.codeStatus === 3 || reservation.codeStatus === 4 || reservation.codeStatus === 5) {
            clearInterval(this.interval);
            this.timeEnd = true;
            thes.$buefy.dialog.alert({
              message: 'La reserva tiene un estado: <br><b>' + reservation.status + '</b>',
              confirmText: 'Aceptar',
              onConfirm: () => {
                window.location.href = '/select';
              }
            });
            return;
          }
          let dateInterval = thes.$moment(reservation.created_at);
          if (thes.params.paymentTime) {
            dateInterval.set('minutes', dateInterval.get('minutes') + parseInt(thes.params.paymentTime)
            );
          } else {
            dateInterval.set('minutes', dateInterval.get('minutes') + 20);
          }
          thes.InitInterval(dateInterval);
          thes.client.identification = reservation.identityDocument;
          thes.client.email = reservation.email;
          if (reservation.phone) {
            thes.client.phone = parseInt(reservation.phone);
          } else {
            thes.client.phone = '';
          }
          thes.client.name = reservation.displayName;
          thes.people = reservation.people;
          let date = this.$moment(reservation.fechaCompleta);
          var month = date.format('MMM').replace('.', '');
          thes.calendar = `${date.format('DD')}/${(month[0].toUpperCase() + month.slice(1))}/${date.format('YY')}`;
          thes.hour = `${date.format('hh:mm a')}`;
          thes.balancePaid = reservation.balancePaid;
          thes.getReservation(thes.id);
        } else {
          thes.$buefy.dialog.alert({
            message: 'La reserva no fue encontrada',
            confirmText: 'Aceptar',
            onConfirm: () => {
              window.location.href = '/select';
            }
          });
        }
      }).catch((err) => {
        console.error(err);
        clearInterval(thes.interval);
        thes.timeEnd = true;
        thes.$buefy.dialog.alert({
          message: 'La reserva no fue encontrada',
          confirmText: 'Aceptar',
          onConfirm: () => {
            window.location.href = '/select';
          }
        });
      });
    },
    endTime () {
      this.$store.dispatch({
        type: 'reservation:cancelarReservation',
        data: { id: this.decryptMx(this.id), linkPayment: 1 }
      });
    },
    initCoutes (installments) {
      this.CoutesLimit = [];
      for (let index = 1; index < installments + 1; index++) {
        this.CoutesLimit.push({ name: index, value: index });
      }
      this.cuotesReady = true;
    },
    getIdentification () {
      if (this.paymentProvider === 'PAYU' || this.paymentProviderTC === 'PAYU') {
        this.typePersonOptions = [
          { value: 'CC', name: 'CC - Cédula de ciudadanía' },
          { value: 'CE', name: 'CE - Cédula de extranjería' },
          { value: 'NIT', name: 'NIT - En caso de ser una empresa' },
          { value: 'TI', name: 'TI - Tarjeta de Identidad' },
          { value: 'PP', name: 'PP - Pasaporte' },
          {
            value: 'CEL',
            name: 'CEL - En caso de identificarse a través de la línea del móvil'
          },
          { value: 'RC', name: 'RC - Registro civil de nacimiento' },
          { value: 'DE', name: 'DE - Documento de identificación extranjero' }
        ];
      } else if (this.paymentProvider === 'MP' || this.paymentProviderTC === 'MP') {
        this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
          if (data.code === 200) {
            const dataResult = data.data;
            dataResult.forEach((element) => {
              this.typePersonOptions.push({
                name: element.name,
                value: element.id
              });
            });
          } else {
            this.typePersonOptions = [];
          }
        });
      }
      if (this.paymentProvider === 'PAYU' || this.paymentProviderTC === 'PAYU') {
        this.creditOPtionsTypePerson = [
          { value: 'CC', name: 'CC - Cédula de ciudadanía' },
          { value: 'CE', name: 'CE - Cédula de extranjería' },
          { value: 'NIT', name: 'NIT - En caso de ser una empresa' },
          { value: 'TI', name: 'TI - Tarjeta de Identidad' },
          { value: 'PP', name: 'PP - Pasaporte' },
          {
            value: 'CEL',
            name: 'CEL - En caso de identificarse a través de la línea del móvil'
          },
          { value: 'RC', name: 'RC - Registro civil de nacimiento' },
          { value: 'DE', name: 'DE - Documento de identificación extranjero' }
        ];
      } else if (this.paymentProvider === 'MP' || this.paymentProviderTC === 'MP') {
        this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
          if (data.code === 200) {
            const dataResult = data.data;
            dataResult.forEach((element) => {
              this.creditOPtionsTypePerson.push({
                name: element.name,
                value: element.id
              });
            });
          } else {
            this.typePersonOptions = [];
          }
        });
      } else {
        this.creditOPtionsTypePerson = [
          { value: 'CC', name: 'CC - Cédula de ciudadanía' },
          { value: 'CE', name: 'CE - Cédula de extranjería' },
          { value: 'NIT', name: 'NIT - En caso de ser una empresa' }
        ];
        this.typePersonOptions = this.creditOPtionsTypePerson;
      }
    },
    getStatusPSE () {
      this.$http.get('payment/banks/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          let pse = _.find(data.data, { id: 'pse' });
          if (pse) {
            this.showPse = true;
          } else {
            this.showPse = false;
          }
        } else {
          this.showPse = false;
        }
      });
    },
    getPaymentOptionsPSE () {
      this.$http.get('payment/banks/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          let info = _.find(data.data, { id: 'pse' });
          info.financial_institutions.forEach((bank) => {
            this.banks.push({ value: bank.id, name: bank.description });
          });
        } else {
          this.banks = [];
        }
      });
    },
    initYearOptions () {
      const today = new Date();
      const thisYear = parseInt(today.getFullYear());

      for (let year = thisYear; year < thisYear + 25; year++) {
        this.yearOptions.push({ name: year, value: year });
      }
    },
    initMonthOptions () {
      for (let month = 0; month < 12; month++) {
        this.monthOptions.push({ name: month + 1, value: month + 1 });
      }
    },
    postPayuPSE () {
      this.submitDisabled = true;
      this.loading = true;
      serviceReservation
        .post('/reservation/confirmPayment', {
          pse: this.psePAYU,
          reservationId: parseInt(this.decryptMx(this.id)),
          paymentProvider: 'payu'
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 799) {
            this.submitDisabled = false;
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>" + data.msg,
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            return;
          }
          if (data.transactionResponse.state === 'PENDING') {
            window.localStorage.setItem(
              '_reservation_link',
              JSON.stringify(this.reservationLink)
            );
            window.location.href = data.transactionResponse.extraParameters.BANK_URL;
          }
        })
        .catch(() => {
          this.loading = false;
          this.submitDisabled = false;
        });
    },
    postmercadoPagoPSE () {
      this.submitDisabled = true;
      this.loading = true;
      serviceReservation
        .post('/reservation/confirmPayment', {
          pse: this.pse,
          reservationId: parseInt(this.decryptMx(this.id)),
          paymentProvider: 'mp'
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 799) {
            this.submitDisabled = false;
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>" + data.msg,
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            return;
          }
          if (data.code === 200) {
            window.location.href = data.url;
          }
        })
        .catch(() => {
          this.loading = false;
          this.submitDisabled = false;
          this.$buefy.snackbar.open({
            duration: 5000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
        });
    },
    postPSETransaction () {
      if (this.paymentProvider === 'PAYU') {
        this.psePAYU.bank = this.bank;
        this.psePAYU.name = this.name;
        this.psePAYU.email = this.email;
        this.psePAYU.typeDocument = this.typePerson;
        this.psePAYU.identification = this.documentNumber;
        this.psePAYU.phone = this.phone;
        this.psePAYU.typePeople = this.personaNatural ? 'N' : 'J';
        this.psePAYU.balance = this.balancePaid;
        this.postPayuPSE();
      } else if (this.paymentProvider === 'MP') {
        this.pse.payer.email = this.email;
        this.pse.payer.identification.type = this.typePerson;
        this.pse.payer.identification.number = this.documentNumber;
        this.pse.payer.entity_type = this.personaNatural
          ? 'individual'
          : 'association';
        this.pse.transaction_details.financial_institution = this.bank;
        this.pse.transaction_amount = this.balancePaid;
        this.pse.net_amount = this.balancePaid;
        this.postmercadoPagoPSE();
      } else if (this.paymentProvider === 'WOMPI') {
        const dataWompi = {
          pse: {
            financial_institution_code: this.bank,
            user_legal_id: this.documentNumber,
            user_legal_id_type: this.typePerson,
            user_type: this.personaNatural ? '0' : '1',
            phone_number: this.phone,
            email: this.email,
            amount: this.balancePaid,
            name: this.name
          },
          redirect_url: window.location.href,
          acceptance_token: this.acceptanceToken,
          paymentProvider: 'wompi'
        };
        this.processPaymentWompi(dataWompi);
      }
    },
    postPayuCreditCard () {
      const thes = this;
      thes.submitDisabled = true;
      thes.loading = true;
      serviceReservation
        .post('/reservation/confirmPayment', {
          cardCredit: thes.card,
          reservationId: parseInt(thes.decryptMx(thes.id)),
          paymentProvider: 'payu'
        })
        .then(({ data }) => {
          if (data.code === 705) {
            thes.submitDisabled = false;
            thes.loading = false;
            const msg =
              data.paymentNetworkResponseErrorMessage != null
                ? data.paymentNetworkResponseErrorMessage
                : data.responseCode;
            thes.reasonDeclined = msg;
            thes.showCancel = true;
          }
          if (data.code === 703 || data.code === 704) {
            thes.submitDisabled = false;
            thes.loading = false;
            thes.reasonDeclined = data.message;
            thes.showCancel = true;
          }
          if (data.code === 710 || data.code === 711) {
            thes.submitDisabled = false;
            thes.loading = false;
            thes.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error <br><strong>Intenta realizar la reserva de nuevo</strong> ",
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
          }
          if (data.code === 780) {
            thes.submitDisabled = false;
            thes.loading = false;
            thes.showPending = true;
          }
          if (data.code === 200) {
            window.localStorage.setItem('_reservationOk', true);
            setTimeout(() => {
              thes.loading = false;
              clearInterval(thes.interval);
              thes.timeEnd = true;
              window.location.href = '/confirmation?id=' + thes.id;
            }, 600);
          }
        })
        .catch(() => {
          thes.submitDisabled = false;
          thes.loading = false;
          thes.$buefy.snackbar.open({
            duration: 5000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
        });
    },
    postMercadopagoCreditCard () {
      const thes = this;
      thes.submitDisabled = true;
      thes.loading = true;
      serviceReservation
        .post('/reservation/confirmPayment', {
          cardCredit: thes.cardMercadoPago,
          reservationId: parseInt(thes.decryptMx(thes.id)),
          paymentProvider: 'mp'
        })
        .then(({ data }) => {
          if (data.code === 799) {
            thes.submitDisabled = false;
            thes.loading = false;
            thes.submitDisabled = false;
            thes.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>" + data.msg,
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            return;
          }
          if (data.code === 705) {
            thes.submitDisabled = false;
            thes.loading = false;
            thes.reasonDeclined = data.message;
            thes.showCancel = true;
          }
          if (data.code === 780) {
            thes.submitDisabled = false;
            thes.loading = false;
            thes.showPending = true;
          }
          if (data.code === 200) {
            window.localStorage.setItem('_reservationOk', true);
            setTimeout(() => {
              thes.loading = false;
              thes.submitDisabled = false;
              clearInterval(thes.interval);
              thes.timeEnd = true;
              window.location.href = '/confirmation?id=' + thes.id;
            }, 600);
          }
        })
        .catch(() => {
          thes.submitDisabled = false;
          thes.loading = false;
        });
    },
    async postCreditCardTransaction () {
      if (this.paymentProviderTC === 'PAYU') {
        let month = this.month;
        if (month < 10) {
          month = '0' + this.month;
        }
        this.card.name = this.nameLikeCard;
        this.card.identification = this.documentNumber;
        this.card.phone = this.phone;
        this.card.email = this.email;
        this.card.mes = this.month;
        this.card.ano = this.year;
        this.card.expiration = this.year + '/' + month;
        this.card.cuota = this.cuots;
        this.card.card = this.cardNumber.split(/\s+/).join('');
        this.card.address = 'Bogotá Dc';
        this.card.city = 'Bogotá Dc';
        this.card.balance = this.balancePaid;
        this.postPayuCreditCard();
      } else if (this.paymentProviderTC === 'MP') {
        this.cardMercadoPago.payer.first_name = this.nameLikeCard;
        this.cardMercadoPago.payer.email = this.nameLikeCard;
        this.cardMercadoPago.payer.identification.type = this.typePerson;
        this.cardMercadoPago.payer.identification.number = this.documentNumber;
        this.cardMercadoPago.payer.email = this.email;
        this.cardMercadoPago.transaction_amount = this.balancePaid;
        this.cardMercadoPago.net_amount = this.balancePaid;
        this.cardMercadoPago.installments = this.cuots;
        const token = await this.mercadopago.fields.createCardToken({
          cardholderName: document.getElementById('cardholderName').value,
          identificationType: document.getElementById('docType').value,
          identificationNumber: document.getElementById('docNumber').value
        });
        this.cardMercadoPago.token = token.id;
        this.setCardTokenAndPay(token.id);
      } else if (this.paymentProviderTC === 'WOMPI') {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            return;
          }
          const wompiData = {
            cardCredit: {
              number: this.cardNumber.split(/\s+/).join(''),
              cvc: this.wompiCard.cvv,
              exp_month: this.wompiCard.month,
              exp_year: this.wompiCard.year,
              card_holder: this.nameLikeCard,
              email: this.email,
              amount: this.balancePaid,
              cuota: this.cuots
            },
            paymentProvider: 'wompi',
            acceptance_token: this.acceptanceToken
          };
          this.processPaymentWompi(wompiData, false);
        });
      }
    },
    InitInterval (date) {
      const MILLISECONDS_OF_A_SECOND = 1000;
      const MILLISECONDS_OF_A_MINUTE = MILLISECONDS_OF_A_SECOND * 60;
      const MILLISECONDS_OF_A_HOUR = MILLISECONDS_OF_A_MINUTE * 60;
      this.interval = setInterval(() => {
        const DATE_TARGET = date;
        const NOW = parseInt(this.$moment(this.serverTimeCurrent.add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')).format('x'));
        const DURATION = DATE_TARGET - NOW;
        const REMAINING_MINUTES = Math.floor(
          (DURATION % MILLISECONDS_OF_A_HOUR) / MILLISECONDS_OF_A_MINUTE
        );
        const REMAINING_SECONDS = Math.floor(
          (DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND
        );
        if (REMAINING_SECONDS >= 0 && REMAINING_MINUTES >= 0) {
          const seconds =
            (REMAINING_SECONDS + '').length < 2
              ? '0' + REMAINING_SECONDS
              : REMAINING_SECONDS;
          const minutes =
            (REMAINING_MINUTES + '').length < 2
              ? '0' + REMAINING_MINUTES
              : REMAINING_MINUTES;
          this.timer = minutes + ':' + seconds;
        } else {
          clearInterval(this.interval);
          this.timeEnd = true;
          this.endTime();
          this.showTimeOver = true;
        }
      }, MILLISECONDS_OF_A_SECOND);
    },
    getReservation (reservationId) {
      this.loading = true;
      this.$http.get('reservation/detail/' + reservationId).then(({ data }) => {
        this.loading = false;
        this.reservation = data;
        if (this.reservation.indicativo && this.reservation.bandera) {
          this.initCode = parseInt(this.reservation.indicativo);
          this.initBandera = this.reservation.bandera;
        }
        this.reservation.dateTime = parseInt(this.reservation.date);
        this.reservation.infoId = this.reservation.id;
        if (this.reservation.codeStatus === 3 || this.reservation.codeStatus === 4 || this.reservation.codeStatus === 5 || this.reservation.paymentLink == null) {
          clearInterval(this.interval);
          this.timeEnd = true;
          this.$buefy.dialog.alert({
            message: 'La reserva ya cuenta con un pago procesado',
            confirmText: 'Aceptar',
            onConfirm: () => {
              window.location.href = '/select';
            }
          });
        }
      }).catch(() => {
        clearInterval(this.interval);
        this.timeEnd = true;
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>" +
            'Ha ocurriddo un error encontrando tu reserva',
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      });
    },
    setCoutes (cuotes) {
      this.CoutesLimit = [];
      cuotes.forEach((cuote) => {
        this.CoutesLimit.push({
          name: cuote.installments,
          value: cuote.installments
        });
      });

      this.cuotesReady = true;
    },
    processPaymentWompi (bodyData, pse = true) {
      this.submitDisabled = true;
      if (!pse) this.$store.commit(globalTypes.mutations.setVerifyingPayment, true);
      else this.loading = true;
      serviceReservation
        .post('/reservation/confirmPayment', { ...bodyData, reservationId: parseInt(this.decryptMx(this.id)) })
        .then(({ data }) => {
          if (pse && data.code === 200) {
            window.location.href = data.paymentUrl;
            return;
          }
          if (data.code === 705) {
            this.reasonDeclined = data.message;
            this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
            this.showCancel = true;
          }
          if (data.code === 780) {
            this.longPollTransaction(`transactionId=${data.data.transactionId}`);
          }
          if (data.code === 200) {
            window.localStorage.setItem('_reservationOk', true);
            setTimeout(() => {
              clearInterval(this.interval);
              this.timeEnd = true;
              this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
              window.location.href = '/confirmation?id=' + this.id;
            }, 600);
          }
        })
        .catch((err) => {
          this.$buefy.snackbar.open({
            duration: 5000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i>" + err,
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
          this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
        }).finally(() => {
          this.submitDisabled = false;
          this.loading = false;
        });
    },
    getBanksAcceptanceToken () {
      this.$http.get('/payment/wompi/banks/' + this.vendorId)
        .then(({ data }) => {
          this.banks = data.data.map(item => {
            return { value: item.financial_institution_code, name: item.financial_institution_name };
          });
        });
      this.$http.get('/payment/wompi/acceptance-token/' + this.vendorId)
        .then(({ data }) => {
          this.linkTerms = data.data.permalink;
          this.acceptanceToken = data.data.acceptance_token;
        });
    },
    checkRedirectionWompi () {
      const transactionId = this.$route.query.id;
      if (transactionId && transactionId.includes('-')) {
        this.$store.commit(globalTypes.mutations.setVerifyingPayment, true);
        setTimeout(() => {
          this.longPollTransaction(`reservationId=${parseInt(this.decryptMx(this.id))}`);
        }, 1000);
      }
    },
    longPollTransaction (searchParam) {
      this.$http.get('/payment/status-transaction?paymentProvider=wompi&' + searchParam)
        .then(({ data }) => {
          if (data.status === 'APPROVED') {
            window.localStorage.setItem('_reservationOk', true);
            setTimeout(() => {
              clearInterval(this.interval);
              this.timeEnd = true;
              window.location.href = '/confirmation?id=' + this.id;
            }, 600);
          }
          if (data.status === 'DECLINED' || data.status === 'ERROR') {
            setTimeout(() => {
              this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
              this.reasonDeclined = data.message;
              this.showCancel = true;
            }, 1000);
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.longPollingTransaction(searchParam);
          }, 700);
        });
    }
  },
  watch: {
    dataTreatment () {
      if (this.dataTreatment && this.termsConditions) {
        this.lock = false;
      }
    },
    termsConditions () {
      if (this.dataTreatment && this.termsConditions) {
        this.lock = false;
      }
    },
    cardNumber (cardNumber) {
      this.cardNumber = this.creditCardFormatter(cardNumber.split(/\s+/).join(''));
      const cardJustNumber = this.cardNumber.split(/\s+/).join('');
      this.cardMercadoPago.payment_method_id = null;
      const regexDiner = new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{5,}$/);
      const regexVisa = new RegExp(/^4[0-9]{6,}$/);
      const regexAmex = new RegExp(/^3[47][0-9]{5,}$/);
      const regexMasterCard = new RegExp(/^5[1-5][0-9]{14}$/);

      if (regexVisa.test(cardJustNumber)) {
        this.cardType = 'Visa';
      } else if (regexAmex.test(cardJustNumber)) {
        this.cardType = 'Amex';
      } else if (regexMasterCard.test(cardJustNumber)) {
        this.cardType = 'MasterCard';
      } else if (regexDiner.test(cardJustNumber)) {
        this.maxAllowed = 21;
        this.cardType = 'Diners';
      } else {
        this.cardType = '';
        this.maxAllowed = 19;
      }
    },
    personaJuridica () {
      if (this.personaJuridica) {
        this.personaNatural = false;
      }
      if (!this.personaJuridica && !this.personaNatural) {
        this.personaNatural = true;
      }
    },
    personaNatural (value) {
      if (this.personaNatural) {
        this.personaJuridica = false;
      }
      if (!this.personaJuridica && !this.personaNatural) {
        this.personaJuridica = true;
      }
    },
    creditMethod () {
      if (this.paymentProviderTC === 'MP') {
        this.setConfigMercadoPago();
      }
    }
  },
  computed: {
    ...mapGetters({
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults],
      reservationLink: reservationTypes.getters.reservationsUserLink
    }),
    submitReady () {
      if (!this.creditMethod) {
        if (
          this.name &&
          this.documentNumber &&
          this.phone &&
          this.bank &&
          this.typePerson
        ) {
          return true;
        }
      } else {
        if (
          this.nameLikeCard &&
          this.documentNumber &&
          this.phone &&
          this.typePerson &&
          this.cuots &&
          this.paymentProviderTC === 'MP'
        ) {
          return true;
        } else if (
          this.paymentProviderTC === 'PAYU' &&
          this.nameLikeCard &&
          this.documentNumber &&
          this.phone &&
          this.typePerson &&
          this.cardNumber &&
          this.month &&
          this.year &&
          this.cuots
        ) {
          return true;
        } else if (
          this.paymentProviderTC === 'WOMPI' &&
          this.nameLikeCard &&
          this.documentNumber &&
          this.phone &&
          this.typePerson &&
          this.cuots &&
          this.cardNumber &&
          this.wompiCard.cvv &&
          this.wompiCard.month &&
          this.wompiCard.year
        ) {
          return true;
        }
      }
      return false;
    }
  },
  destroyed () {
    clearInterval(this.interval);
    this.timeEnd = true;
  }
};
</script>
<style lang="scss" scoped>
#time-over-modal{
  .modals-bosy{
    max-width: 358px;
    padding: 30px !important;
    margin: 0 auto;
  }
  .icon_time_over{
    width: 55px;
    height: 55px;
    margin: 0 auto;
  }
  .title-over{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .text-over{
    font-size: 14px;
    text-align: center;
  }
  .btns-over{
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .btn-prec{
      background-color: #444b57;
      color: #fff;
      padding: 6px 12px;
      border: none;
      border-radius: 6px;
      min-width: 136px;
      cursor: pointer;
    }
  }
}
.mp_fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &-item {
    height: 29px;
    border: 1px solid transparent;
    background-color: white;
    border-color: #444b57;
    border-radius: 4px;
    position: relative;
    vertical-align: top;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: #363636;
    padding-bottom: calc(0.2em - 1px);
    padding-left: calc(0.5em - 1px);
    padding-right: calc(0.5em - 1px);
    padding-top: calc(0.2em - 1px);
  }
  .w50 {
    width: 50%;
  }
  .w20 {
    width: 20%;
  }
}
.large-email{
  width: 99.8% !important;
}
.cardSelected {
  box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 4px black;
}
.termsC {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 90%;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 8px;
  padding-bottom: unset;
  overflow: hidden;
  max-height: 70%;
  border-radius: 8px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.582);
}
@keyframes loader {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.loadingScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 101.8vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.37);
  z-index: 49;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-width: 600px;
    span {
      display: block;
      background-color: #bdc30e;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation-name: loader;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      &:nth-child(2) {
        animation-delay: 0.8s;
      }
      &:nth-child(3) {
        animation-delay: 1.6s;
      }
    }
  }
}
#precompraPlatform {
  overflow: scroll;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
  min-width: 100vw;
  overflow-x: hidden;
  background-color: #d9dbde4d;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @mixin imageSet($width, $height, $url) {
    background-image: url($url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: $width;
    height: $height;
    display: block;
  }
  input {
    color: #444b57;
  }
  .generalInputContainer {
    width: 100%;
    @media (min-width: 768px) {
      width: 36%;
    }
    @media (min-width: 1024px) {
      width: 46%;
    }
  }

  .IconPrecompro {
    @include imageSet(16px, 16px, "../assets/images/icons/precomproIcon.svg");
  }
  .iconClock {
    @include imageSet(18px, 18px, "../assets/images/icons/horariosIcon.svg");
  }
  .iconCalendar {
    @include imageSet(
      18px,
      18px,
      "../assets/images/icons/calendarIcon3points.svg"
    );
  }
  .iconPerson {
    @include imageSet(18px, 18px, "../assets/images/icons/usuarioIcon.svg");
  }
  .topHeader {
    height: 6vh;
    min-height: 86px;
    background-color: #2c3e50;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    img{
      max-height: 100%;
      width: auto;
    }
  }

  .principalMain {
    display: flex;
    gap: 20px;
    padding: 0 10px;
    justify-content: center;
    .payInfo--cantUse {
      pointer-events: none;
    }
    .information, .payInfo {
      max-width: 610px;
      // min-height: 460px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      width: 96%;
      background-color: white;
      border-radius: 8px;
      font-size: 14px;
      button {
        width: 60%;
        min-height: 30px;
        font-weight: 600;
        font-size: 12px;
        outline: unset;
        border: unset;
        color: white;
        background-color: #444b57;
        border-radius: 6px;
        opacity: 0.6;
        margin: 0 auto;
        @media (min-width: 768px) {
          max-width: 230px;
        }
      }
    }
    .information {
      position: relative;
      header {
        padding-bottom: 7px;
        border-bottom: solid 1px black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .header__leftSide {
          width: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          h2 {
            font-family: "Source Sans Pro", sans-serif;
            font-weight: 600;
            margin-left: 5px;
            font-size: 14px;
          }
        }
        .header__rightSide {
          h2 {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 16px;
            font-weight: 600;
          }
          min-width: 52%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (min-width: 768px) {
            min-width: 40%;
            justify-content: flex-end;
            gap: 15px;
          }
          p {
            color: #444b57;
            font-size: 10px;
            font-weight: 400;
            @media (min-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
      &__mainContent {
        width: 100%;
        flex: 1;
        justify-content: space-between;
        display: flex;
        align-items: center;
        flex-direction: column;
        .mainContent__cardContainer {
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 90%;
          @media (min-width: 1020px) {
            padding-top: 25px;
          }
          .cardContainer__card {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            border: solid 2px #eeefef;
            border-radius: 5px;
            width: 100px;
            height: 100px;
            h4 {
              font-size: 12px;
              font-weight: 400;
            }
            p {
              font-weight: 600;
            }
          }
        }
        .mainContent__detailsCardsContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin: unset;
          @media (min-width: 768px) {
            width: 96%;
            justify-content: space-between;
          }
          .detailsCardsContainer__detail {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
            font-size: 12px;
            @media (min-width: 768px) {
              font-size: 14px;
            }
            span {
              margin-left: 5px;
              font-weight: 600;
            }
          }
        }
        .mainContent__smallLetter {
          font-size: 11px;
          font-weight: 300;
          margin: 1rem 0;
          color: #444b57;
          width: 100%;
          margin-left: 20px;
          @media (min-width: 768px) {
            font-size: 12px;
          }
        }
        .mainContent__clientData {
          padding: 16px 12px;
          border-radius: 8px;
          background-color: #f5f6f6;
          display: flex;
          flex-direction: column;
          align-items: center;
          .data-client-header {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 15px;
            border: unset;
            width: 100%;
            h2 {
              font-family: "Source Sans Pro", sans-serif;
              margin-left: 8px;
              font-weight: 600;
              font-size: 14px;
            }
            .small-text-pay{
              font-size: 12px;
              margin-left: 5px;
            }
          }
          .clientData__form {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 10px;

            .inputContainer {
              border: #444b57 1px solid;
              border-radius: 6px;
              input {
                border: unset;
              }

              &.client-name {
                width: 61.5%;
              }
              &.client-phone {
                width: 36%;
                border: unset;
                position: relative;
                input {
                  padding-left: 25px;
                }
                span {
                  position: absolute;
                  left: 10px;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  @include imageSet(
                    15px,
                    15px,
                    "../assets/images/icons/celIcon.svg"
                  );
                }
              }
              &.client-email {
                position: relative;
                width: 48.7%;
                input {
                  padding-left: 25px;
                }
                span {
                  position: absolute;
                  left: 10px;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  @include imageSet(
                    15px,
                    15px,
                    "../assets/images/icons/mailIcon.svg"
                  );
                }
              }
              &.client-identification{
                border: unset;
                width: 48.7%;
              }
              input {
                font-size: 12px;
                width: 100%;
                border-radius: 5px;
                outline: unset;
                background-color: transparent;
                text-indent: 9px;
                min-height: 30px;
                pointer-events: none;
              }
            }
          }
          .form__checkbox {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            width: 100%;
            gap: 10px;
            font-weight: 300;
            font-size: 10px;
            flex-wrap: wrap;
            color: #444b57;
            .condition-checkbox{
              display: flex;
              gap: 18px;
            }
            b {
              text-decoration: underline;
              cursor: pointer;
            }
            input {
              cursor: initial;
            }
            &:nth-child(6) {
              @media (min-width: 768px) and (max-width: 1020px) {
                width: 58%;
                font-weight: 300px;
              }
              @media (min-width: 1280px) {
                width: 65%;
              }
            }
            .container {
              width: 16px !important;
              height: 16px;
              padding: unset;
              margin: unset;
              display: block;
              position: relative;
              cursor: pointer;
              font-size: 22px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              flex-grow: unset;
            }
            /* Hide the browser's default checkbox */
            .container input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 16px;
              width: 16px;
              background-color: #eee;
              border-radius: 3px;
            }

            /* On mouse-over, add a grey background color */
            .container:hover input ~ .checkmark {
              background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            .container input:checked ~ .checkmark {
              background-color: #444b57;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Show the checkmark when checked */
            .container input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the checkmark/indicator */
            .container .checkmark:after {
              left: 6px;
              top: 3px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .payInfo {
      &--cantUse {
        pointer-events: none;
        opacity: 0.8;
      }
      &__title {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-bottom: 7px;
        font-weight: 600;
        font-family: "Source Sans Pro", sans-serif;
      }
      nav {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 1px;
        gap: 1px;
        .payInfo__tabs {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px;
          width: 50%;
          border: solid 1px #eaebed;
          border-bottom: unset;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          min-height: 75px;
          max-height: 80px;
          justify-content: space-evenly;
          border-bottom: solid 3px #ecedee;
          h1 {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 6px;
          }
          .PSEIcon {
            @include imageSet(
              21px,
              19px,
              "../assets/images/payPlatforms/PSEImage.svg"
            );
          }
          .VisaIcon {
            @include imageSet(
              48px,
              19px,
              "../assets/images/payPlatforms/VisaImg.svg"
            );
          }
          .masterCardIcon {
            @include imageSet(
              21px,
              19px,
              "../assets/images/payPlatforms/mastercardImage.svg"
            );
          }
          .clubIcon {
            @include imageSet(
              21px,
              19px,
              "../assets/images/payPlatforms/clubImg.svg"
            );
          }
          .americanExpressIcon {
            @include imageSet(
              21px,
              19px,
              "../assets/images/payPlatforms/americanExpress.svg"
            );
          }
          .paymentsContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;
          }
        }
        .selected {
          border-bottom: solid 3px #444b57;
        }
      }
      button {
        cursor: not-allowed;
      }
      button:hover {
        filter: brightness(1.2);
      }
      .readyToSubmit {
        background-color: #bdc30e;
        color: white;
        cursor: pointer;
      }
      form {
        position: relative;
        top: -1px;
        border-top: unset;
        border: solid 1px #eaebed;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        flex: 1;
        .securePay {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 15px;
          padding-bottom: 10px;
          @media (min-width: 1024px) {
            padding-bottom: 3px;
          }
          .mercadoPagoImg {
            @include imageSet(
              40px,
              28px,
              "../assets/images/payPlatforms/mercadoPagoImg.svg"
            );
          }
          .payuImg {
            @include imageSet(
              40px,
              28px,
              "../assets/images/payPlatforms/payuIcon.svg"
            );
          }
          .wompiImg {
            @include imageSet(
              40px,
              28px,
              "../assets/images/payPlatforms/wompi.svg"
            );
          }
        }
      }

      &__debitInformation {
        padding: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;

        .mail-input{
          width: 40.3%;
        }
        .switchesContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: row;
          padding: 6px 0;
          @media (min-width: 1024px) {
            padding: 2px 0;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 12px;
              font-weight: 400;
              margin-left: 10px;
            }
          }
        }
        .additionalInfo {
          & > p {
            font-size: 10px;
            font-weight: 300;
            width: 97%;
          }
        }
        .selectorContent--two {
          width: 100%;
        }
        input,
        select {
          font-size: 12px;
          border-radius: 5px;
          outline: unset;
          border: solid 1px #444b57;
          min-height: 30px;
          text-indent: 15px;
          &:nth-child(2) {
            width: 100%;
          }
          &:nth-child(3) {
            width: 40%;
          }
          &:nth-child(4) {
            width: 48.8%;
          }
        }
        .inputContainerC {
          width: 33%;
          z-index: 39;
          position: relative;
          input {
            width: 100%;
          }
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 28%;
          }
          span {
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            @include imageSet(15px, 15px, "../assets/images/icons/celIcon.svg");
          }
        }
        .selectorContentDocoument {
          width: 48.5%;
        }
      }
      &__creditInformation {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        gap: 12px;
        height: 1px;
        height: fit-content;
        h1 {
          font-weight: 400;
          font-size: 14px;
          color: #444b57;
          width: 100%;
          text-align: start;
          @media (min-width: 768px) and (max-width: 1020px) {
            padding: 5px 0;
          }
        }
        .inputContainer {
          width: 40%;
          z-index: 39;
          position: relative;
          span {
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            @include imageSet(15px, 15px, "../assets/images/icons/celIcon.svg");
          }
        }
        input {
          width: 100%;
          font-size: 12px;
          border-radius: 5px;
          outline: unset;
          border: solid 1px #444b57;
          min-height: 30px;
          text-indent: 15px;
          &:nth-child(1):not(.phoneInput) {
            width: 99.6%;
          }
          &:nth-child(3) {
            width: 48.5%;
          }
          &:nth-child(4) {
            width: 57%;
          }
          &:nth-child(7) {
            width: 67%;
          }
        }
        .cvvContainer {
          display: flex;
          align-items: center;
          width: 25%;
          justify-content: space-between;
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 15%;
          }
          @media (min-width: 1280px) {
            width: 19%;
          }
          @media (min-width: 1600px) {
            width: 18%;
          }
          p {
            color: #444b57;
            font-weight: 400;
            font-size: 12px;
            max-width: 25px;
          }
          input {
            width: 70%;
          }
        }
        .mesContainer {
          width: 21%;
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 12%;
          }
          @media (min-width: 1280px) {
            width: 17%;
          }
          @media (min-width: 1600px) {
            width: 12%;
          }
        }
        .anoContainer {
          position: relative;
          right: 22.2%;
          width: 23%;
          @media (min-width: 768px) {
            right: unset;
          }
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 12%;
          }
          @media (min-width: 1024px) {
            right: 20.3%;
          }
          @media (min-width: 1280px) {
            width: 16%;
            right: 0;
          }
          &.cvvHidden {
            right: initial;
          }
        }
        .selectorContentDocoument {
          width: 48.7%;
        }
        .NoCuotasContainer {
          position: relative;
          width: 27.1%;
          justify-self: start;
        }
      }
    }

    @media screen and (max-width: 1023px){
      flex-direction: column;
      align-items: center;
      gap: 5px;
      .payInfo, .information{
        margin-top: 10px;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px){
      .payInfo{
        &__creditInformation{
          input:nth-child(1):not(.phoneInput){
            width: 50%;
          }
          .selectorContentDocoument{
            width: 47%;
          }
          input:nth-child(3){
            width: 30%;
          }
          input:nth-child(4){
            width: 36%;
          }
          .inputContainer{
            width: 29%;
          }
        }
        &__debitInformation{
          .selectorContent--two{
            width: 99%;
          }
          input,select{
            &:nth-child(2){
              width: 50%;
            }
            &:nth-child(4){
              width: 30%;
            }
          }
          .mail-input{
            width: 36%;
          }
          .inputContainerC{
            width: 29%;
          }
          .selectorContentDocoument{
            width: 47%;
          }
        }
      }
    }
    @media screen and (max-width: 530px){
      padding: 0;
      .information, .payInfo{
        padding: 10px 5px;
      }
      .information{
        .mainContent__cardContainer{
          width: 100%;
        }
      }
      .inputContainer{
        &.client-name{
          width: 56.7% !important;
        }
        &.client-phone{
          width: 40% !important;
        }
        &.client-identification{
          width: 47% !important;
        }
      }
      .payInfo{
        &__creditInformation{
          input:nth-child(3){
            width: 47%;
          }
          input:nth-child(4){
            width: 48.7%;
          }
          .inputContainer{
            width: 47%;
          }
        }
        &__debitInformation{
          .selectorContent--two{
            width: 99.5%;
          }
          input,select{
            &:nth-child(2){
              width: 99.5%;
            }
            &:nth-child(4){
              width: 47.8%;
            }
          }

          .inputContainerC{
            width: 43.3%;
          }
          .mail-input{
            width: 53%;
          }
        }
      }
    }
  }
  footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
    padding: 20px 0;
    .logoBuildByPrecompro {
      @include imageSet(
        190px,
        20px,
        "../assets/images/logos/buildByPrecomproLogo.svg"
      );
    }
  }
}
#CardNumberInput {
  position: relative;
  &::after {
    position: absolute;
    background-color: red;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    content: attr(afterContent) "";
  }
}
</style>
<!--Edinson mi culpa si algo pasa-->
<style lang="scss">
.inputContainer {
  .text-lef-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.text_error_card {
  color: red;
  font-size: 11px;
  font-weight: 600;
  margin-top: 5px;
}
</style>
