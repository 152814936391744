<template>
  <b-modal :active.sync="editReservation" :can-cancel="false" id="editReservationNew">
    <div class="modal-body" :style="{backgroundColor: getBackOpa()}">
      <div class="logoDiv"
        :class="{
          'logoBorder': (design.typeLogo == 'border'),
          'logoRibbon': (design.typeLogo == 'ribbon'),
          'padding': (design.typeLogo == 'basic2'),
        }">
        <div class="centerlog" v-if="design.typeLogo == 'basic'">
          <img :src="vendor.lightLogoURL" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'ancho'">
          <img :src="vendor.lightLogoURL" class="mx-auto max-h200" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'basic2'">
          <img :src="vendor.lightLogoURL" class="basic2" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'basic3'">
          <img :src="vendor.lightLogoURL" class="basic3" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'border'">
          <img :src="vendor.lightLogoURL" class="mx-auto d-block img-border logo-border-img" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'large'">
          <img :src="vendor.lightLogoURL" class="mx-auto d-block img-large" />
        </div>
        <div class="rabion" v-if="design.typeLogo == 'ribbon'">
          <svg height="160" width="100">
            <polygon
              class="bookmark"
              data-v-368b4645
              points="0,0 0,150 50,120 100,150 100,0"
            />
          </svg>
          <img :src="vendor.lightLogoURL " />
        </div>
      </div>

      <!-- Datos de reserva actual y nueva -->
      <header class="edit-reservation-header">
        <section class="current-reservation" v-show="showAll || !showPaymentOptions">
          <h3 class="title-modal">{{ $t('newEditReservation.currentReservation') }}</h3>
          <div class="data-card-container">
            <div class="small data-card">
              <i class="icon_user_outlined icon-mks"></i>
              <p class="text-modal">{{ dataReservationEdit.people }} pax</p>
            </div>
            <div class="small data-card">
              <i class="icon_calendar icon-mks"></i>
              <p class="text-modal">{{ parseInt(dataReservationEdit.date) | moment('timezone', vendorTimezone, 'DD/MMM/YY') }}</p>
            </div>
            <div class="small data-card">
              <i class="icon_hour_outlined icon-mks"></i>
              <p class="text-modal">{{ parseInt(dataReservationEdit.date) | moment('timezone', vendorTimezone, 'hh:mm a') }}</p>
            </div>
          </div>
        </section>

        <section class="modified-reservation">
          <h3 class="title-modal">{{ $t('newEditReservation.modifiedReservation') }}</h3>
          <div class="data-card-container">
            <div class="small data-card inpulse-card" :class="{active: selectedPeople && parseInt(selectedPeople) != parseInt(dataReservationEdit.people)}" @click="sectionEdit = 1">
              <i class="icon_user_outlined icon-mks"></i>
              <p class="text-modal" v-if="selectedPeople && selectedPeople != dataReservationEdit.people">{{selectedPeople}} pax</p>
              <p class="text-modal" v-else> -- pax</p>
            </div>
            <div class="small data-card inpulse-card" :class="{active: selectedDate && !isSameDate(selectedDate)}" @click="sectionEdit = 2">
              <i class="icon_calendar icon-mks"></i>
              <p class="text-modal" v-if="selectedDate && !isSameDate(selectedDate)">
                {{ parseInt(selectedDate) | moment('timezone', vendorTimezone, 'DD/MMM/YY') }}
              </p>
              <p class="text-modal" v-else>
                --/--/--
              </p>
            </div>
            <div class="small data-card inpulse-card" :class="{active: selectedHour && parseInt(selectedHour) != parseInt(dataReservationEdit.date)}" @click="sectionEdit = 3">
              <i class="icon_hour_outlined icon-mks"></i>
              <p class="text-modal" v-if="selectedHour && selectedHour != dataReservationEdit.date">
                {{ parseInt(selectedHour) | moment('timezone', vendorTimezone, 'hh:mm a') }}
              </p>
              <p class="text-modal" v-else>
                --:-- --
              </p>
            </div>
          </div>
        </section>
      </header>

      <!-- Opciones para modificar reserva -->
      <template v-if="!showPaymentOptions">
        <h3 class="title-modal my-6px">{{ $t('modifyReservaton') }}</h3>
        <div class="data-card-container options">

          <div class="data-card medium main" :style="`${this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1 ? 'width: 500px': ''}`" v-show="showAll || sectionEdit == 1">
            <People
              :colorPrimary="design.colorModal"
              :editPeople="true"
              :useNewPeople="true"
              :infoReservation="dataReservationEdit"
              :isFrees="(dataReservationEdit.balancePaid <= 0) ? true : false"
              :minimunPeoples="dataReservationEdit.people"
              :params="params"
              :peoples="dataReservationEdit.people"
              :showTitle="false"
              id="EditPeople"
            ></People>
          </div>

          <div class="data-card medium main calendar-option" :style="{padding: (moreD) ? '20px 20px 0px 20px' : '20px'}" v-show="showAll || sectionEdit == 2">
            <p class="title-card-modal edit">{{ $t('chooseAvailableDate') }}</p>
            <dateTime
              :editPeople="true"
              :editDaySelected="editReservationDate"
              @change-date="changeDate"
              @show-modal-select-zone="selectZone($event)"
              @show-modal-select-zone-map="selectZoneMap"
              @moreDates="moreD = $event"
              id="EditCalendar"
            ></dateTime>
          </div>

          <div class="data-card medium main" v-show="showAll || sectionEdit == 3">
            <Shedule
              :colorPrimary="design.colorModal"
              :params="params"
              :editPeople="true"
              :sectionEdit="sectionEdit"
              :showTitle="false"
            ></Shedule>
          </div>
        </div>
        <div class="btns-modal" v-if="showAll">
          <button @click="boolDialog" class="btn-modal" type="button">{{ $t('cancel') }}</button>
          <button
            :class="{ 'disabled': !selectedHour || noChangeReservation }"
            :disabled="!selectedHour || noChangeReservation"
            :loading="modified"
            @click="validateSubmit"
            class="btn-modal btn-modal-confirm">
            <span
              v-if="dataReservationEdit.balancePaid <= 0 || dataReservationEdit.balancePaid === null"
            >{{ (isFree) ? $t('confirm') : $t('pay') }}</span>
            <span
              v-else
            >{{ ((this.selectedPeople - this.dataReservationEdit.people) > 0 && !isFree) ? $t('pay') : $t('confirm') }}</span>
          </button>
        </div>

        <!-- Botones Movile -->
        <div class="btns-modal" v-else>
          <button @click="boolDialog" class="btn-modal" type="button" v-show="sectionEdit == 1">
            {{ $t('cancel') }}
          </button>
          <button @click="prevSection" class="btn-modal btn-modal-confirm" type="button" v-show="sectionEdit != 1">
            {{ $t('newEditReservation.previous') }}
          </button>
          <button class="btn-modal btn-modal-confirm" :loading="modified" @click="nextSection" v-show="sectionEdit != 3">
            {{ $t('newEditReservation.next') }}
          </button>
          <button :class="{ 'disabled': !selectedHour || noChangeReservation }" :disabled="!selectedHour || noChangeReservation" v-show="sectionEdit== 3"
            :loading="modified"
            @click="validateSubmit"
            class="btn-modal btn-modal-confirm">
            <span
              v-if="dataReservationEdit.balancePaid <= 0 || dataReservationEdit.balancePaid === null"
            >{{ (isFree) ? $t('confirm') : $t('pay') }}</span>
            <span
              v-else
            >{{ ((this.selectedPeople - this.dataReservationEdit.people) > 0 && !isFree) ? $t('pay') : $t('confirm') }}</span>
          </button>
        </div>
      </template>

      <!-- Datos para el pago -->
      <template v-else>
        <h3 class="title-modal my-6px">{{ $t('payment') }}</h3>
        <div class="data-card-container payment-options">

          <div class="data-card medium">
            <p class="subtitle-modal">{{ $t('newEditReservation.currentPeople') }}</p>
            <span class="payment-indication">
              <i class="icon_user_outlined icon-mks"></i>
              {{ dataReservationEdit.people }} pax
            </span>
            <p class="text-modal">
              {{ $t('newEditReservation.paidValue') }}: <br>
              {{ (dataReservationEdit.balancePaid) | currency("$ ", 0, { thousandsSeparator: "," }) }} x {{ dataReservationEdit.people }} pax
            </p>
          </div>

          <div class="data-card medium">
            <p class="subtitle-modal">{{ $t('newEditReservation.aditionalPeople') }}</p>
            <span class="payment-indication">
              <i class="icon_user_outlined icon-mks"></i>
              {{ selectedPeople - dataReservationEdit.people }} pax
            </span>
            <p class="text-modal">
              {{ $t('newEditReservation.aditionalValue') }}: <br>
              {{ (aditionalAmount) | currency("$ ", 0, { thousandsSeparator: "," }) }} x {{ getAditionalPeople() }} pax
            </p>
          </div>

          <div class="aditional-value-pay">
            <p class="subtitle-modal">{{ $t('newEditReservation.valueToPay') }}</p>
            <span class="payment-indication-medium">
              {{ $t('newEditReservation.aditionalValue') }}: <br>
              {{ (aditionalAmount) | currency("$ ", 0, { thousandsSeparator: "," }) }} x {{ getAditionalPeople() }} pax
            </span>
          </div>

        </div>
        <div class="btns-modal">
          <button @click="boolDialog" class="btn-modal" type="button">{{ $t('cancel') }}</button>
          <button
            :loading="modified"
            @click="updateReservation()"
            class="btn-modal btn-modal-confirm">
            <span>{{ $t('pay') }}</span>
          </button>
        </div>
      </template>

      <SelectZoneEdit :prevDate="prevDate" v-if="showSelectZone" v-model="showSelectZone" :editZone="dataReservationEdit"></SelectZoneEdit>
      <GairaMap
        :prevDate="prevDate"
        @hide-select-zone-map="showSelectZoneMap = false"
        v-if="showSelectZoneMap && params.isMapped && vendor.id == 72 && selectedTypeReservation == 'Rumba'"
      ></GairaMap>
    </div>
    <b-modal :active.sync="corporative" :can-cancel="false">
      <div class="modals-bosy">
        <button @click="corporative = false" class="modal-close-btn" type="button">
          <span class="Reservation-title">&times;</span>
        </button>
        <h3 class="title-modal">{{ $t('information') }}</h3>
        <div
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px; "
        >{{ $t('contactNeedNumberPerson') }}</div>
        <span
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px;"
          v-if="vendor.phone"
        >
          <b>Teléfonos:</b>
          {{ vendor.phone }}
        </span>
        <br />
        <span
          class="general-subtitle"
          style="text-align:center;margin-bottom:20px;"
          v-if="vendor.email"
        >
          <b>{{ $t('email') }}:</b>
          {{ vendor.email }}
        </span>
      </div>
    </b-modal>
    <UpdatedReservationModal :updatedReservation="updatedReservation" v-model="successEdit" @success-updated-close="boolDialog"></UpdatedReservationModal>
    <b-modal :active.sync="showMaxPeopleEditModal" :can-cancel="false" id="more-people-modal-edit">
      <div class="body-modal">
        <i class="icon-mks icon_round_warning"></i>
        <div class="general-subtitle" v-if="getTypeLimitPeopleEdit() === 'hour'">
          <p v-html="$t('morePeopleMessage', {peopleMaxTranslate: maxPeopleEdit})"></p>
        </div>
        <div class="general-subtitle" v-else-if="getTypeLimitPeopleEdit() === 'date'">
          <p v-html="$t('morePeopleMessageDate', {peopleMaxTranslate: maxPeopleEdit})"></p>
        </div>
        <span class="phone-container">
          <a :href="'tel:'+phone.original" class="phone-number" v-for="(phone, index) in phoneNumbers" :key="'phone' + index">
            <i class="icon-mks icon_phone_filled"></i>
            {{phone.formatted}}
          </a>
          <a :href="'https://wa.me/'+phone.original" target="_blank" class="phone-number"
            v-for="(phone, index) in whatsAppNumbers" :key="'wpp' + index">
            <i class="icon-mks icon_whatsapp_outlined"></i>
            {{phone.formatted}}
          </a>
        </span>
        <div class="btns-modal">
          <button
            @click="closeAlertEdit()"
            class="btn-modal"
            type="button"
          >{{ $t('close') }}</button>
        </div>
      </div>
    </b-modal>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import authTypes from '@/store/types/auth';
import People from '@/components/Select/people';
import Shedule from '@/components/Select/schedule';
import dateTime from '@/components/Select/dateTime';
import SelectZoneEdit from '@/components/Select/selectZoneEdit';
import UpdatedReservationModal from '@/components/Account/updatedReservationModal';
import GairaMap from '@/components/Select/gairaMap';
import _ from 'lodash';

export default {
  name: 'editReserva',
  props: ['type', 'edit'],
  data () {
    return {
      sectionEdit: 1,
      peopleEdit: 0,
      modified: false,
      oldPeople: null,
      isImageModalActive3: true,
      isLoading: false,
      showSelectZone: false,
      showSelectZoneMap: false,
      prevDate: false,
      corporative: false,
      editReservationDate: null,
      successEdit: false,
      showPaymentOptions: false,
      showAll: true,
      updatedReservation: null,
      moreD: false,
      showMaxPeopleEditModal: false,
      showDefaultMessagePeopleEdit: true,
      maxPeopleEdit: 9999
    };
  },
  components: {
    People,
    dateTime,
    Shedule,
    SelectZoneEdit,
    GairaMap,
    UpdatedReservationModal
  },
  computed: {
    ...mapGetters({
      user: authTypes.getters.user,
      vendors: whiteLabelTypes.getters.vendor
    }),
    noChangeReservation () {
      if (parseInt(this.selectedHour) === parseInt(this.dataReservationEdit.date) &&
        this.isSameDate(this.selectedDate) &&
        parseInt(this.selectedPeople) === parseInt(this.dataReservationEdit.people)) {
        return true;
      }
      return false;
    },
    aditionalAmount () {
      return this.params.purchaseCost * this.getAditionalPeople();
    },
    phoneNumbers () {
      if (this.vendor.phone) {
        return this.formatNumbers(this.vendor.phone);
      }
      return [];
    },
    whatsAppNumbers () {
      if (this.vendor.whatsapp) {
        return this.formatNumbers(this.vendor.whatsapp);
      }
      return [];
    }
  },
  mounted () {
    this.handleMobile();
    window.addEventListener('resize', this.handleMobile);
    if (this.params.isActiveAvailabilityRange === 1) {
      this.$store.dispatch({ type: 'reservation:availabilityCalendar', data: { vendorId: this.vendor.id } });
    }
    this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
    this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
    this.$store.dispatch({
      type: 'whiteLabel:avalibilityHour',
      data: {
        timestamp: this.selectedDate,
        vendorId: this.$store.getters['whiteLabel:vendorId'],
        people: this.selectedPeople,
        zone: this.selectedZone,
        typeReservation: this.selectedTypeReservation,
        tableId: this.selectedTable
      }
    }).catch(() => {
      this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
    }).finally(() => {
      this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
    });
    if (this.params.activeSelectorBoy) {
      this.peopleEdit = this.dataReservationEdit.adult + this.dataReservationEdit.boy;
    } else {
      this.peopleEdit = this.dataReservationEdit.people;
    }
  },
  methods: {
    changeLoadStatus (status) {
      this.isLoading = status;
    },
    isSameDate (timestamp1, timestamp2 = this.dataReservationEdit.date) {
      if (timestamp1 && timestamp2) {
        const date1 = this.$moment(parseInt(timestamp1));
        const date2 = this.$moment(parseInt(timestamp2));
        return date1.isSame(date2, 'day');
      }
      return false;
    },
    getAditionalPeople () {
      if (this.dataReservationEdit.balancePaid > 0) {
        return this.selectedPeople - this.dataReservationEdit.people;
      } else {
        return this.selectedPeople;
      }
    },
    nextSection () {
      if (this.sectionEdit < 3) {
        this.sectionEdit += 1;
      }
    },
    prevSection () {
      if (this.sectionEdit > 1) {
        this.sectionEdit -= 1;
      }
    },
    handleMobile () {
      if (window.innerWidth < 737) {
        this.showAll = false;
      } else {
        this.showAll = true;
      }
    },
    boolDialog () {
      this.modified = false;
      this.$store.commit(
        reservationTypes.mutations.setEditReservation,
        !this.editReservation
      );
      this.sectionEdit = 1;
      this.showPaymentOptions = false;
      this.successEdit = false;
    },
    saveReservation () {
      let info = this.dataReservationEdit;
      let date = new Date(this.selectedDate);
      date = this.$moment(date).format('YYYY-MM-DD');
      this.modified = true;
      info.people = this.selectedPeople;
      info.adult = this.selectedAdult;
      info.boy = this.selectedBoy;
      info.baby = this.selectedBaby;
      info.typeReservation = this.selectedTypeReservation;
      info.date = this.selectedHour;
      info.fecha = date;
      info.originEdit = this.type;
      info.zoneId = this.selectedZone;
      info.seatingSectionId = this.selectedZone;
      let dayOfWeek = parseInt(
        this.$moment(date)
          .seconds(0)
          .minutes(0)
          .hours(0)
          .milliseconds(0)
          .format('d')
      );
      if (this.vendor.id === 72 &&
        this.selectedTypeReservation === 'Rumba' &&
        (dayOfWeek === 5 || dayOfWeek === 6) &&
        this.params.isMapped === 1 && !this.allEvents[date]) {
        info.tableId = this.selectedTable;
      } else {
        info.tableId = null;
      }
      this.$emit('reservation-select', true);
      this.$store
        .dispatch({ type: 'reservation:updateReservation', data: info })
        .then(({ data }) => {
          if (parseInt(data.code) === 200) {
            this.isLoading = false;
            this.successEdit = true;
            this.updatedReservation = info;
          }
          if (parseInt(data.code) === 705) {
            this.isLoading = false;
            this.modified = false;
            this.$notify({
              group: 'avalaibility',
              title: 'EL horario que seleccionaste, no esta disponible',
              type: 'warning'
            });
          }
          this.$store.dispatch({
            type: 'reservation:reservationsList',
            data: { userId: this.user.id, vendorId: this.$store.getters['whiteLabel:vendorId'] }
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.modified = false;
        });
    },
    preReservaUpdate () {
      let residuo = null;
      let details = {};
      if (this.dataReservationEdit.isEditWhiteLabel === null) {
        residuo = this.selectedPeople - this.dataReservationEdit.people;
      } else {
        residuo =
          this.selectedPeople -
          this.dataReservationEdit.people +
          this.dataReservationEdit.isEditWhiteLabel;
      }
      let date = new Date(this.selectedDate);
      date = this.$moment(date).format('YYYY-MM-DD');
      let dataOld = {
        adult: this.dataReservationEdit.adult,
        boy: this.dataReservationEdit.boy,
        baby: this.dataReservationEdit.baby,
        people: this.dataReservationEdit.people
      };
      let data = {
        reservationId: this.dataReservationEdit.id,
        people: this.selectedPeople,
        adult: this.selectedAdult,
        boy: this.selectedBoy,
        baby: this.selectedBaby,
        typeReservation: this.selectedTypeReservation,
        isFree: this.isFree,
        dateTime: this.selectedHour,
        referrer: this.dataReservationEdit.referrer,
        comments: this.dataReservationEdit.comments,
        isEvent: false,
        userId: this.dataReservationEdit.userId,
        email: this.dataReservationEdit.email,
        phone: this.dataReservationEdit.phone,
        name: this.dataReservationEdit.displayName,
        date: date,
        vendorId: this.$store.getters['whiteLabel:vendorId'],
        isEditWhiteLabel: this.dataReservationEdit.isEditWhiteLabel,
        dataOld,
        paidOld: parseInt(this.dataReservationEdit.balancePaid) || 0,
        totalBalance: parseInt(this.dataReservationEdit.balancePaid) || 0,
        decoration: this.dataReservationEdit.decoration,
        decorationId: this.dataReservationEdit.decorationId,
        tableId: this.selectedTable,
        seatingSectionId: this.selectedZone,
        giftcardId: null,
        giftcardPrice: 0,
        zone: this.selectedZone
      };
      if (
        this.dataReservationEdit.balancePaid === null ||
        this.dataReservationEdit.balancePaid === 0
      ) {
        data.balance = this.selectedPeople * parseInt(this.params.purchaseCost);
        data.totalBalance = parseInt(data.balance);
      } else {
        if (data.typeReservation === 'Pombo') {
          let balanceP = 0;
          let balanceP2 = 0;
          if (data.adult > dataOld.adult) {
            balanceP =
              (parseInt(data.adult) - parseInt(dataOld.adult)) *
              parseInt(this.schedules['pombo'][0].purchaseAdult);
          }
          if (data.boy > dataOld.boy) {
            balanceP2 =
              (parseInt(data.boy) - parseInt(dataOld.boy)) *
              parseInt(this.schedules['pombo'][0].purchaseBoy);
          }
          data.balance = balanceP + balanceP2;
          let payDet = {
            adult: parseInt(this.schedules['pombo'][0].purchaseAdult),
            boy: parseInt(this.schedules['pombo'][0].purchaseBoy)
          };
          data.payDetails = payDet;
          data.residuo = residuo;
        } else if (this.selectedTypeReservation === 'Brunch') {
          details = {
            cover: parseInt(this.showSchedules('brunch', this.selectedDate, 'cover'))
          };
          let value = parseInt(this.selectedPeople) * details.cover;
          if (value <= 0) {
            this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
          }
          data.payDetails = details;
          data.balance = value - data.paidOld;
          data.residuo = residuo;
        } else if (data.typeReservation === 'Rumba') {
          let totalCostRumba = 0;
          if (data.people > dataOld.people) {
            let dayOfWeek = parseInt(
              this.$moment(this.selectedHour)
                .seconds(0)
                .minutes(0)
                .hours(0)
                .milliseconds(0)
                .format('d')
            );
            let cover = 0;
            let cost = 0;
            if (this.vendor.id === 72 && (dayOfWeek === 5 || dayOfWeek === 6) && this.params.isMapped === 1) {
              let costZone = [
                { tableId: 2079, cost: 150000, cover: 50000 },
                { tableId: 3426, cost: 0, cover: 35000 },
                { tableId: 3425, cost: 160000, cover: 40000 },
                { tableId: 3423, cost: 160000, cover: 40000 },
                { tableId: 3424, cost: 160000, cover: 40000 },
                { tableId: 3427, cost: 160000, cover: 40000 },
                { tableId: 3428, cost: 160000, cover: 40000 },
                { tableId: 3429, cost: 160000, cover: 40000 },
                { tableId: 3430, cost: 160000, cover: 40000 },
                { tableId: 3431, cost: 160000, cover: 40000 }
              ];
              let pay = 0;
              if (this.selectedTable != null) {
                let inf = _.find(costZone, { tableId: parseInt(this.selectedTable) });
                pay = parseInt(inf.cost) + parseInt(inf.cover);
                cost = inf.cost;
                cover = inf.cover;
              }
              if (this.dataReservationEdit.balancePaid === 0) {
                totalCostRumba = parseInt(this.selectedPeople) * parseInt(pay);
              } else {
                totalCostRumba = parseInt(this.selectedPeople - this.dataReservationEdit.people) * parseInt(pay);
              }
              details = {
                adult: parseInt(pay),
                cover: parseInt(cover),
                cost: parseInt(cost)
              };
            } else {
              details = {
                adult: parseInt(this.showSchedules('rumba', this.selectedDate)),
                cover: parseInt(this.showSchedules('rumba', this.selectedDate, 'cover')),
                cost
              };
              const totalFull = details.adult + details.cover;
              totalCostRumba = parseInt(this.selectedPeople) * totalFull;
            }
            if (totalCostRumba <= 0) {
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
            }
          }
          data.balance = totalCostRumba;
          data.payDetails = details;
          data.residuo = residuo;
        } else if (data.typeReservation === 'Cena') {
          const adultPay = this.showSchedules('cena', this.selectedDate, 'purchaseAdult');
          const coverPay = this.showSchedules('cena', this.selectedDate, 'cover');
          const total1 = this.selectedPeople * (parseInt(adultPay) + parseInt(coverPay));
          details = {
            adult: parseInt(this.showSchedules('cena', this.selectedDate, 'purchaseAdult')),
            cover: parseInt(this.showSchedules('cena', this.selectedDate, 'cover'))
          };
          if (total1 <= 0) {
            this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
          }
          data.balance = parseInt(total1) - data.paidOld;
          data.payDetails = details;
          data.residuo = parseInt(residuo);
        } else {
          if (!this.isFree) {
            data.balance = residuo * this.params.purchaseCost;
            data.residuo = residuo;
          } else {
            data.balance = 0;
          }
        }
      }
      if (this.vendor.id === 150 || this.vendor.id === 149) {
        let zone = _.find(this.zones, { id: parseInt(this.selectedZone) });
        if (this.dataReservationEdit.balancePaid > 0) {
          data.balance = zone.cost * (this.selectedPeople - this.dataReservationEdit.people);
        } else if (this.dataReservationEdit.balancePaid === 0) {
          data.balance = zone.cost * this.selectedPeople;
          if (data.paidOld === 0) {
            data.totalBalance = data.totalBalance + data.balance;
          }
        }
        if (data.balance > 0) {
          this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
          data.isFree = false;
        } else {
          this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
          data.isFree = true;
        }
      }
      if (data.paidOld > 0) {
        data.totalBalance = parseInt(data.totalBalance) + parseInt(data.balance);
      }
      this.$store
        .dispatch({ type: 'reservation:preReservation', data })
        .then(response => {
          if (parseInt(response.code) === 705 || parseInt(response.code) === 410) {
            this.$store.commit(
              reservationTypes.mutations.setSendReservation,
              false
            );
            this.$buefy.toast.open({
              duration: 5000,
              message: 'No hay disponibilidad',
              position: 'is-bottom',
              type: 'is-danger'
            });
            this.isLoading = false;
          } else {
            data.seatingSectionId = response.vendorAvailability.seatingSectionId;
            // set  futureReservationsId  and  vendorAvailability
            data.futureReservationsId = parseInt(response.futureReservations);
            data.vendorAvailability = response.vendorAvailability;
            window.localStorage.setItem(
              '_reservation',
              btoa(JSON.stringify(data))
            );
            this.$store.commit(reservationTypes.mutations.setReservationsUser);
            window.localStorage.setItem(
              '_reservation_time',
              btoa(response.vendorAvailability.created_at)
            );
            this.isLoading = false;
            window.location.href = '/checkout';
          }
        });
      this.$emit('reservation-select', true);
    },
    validateSubmit () {
      if (this.isFree) {
        this.updateReservation();
        return;
      }
      this.aditionalAmount > 0 ? this.showPaymentOptions = true : this.updateReservation();
    },
    updateReservation () {
      this.isLoading = true;
      if (
        this.dataReservationEdit.typeReservation === 'Rumba' ||
        this.dataReservationEdit.typeReservation === 'Pombo' ||
        this.dataReservationEdit.typeReservation === 'Cena'
      ) {
        if (this.selectedPeople === this.dataReservationEdit.people) {
          this.saveReservation();
        } else {
          this.preReservaUpdate();
        }
      } else if (this.vendor.id === 150 || this.vendor.id === 149) {
        let zone = _.find(this.zones, { id: parseInt(this.selectedZone) });
        let residuo = this.selectedPeople - this.dataReservationEdit.people;
        if (zone.cost > 0 && this.dataReservationEdit.balancePaid === 0) {
          this.preReservaUpdate();
        } else if (zone.cost > 0 && residuo > 0) {
          this.preReservaUpdate();
        } else {
          this.saveReservation();
        }
      } else {
        let residuo = this.selectedPeople - this.dataReservationEdit.people;
        if (
          this.dataReservationEdit.balancePaid <= 0 ||
          this.dataReservationEdit.balancePaid === null
        ) {
          if (this.isFree) {
            this.saveReservation();
          } else {
            this.preReservaUpdate();
          }
        } else {
          if (this.dataReservationEdit.isEditWhiteLabel === null) {
            if (residuo > 0 && !this.isFree) {
              this.preReservaUpdate();
            } else {
              this.dataReservationEdit.isEditWhiteLabel = 1;
              this.saveReservation();
            }
          } else {
            if (residuo > 0 && !this.isFree) {
              this.preReservaUpdate();
            } else {
              this.saveReservation();
            }
          }
        }
      }
    },
    changeDate () {
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
    },
    selectZone (e) {
      this.prevDate = e;
      this.showSelectZone = true;
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
    },
    selectZoneMap (e) {
      this.showSelectZoneMap = true;
      this.prevDate = e;
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
    },
    getBackOpa () {
      let colorObject = this.hexToRgb(this.design.bgColorCheckout);
      if (colorObject === null) {
        colorObject = this.hexToRgb(this.design.color1);
      }
      if (this.design.opacityInEdit) {
        return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.8)`;
      }
      return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b})`;
    },
    formatNumbers (numbersString) {
      const numbers = numbersString.split(',');
      const formattedNumbers = numbers.map(number => {
        const cleanedNumber = number.replace(/\D/g, '');

        const formatWithCountry = /^(\d{2})(\d{3})(\d{3})(\d{4})$/;
        const formatWithoutCountry = /^(\d{3})(\d{3})(\d{4})$/;

        const partsWithCountry = cleanedNumber.match(formatWithCountry);
        const partsWithoutCountry = cleanedNumber.match(formatWithoutCountry);

        if (partsWithCountry) {
          return {
            original: number,
            formatted: `+${partsWithCountry[1]} (${partsWithCountry[2]}) ${partsWithCountry[3]} ${partsWithCountry[4]}`
          };
        } else if (partsWithoutCountry) {
          return {
            original: number,
            formatted: `(${partsWithoutCountry[1]}) ${partsWithoutCountry[2]} ${partsWithoutCountry[3]}`
          };
        } else {
          return {
            original: number,
            formatted: number
          };
        }
      });

      return formattedNumbers;
    },
    maxPeopleDateEdit (fecha) {
      if (fecha !== false) {
        let hour = this.$moment(fecha).format('HH:mm');
        if (hour === '00:00') {
          hour = null;
        } else {
          hour = parseInt(this.$moment(`1970-01-01 ${hour}`).milliseconds(0).seconds(0).format('x'));
        }
        let date = this.$moment(fecha).format('YYYY-MM-DD');
        let dayOfWeek = this.$moment(fecha).day();
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        this.$store.dispatch({
          type: 'reservation:getMaxPeople',
          data: {
            vendorId: this.$store.getters['whiteLabel:vendorId'],
            fecha: date,
            hora: hour,
            dayOfWeek: dayOfWeek
          }
        })
          .then(response => {
            // eslint-disable-next-line
            if (response.hasOwnProperty(0)) {
              this.maxPeopleEdit = response[0].maxPeople;
              if (this.peopleEdit > this.maxPeopleEdit) {
                this.peopleEdit = this.maxPeopleEdit;
                this.dangerPeopleEdit(false);
              }
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              if (hour === null) {
                window.localStorage.setItem('dayPeopleLimit', this.maxPeopleEdit);
                window.localStorage.setItem('dayTypePeopleLimit', 'date');
              } else {
                window.localStorage.setItem('dayTypePeopleLimit', 'hour');
              }
            } else {
              if (this.maxPeopleEdit !== 9999 && hour === null) {
                this.maxPeopleEdit = 9999;
                window.localStorage.removeItem('dayPeopleLimit');
                window.localStorage.removeItem('dayTypePeopleLimit');
              }
              if (hour != null) {
                const peopleLimitDay = window.localStorage.getItem('dayPeopleLimit');
                if (peopleLimitDay) {
                  this.maxPeopleEdit = peopleLimitDay;
                } else {
                  this.maxPeopleEdit = 9999;
                  window.localStorage.removeItem('dayPeopleLimit');
                  window.localStorage.removeItem('dayTypePeopleLimit');
                }
              }
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            }
          })
          .catch(() => {
            this.maxPeopleEdit = 9999;
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          });
      }
    },
    dangerPeopleEdit (defaultMessage = true) {
      this.showMaxPeopleEditModal = true;
      this.showDefaultMessagePeopleEdit = defaultMessage;
    },
    getTypeLimitPeopleEdit () {
      return window.localStorage.getItem('dayTypePeopleLimit');
    },
    closeAlertEdit () {
      this.$store.commit(reservationTypes.mutations.setSelectedPeople, this.maxPeopleEdit);
      this.showMaxPeopleEditModal = false;
    }
  },
  watch: {
    selectedDate () {
      this.maxPeopleDateEdit(this.selectedDate);
    },
    selectedHour () {
      this.maxPeopleDateEdit(this.selectedHour);
    },
    selectedPeople () {
      if (this.params.activeSelectorBoy) {
        this.peopleEdit = this.selectedAdult + this.selectedBoy;
      } else {
        this.peopleEdit = this.selectedPeople;
      }
      if (this.selectedDate != null && this.selectedHour == null) {
        this.maxPeopleDateEdit(this.selectedDate);
      }
      if (this.selectedDate != null && this.selectedHour != null) {
        this.maxPeopleDateEdit(this.selectedHour);
      }
    }
  },
  destroyed () {
    this.modified = false;
    this.$store.commit(reservationTypes.mutations.setEditReservation, false);
    this.sectionEdit = 1;
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Reservation/editReservationModalNew.scss";
#more-people-modal-edit {
  .body-modal{
    padding: 40px 25px;
    border-radius: 20px;
    width: 98%;
    max-width: 415px;
    margin: 0 auto;
    background-color: #fff;
    color: #000;
  }
  .icon_round_warning{
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }
  .general-subtitle{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
  }
  .phone-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 15px 40px;
    flex-wrap: wrap;
    .phone-number{
      color: #000;
      font-size: 15px;
      font-weight: 600;
      text-decoration: underline;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 11px;

      .icon_phone_filled{
        margin: 0;
        width: 12px;
        height: 16px;
      }
      .icon_whatsapp_outlined{
        margin: 0;
        width: 16px;
        height: 16px;
      }
    }
  }
  .btns-modal{
    margin-top: 20px;
    justify-content: center;
    display: flex;
    .btn-modal{
      width: 160px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #000;
      background-color: transparent;
      color: #000;
      transition: .2s ease;
      cursor: pointer;
      &:hover{
        background-color: #000;
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 768px){
    .body-modal{
      padding: 40px 12px;
    }
  }
}
</style>
