<template>
  <b-modal :active.sync="value" :can-cancel="false" id="selectZone">
    <div class="cont_close">
      <i @click="hideModal" class="icons icon_close"></i>
    </div>
    <div class="cont_title">{{ $t('availableArea') }}</div>
    <div class="body_modal columns is-multiline mx-0">
      <div
        :class="{'not_selected' : zone !== false && zone.id !== item.id, 'image' : item.imageZone}"
        :key="'Zone#selected-' + key"
        @click="changeZone(item)"
        class="column is-6 is-6-mobile card_zone"
        v-for="(item, key) in zoneOption"
      >
        <div class="cont_info" v-if="!item.imageZone">
          <div class="content_check">
            <b-checkbox
              :false-value="false"
              :true-value="item.id"
              class="check_zone"
              disabled
              size="is-small"
              style="width: auto !important; margin-top: 5px"
              v-model="zone.id"
            ></b-checkbox>
          </div>
          <div class="title_zone fw600">{{ item.displayName }}</div>
        </div>
        <div class="cont_info" v-else>
          <div :style="'background-image: url(' + item.imageZone +')'" class="cont_image">
            <div class="content_check">
              <b-checkbox
                :false-value="false"
                :true-value="item.id"
                class="check_zone"
                disabled
                size="is-small"
                style="width: auto !important; margin-top: 5px"
                v-model="zone.id"
              ></b-checkbox>
            </div>
          </div>
          <div class="title_zone image fw600">{{ item.displayName }}</div>
        </div>
      </div>
      <div
        :class="{'image' : item.imageZone}"
        :key="'item#Zone-' + key"
        class="column is-6 is-6-mobile card_zone inactive"
        v-for="(item, key) in zoneOptionInactive"
      >
        <div class="cont_info_not" v-if="!item.imageZone">
          <div class="title_zone inactive fw600">{{ item.displayName }}</div>
          <div class="no_availability fw600">{{ $t('unavailable') }}</div>
        </div>
        <div class="cont_info" v-else>
          <div
            :style="'background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(' + item.imageZone +') no-repeat center center/cover;'"
            class="cont_image unavailable"
          >
            <div class="no_availability image fw600">{{ $t('unavailable') }}</div>
          </div>
          <div class="title_zone image fw600">{{ item.displayName }}</div>
        </div>
      </div>
    </div>
    <div class="content_description" v-if="zone != null && zone != false">
      <div class="title_selected">{{ zone.displayName }}</div>
      <div class="description_selected" v-html="zone.copy" v-if="vendor.id != 51"></div>
      <div class="description_selected" v-html="msg" v-if="msg != null"></div>
    </div>
    <div :class="{'mt17' : zone != null && zone != false}" class="content_button">
      <button
        :class="{'disabled' : !zone}"
        :disabled="!zone"
        @click="confirm"
        class="btn_zone"
        type="submit"
      >{{ $t('continue') }}</button>
    </div>
    <b-loading :active.sync="loading" :is-full-page="false" />
  </b-modal>
</template>
<script>
// ================ Libraries =============
import { mapActions } from 'vuex';
// ================ Types  ================
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import eventTypes from '@/store/types/event';

import _ from 'lodash';
import sectionTypes from '@/store/types/section';

export default {
  props: ['prevDate', 'value', 'editZone'],
  data () {
    return {
      zoneOption: [],
      zoneOptionInactive: [],
      zone: false,
      copy: null,
      msg: null,
      dayOfWeekZone: null,
      loading: true,
      datePrev: false,
      r: 0,
      p: 0
    };
  },
  watch: {
    zones () {
      this.zones.forEach(async item => {
        const info = this.blocked.find((i) => i.date === this.$moment(this.prevDate).format('YYYY-MM-DD'));
        if (!item.selectSubZone) {
          if (this.selectedTypeReservation === 'Electrónica' && this.params.vendorId === 149) {
            if (info) {
              if (item.displayName !== 'VIP') {
                this.zoneOption.push({
                  id: item.id,
                  displayName: item.displayName,
                  copy: item.copy,
                  imageZone: item.imageZone
                });
              }
            } else {
              if (item.isActive) {
                if (item.displayName !== 'VIP') {
                  let d = await this.validateDispo(item.id);
                  if (d > 0) {
                    this.zoneOption.push({
                      id: item.id,
                      displayName: item.displayName,
                      copy: item.copy,
                      imageZone: item.imageZone,
                      order: item.order
                    });
                    this.zoneOption.sort((a, b) => a.order - b.order);
                  } else {
                    this.zoneOptionInactive.push({
                      id: item.id,
                      displayName: item.displayName,
                      copy: item.copy,
                      imageZone: item.imageZone,
                      order: item.order
                    });
                    this.zoneOptionInactive.sort((a, b) => a.order - b.order);
                  }
                }
              }
            }
          } else {
            if (info) {
              this.zoneOption.push({
                id: item.id,
                displayName: item.displayName,
                copy: item.copy,
                imageZone: item.imageZone
              });
            } else {
              if (item.isActive) {
                let d = await this.validateDispo(item.id);
                if (d > 0) {
                  this.zoneOption.push({
                    id: item.id,
                    displayName: item.displayName,
                    copy: item.copy,
                    imageZone: item.imageZone,
                    order: item.order
                  });
                  this.zoneOption.sort((a, b) => a.order - b.order);
                } else {
                  this.zoneOptionInactive.push({
                    id: item.id,
                    displayName: item.displayName,
                    copy: item.copy,
                    imageZone: item.imageZone,
                    order: item.order
                  });
                  this.zoneOptionInactive.sort((a, b) => a.order - b.order);
                }
              }
            }
          }
        } else {
          if (item.isActive) {
            await item.subSections.forEach(async i => {
              let param = item.id + '=>' + i.id;
              let d = await this.validateDispo(param);
              if (d > 0) {
                this.zoneOption.push({
                  id: item.id + '=>' + i.id,
                  displayName: i.title,
                  copy: i.copy,
                  imageZone: i.imageSubZone,
                  order: item.order
                });
                this.zoneOption.sort((a, b) => a.order - b.order);
              } else {
                this.zoneOptionInactive.push({
                  id: item.id + '=>' + i.id,
                  displayName: i.title,
                  copy: i.copy,
                  imageZone: i.imageSubZone,
                  order: item.order
                });
                this.zoneOptionInactive.sort((a, b) => a.order - b.order);
              }
            });
          }
        }
      });
      this.loading = false;
    },
    zone () {
      this.dayOfWeekZone = parseInt(
        this.$moment(this.prevDate)
          .seconds(0)
          .minutes(0)
          .hours(0)
          .milliseconds(0)
          .format('d')
      );
      let zoneVitto = ['112=>57', '112=>58'];
      let id = this.zone.id;
      let exists = _.find(zoneVitto, (data) => {
        if (data === id) {
          return data;
        }
      });
      if (exists === undefined) {
        exists = false;
      } else {
        exists = true;
      }
      // eslint-disable-next-line
      if (this.vendor.id === 51 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 4) && id == '112=>56') {
        this.msg = 'Esta es zona pet friendly.<br> En caso de lluvia tu reserva quedaría en lista de espera y cambiaría para otra zona.<br>Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de tu reserva';
      } else if (this.vendor.id === 51 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 4) && exists) {
        this.msg = 'Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de tu reserva';
        // eslint-disable-next-line
      } else if (this.vendor.id === 51 && (this.dayOfWeekZone !== 5 || this.dayOfWeekZone !== 4) && id == '112=>56') {
        this.msg = 'Esta es zona pet friendly.<br> En caso de lluvia tu reserva quedaría en lista de espera y cambiaría para otra zona.';
      } else if (this.vendor.id === 54 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 6 || this.dayOfWeekZone === 4)) {
        this.msg = 'Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de  reservada.';
      } else {
        this.msg = null;
      }
    },
    zoneOption () {
      this.loading = false;
    },
    zoneOptionInactive () {
      this.loading = false;
    }
  },
  mounted () {
    this.datePrev = this.prevDate;
    this.getZones({ vendorId: this.vendor.id });
  },
  methods: {
    ...mapActions({
      getZones: sectionTypes.actions.getZones
    }),
    changeZone (item) {
      if (item === this.zone) {
        this.zone = false;
      } else {
        this.zone = item;
      }
      this.selectZone();
      const tempArray = { ...this.zoneOption };
      this.zoneOption = tempArray;
    },
    hideModal () {
      this.zone = false;
      this.copy = null;
      this.msg = null;
      /* this.$store.commit(reservationTypes.mutations.setSelectedDate, false); */
      this.$emit('closeModalZone', true);
      this.$emit('input', false);
    },
    selectZone () {
      this.copy = this.zone.copy;
      this.$store.commit(
        reservationTypes.mutations.setSelectedZone,
        this.zone.id
      );
      if (this.zone) {
        const sub = Number.isInteger(this.zone.id);
        if (sub) {
          window.localStorage.setItem('zoneId', parseInt(this.zone.id));
        } else {
          if (this.zone.id.indexOf()) {
            const z = this.zone.id.split('=>');
            window.localStorage.setItem('zoneId', parseInt(z[1]));
          }
        }
      } else {
        window.localStorage.removeItem('zoneId');
      }
      this.$store.commit(reservationTypes.mutations.setSelectedDate, false);
      if (this.editZone) {
        this.$emit('changeZone', this.zone.id);
      }
    },
    confirm () {
      this.$emit('input', false);
      this.$emit('hide-select-zone', true);
      // this.$emit('change-date', true)
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      let dateInit = this.dateNowInitial();
      let response = this.setSelectedDates(
        this.datePrev,
        dateInit,
        this.allEvents,
        this.zone
      );
      // se utiliza el commit de la variable global isEventDay
      this.$parent.$emit('isEventDay', response.evento);
      this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, response.evento);

      this.zone = false;
      this.copy = null;
      this.msg = null;
    },
    validateDispo: async function (item) {
      this.p++;
      var obs = this;
      let time = parseInt(new Date(this.datePrev).getTime());
      if (!this.datePrev) {
        time = parseInt(this.$moment().seconds(0).minutes(0).hours(0).milliseconds(0).format('x'));
      }
      await this.$store.dispatch({
        type: 'whiteLabel:avalibilityHourNoCommit',
        data: {
          timestamp: time,
          vendorId: this.$store.getters['whiteLabel:vendorId'],
          people: this.selectedPeople,
          zone: item,
          typeReservation: this.selectedTypeReservation,
          tableId: this.selectedTable
        }
      })
        .then(response => {
          obs.r = 0;
          response.forEach(i => {
            if (i.status === true) {
              obs.r++;
            }
          });
          this.p--;
        });
      return obs.r;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Select/selectZone.scss";
</style>
