<template>
  <div :style="{'--screenHeight':screenSize+'px'}" id="modalLogin">
    <section :class="{onlyInvite:params.isLoginInvited  ||  !source}" class="modalContainer">
      <div class="otherSide">
        <img :src="vendor.lightLogoURL" alt />
      </div>
      <section :class="{onlyInvite:params.isLoginInvited  || !source}" class="containerLogin">
        <div class="modalContainerPassword" v-if="forgotPassword">
          <div class="newPassword">
            <img
              @click="() => {forgotPassword = false;showChangedPassword=false;validationToken=false}"
              alt="closeIcon"
              class="closeIcon"
              src="../../../src/assets/images/icons/close.svg"
            />
            <h2>{{ $t('selectView.login.text1') }}</h2>
            <p v-if="!validationToken && !showChangedPassword">
              {{ $t('selectView.login.text2') }}
              <b>{{ $t('selectView.login.text3') }}</b>
            </p>
            <p
              v-if="validationToken && !showChangedPassword"
            >{{ $t('selectView.login.text4') }}</p>
            <p v-if="!validationToken && !showChangedPassword">
              {{ $t('selectView.login.text5') }}
            </p>
            <form
              @submit.prevent="processReset"
              ref="form"
              v-if="!validationToken && !showChangedPassword"
            >
              <div class="sendEmailContainer">
                <i class="icon-mks icon_mail_outlined"></i>
                <input
                  name="forgotpasswordEmail"
                  placeholder="Email"
                  required
                  type="email"
                  v-model="forgotPasswordEmail"
                  v-validate.persist="'required|email'"
                />
                <i class="icon-mks icon-error-alert" v-show="errors.has('forgotpasswordEmail')"></i>
              </div>

              <button type="submit">{{ $t('selectView.login.text3') }}</button>
            </form>
            <form @submit.prevent="checkToken" v-else-if="validationToken && !showChangedPassword">
              <div class="sendTokenContainer">
                <input
                  @keypress="capturePressCode"
                  name="token"
                  placeholder="________"
                  required
                  type="text"
                  v-model="token"
                  v-validate.persist="'required|min:7|max:8'"
                />
              </div>

              <button type="submit">{{ $t('selectView.login.text6') }}</button>
            </form>
            <form @submit.prevent="changedPasswordUser" v-else>
              <b-field
                :message="errors.first('passwordToken')"
                :type="{ 'is-danger': errors.has('passwordToken') }"
              >
                <input
                  :type="!showPassword ? 'text' : 'password'"
                  name="passwordToken"
                  :placeholder="$t('selectView.login.text8')"
                  required
                  v-model="newPasswordContainer"
                  v-validate="'required|min:6'"
                />
                <i class="icon-mks icon-password"></i>
                <i class="icon-mks" :class="`${showPassword ? 'icon-eye-pass': 'icon-eye-pass-close'}`" @click="()=>showPassword = !showPassword"></i>
              </b-field>
              <b-field
                :message="errors.first('repasswordToken')"
                :type="{ 'is-danger': errors.has('repasswordToken') }"
              >
                <input
                  :type="!showPassword ? 'text' : 'password'"
                  name="repasswordToken"
                  :placeholder="$t('selectView.login.text9')"
                  required
                  v-model="repeatNewPasswordContainer"
                  v-validate.persist="'required|min:6'"
                />
                <i class="icon-mks icon-password"></i>
                <i class="icon-mks" :class="`${showPassword ? 'icon-eye-pass': 'icon-eye-pass-close'}`" @click="()=>showPassword = !showPassword"></i>
              </b-field>

              <button type="submit">{{ $t('selectView.login.text7') }}</button>
            </form>
          </div>
        </div>
        <div
          @click="
            () => {
              guestLogin = true;
              registerModal = false;
            }
          "
          class="getBack"
          v-if="!guestLogin && !params.isLoginInvited && source"
        >
          <span class="backIcon"></span>
          <p>{{ $t('selectView.login.text10') }}</p>
        </div>
        <img
          @click="closeRecovery"
          alt="closeIcon"
          class="closeIcon"
          src="../../../src/assets/images/icons/close.svg"
        />
        <h1
          :class="{ loginTitle: guestLogin, registerLogin: registerModal,onlyInvite:params.isLoginInvited || source, loginTitleDefault: !guestLogin && !registerModal } "
        >{{ title }}</h1>
        <form @submit.prevent="processSubmit">
          <b-field
            :message="errors.first('displayName')"
            :type="{ 'is-danger': errors.has('displayName') }"
            v-if="guestLogin || registerModal"
            :class="{ 'fieldNameWithDocument' : guestLogin && params.enableIdentityDocument}"
          >
            <div class="inputContainer">
              <input
                autocomplete="name"
                class="username"
                :class="{'with-error-padding': errors.has('displayName')}"
                name="displayName"
                :placeholder="$t('selectView.login.text11')"
                required
                type="text"
                v-model="userLogged.name"
                v-validate.persist="{required:true, min:3, regex:/^[^\d@.,!'#$%&/\(\)=?\\|]*$/}"
              />
              <i class="icon-mks icon-error-alert" v-show="errors.has('displayName')"></i>
            </div>
          </b-field>
          <b-field
            :message="errors.first('identityDocument')"
            :type="{ 'is-danger': errors.has('identityDocument') }"
            v-if="(guestLogin || registerModal) && params.enableIdentityDocument"
            :class="{ 'fieldTypeNumberDocument' : guestLogin && params.enableIdentityDocument}"
          >
            <div class="inputContainer documentTypeNumber">
              <selectTypeDocuments :valueSelected="userLogged.documentType" @selectTypeDocument="selectTypeDocument" />
              <input
                autocomplete="name"
                class="documentTypeAndNumber"
                :class="{'with-error-padding': errors.has('identityDocument')}"
                name="identityDocument"
                :placeholder="$t('selectView.login.text27')"
                required
                type="text"
                v-model="userLogged.identityDocument"
                v-validate.persist="{required:true}"
              />
              <i class="icon-mks icon-error-alert" v-show="errors.has('identityDocument')"></i>
            </div>
          </b-field>
          <div
            :class="{ lastPosition: registerModal }"
            class="selectorContainer"
            v-if="guestLogin || registerModal"
          >
            <inputSelector @value="inputNumberChange" />
          </div>

          <b-field
            :class="{emailGuest:guestLogin}"
            :message="errors.first('email')"
            :type="{ 'is-danger': errors.has('email') }"
          >
            <div class="inputContainer">
              <input
                @keypress="capturePress"
                autocomplete="username"
                name="email"
                placeholder="Email"
                :class="{'with-error-padding': errors.has('email')}"
                required
                type="email"
                v-model="userLogged.email"
                v-validate.persist="'required|email'"
              />
              <i class="icon-mks icon-error-alert" v-show="errors.has('email')"></i>
              <i class="icon-mks icon_mail_outlined"></i>
            </div>
          </b-field>

          <b-field
            :message="errors.first('password')"
            :type="{ 'is-danger': errors.has('password') }"
            v-if="!guestLogin"
          >
            <input
              :type="!showPassword ? 'text' : 'password'"
              @keypress="capturePress"
              autocomplete="current-password"
              name="password"
              :placeholder="$t('selectView.login.text12')"
              required
              v-model="userLogged.password"
              v-validate.persist="'required|min:6'"
            />
            <i class="icon-mks icon-password"></i>
            <i class="icon-mks" :class="`${showPassword ? 'icon-eye-pass': 'icon-eye-pass-close'}`" @click="()=>showPassword = !showPassword"></i>
          </b-field>
          <b-field
            :message="userLogged.password !==userLogged.repassword  ? 'La clave no coincide' : ''"
            :type="{ 'is-danger':userLogged.password !==userLogged.repassword }"
            v-if="registerModal"
          >
            <input
              :type="!showPassword ? 'text' : 'password'"
              @keypress="capturePress"
              autocomplete="current-password"
              name="repassword"
              :placeholder="$t('selectView.login.text28')"
              required
              v-model="userLogged.repassword"
              v-validate.persist="'required'"
            />
            <i class="icon-mks icon-password"></i>
            <i class="icon-mks" :class="`${showPassword ? 'icon-eye-pass': 'icon-eye-pass-close'}`" @click="()=>showPassword = !showPassword"></i>
          </b-field>
          <div class="forgotContainer" v-if="!guestLogin && !registerModal">
            <p @click="() => (forgotPassword = true)" class="forgotPassword">{{ $t('selectView.login.text13') }}</p>
          </div>
          <button
            :class="{ asGuest: guestLogin, registerModal: registerModal, disabledSubmit:disabledSubmit}"
            class="enterLoginButton"
          >{{ registerModal ? "Registrar " : "Continuar" }}</button>
          <p class="o" v-if="registerModal">o</p>
          <button
            @click="
              () => {
                registerModal = false;
                guestLogin = false;
              }
            "
            class="RegisterButton form"
            v-if="registerModal"
          >{{ $t('selectView.login.text14') }}</button>
        </form>
        <div class="guessTextContainer" v-if="guestLogin && !params.isLoginInvited && source">
          <p>
            {{ $t('selectView.login.text15') }}
            <b>{{ $t('selectView.login.text14') }}</b>
          </p>
          <p>
            {{ $t('selectView.login.text16') }}
            {{ $t('selectView.login.text17') }}
            <b></b>
          </p>
        </div>
        <div class="bothOtionsContainer" v-if="guestLogin && !params.isLoginInvited && source">
          <button
            :class="{guestLogin:guestLogin}"
            @click="
              () => {
                registerModal = false;
                guestLogin = false;
              }
            "
            class="RegisterButton"
          >{{ $t('selectView.login.text14') }}</button>
          <button
            :class="{guestLogin:guestLogin}"
            @click="
              () => {
                registerModal = true;
                guestLogin = false;
              }
            "
            class="RegisterButton"
          >{{ $t('selectView.login.text18') }}</button>
        </div>
        <p class="remeberGuessText" v-if="guestLogin && !params.isLoginInvited && source">
          {{ $t('selectView.login.text19') }}
        </p>
        <section
          :class="{registerLogin: registerModal}"
          class="otherForms"
          v-if="!params.isLoginInvited && source"
        >
          <p>{{ $t('selectView.login.text20') }}</p>
          <img
            @click="popup('facebook')"
            alt="facebookLogin"
            src="../../../src/assets/socialMediaIcons/facebook.svg"
          />
          <img @click="popup('google')" alt src="../../../src/assets/socialMediaIcons/google.svg" />
        </section>
        <p class="register" v-if="!guestLogin && !registerModal">
          {{ $t('selectView.login.text15') }}
          <b>{{ $t('selectView.login.text21') }}</b>
        </p>

        <button
          @click="
            () => {
              registerModal = true;
              guestLogin = false;
            }
          "
          class="RegisterButton onlyLogin"
          v-if="!guestLogin && !registerModal"
        >{{ $t('selectView.login.text18') }}</button>
        <div :class="{registerLogin: registerModal}" class="acceptTerms">
          <b-checkbox type="is-black" v-model="termsAccepted"></b-checkbox>
          <span>
            {{ $t('selectView.login.text22') }}
            <a
              @click="showTermsCondiciones = true"
              style="color: black;"
            >
              <b>{{$t('termsAndCondiW')}}</b>
            </a>
            {{ $t('selectView.login.text23') }}
            <a @click="showTerms = true" style="color: black;">
              <b>{{$t('privacyPolicyW')}}*</b>
            </a>
            {{ $t('selectView.login.text24') }}
          </span>
        </div>
        <div id="modal-terms-login">
          <b-modal :active.sync="showTerms">
            <div class="terms-body">
              <i
                @click="showTerms = false"
                class="fa fa-times"
                style="float: right; cursor: pointer"
              ></i>
              <h3 class="title-modal">{{ $t('selectView.login.text25') }}</h3>
              <terms :fecha="'22 de Noviembre del 2019'"></terms>
            </div>
          </b-modal>
        </div>
        <div id="modal-terms-login">
          <b-modal :active.sync="showTermsCondiciones">
            <div class="terms-body">
              <i
                @click="showTermsCondiciones = false"
                class="fa fa-times"
                style="float: right; cursor: pointer"
              ></i>
              <h3 class="title-modal">{{ $t('selectView.login.text26') }}</h3>
              <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
            </div>
          </b-modal>
        </div>
      </section>
    </section>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import inputSelector from '@/components/Select/inputs/inputSelectLogin.vue';
import selectTypeDocuments from '@/components/Select/inputs/selectTypeDocuments.vue';
import authTypes from '@/store/types/auth';
import { mapActions } from 'vuex';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';

export default {
  props: ['source'],
  name: 'modalLogin',
  components: { inputSelector, terms, terms2, selectTypeDocuments },
  data () {
    return {
      userLogged: {
        email: '',
        password: '',
        repassword: '',
        name: '',
        phone: '',
        indicativo: '',
        bandera: '',
        deviceId: this.deviceId,
        version: this.versionApp,
        system: 'select',
        country: 'Colombia',
        documentType: 'CC',
        identityDocument: null
      },
      showTerms: false,
      showTermsCondiciones: false,
      showPassword: true,
      guestLogin: true,
      registerModal: false,
      forgotPassword: false,
      forgotPasswordEmail: '',
      validationToken: false,
      token: '',
      showChangedPassword: false,
      tokenUser: null,
      newPasswordContainer: '',
      repeatNewPasswordContainer: '',
      screenSize: '',
      termsAccepted: true,
      registerDanger: false
    };
  },
  mounted () {
    this.screenSize = window.innerHeight;
  },
  methods: {
    selectTypeDocument (value) {
      this.userLogged.documentType = value;
    },
    processLogin (user) {
      this.login({ email: user.email, password: user.password, deviceId: this.deviceId, version: this.versionApp, system: 'select' })
        .then((response) => {
          this.responseLogin(response);
        })
        .catch((error) => {
          this.responseLogin(error);
        });
    },
    responseLogin (response) {
      if (parseInt(response.status) !== 200) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
            'Datos de inicio de sesión no validos' +
            '</span></strong>',
          type: 'is-warning',
          position: 'is-top-right',
          actionText: 'X',
          queue: false
        });
      } else {
        this.$emit('validate-user', true);
        this.validateSendReservation();
        this.$store.commit(
          whiteLabelTypes.mutations.setShowModalLogin,
          false
        );
      }
    },
    popup (provider) {
      let x = window.location.href;
      x.replace('#block-hour', '');
      let referrer = this.encrypt(x);
      // URL a la que se redirigirá para iniciar sesión social
      const url = process.env.VUE_APP_URL_AUTH + '/login/social/' + provider + '/' + referrer;
      // Configuración de la ventana emergente
      const width = 600;
      const height = 400;
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;
      const options = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
      const popup = window.open(url, 'Login Social', options);
      window.addEventListener('message', event => {
        // Manejar los mensajes recibidos desde la ventana emergente
        const data = event.data;
        console.log(data);
        if (data.token) {
          window.localStorage.setItem('_token', data.token);
          window.localStorage.setItem(
            '_user',
            btoa(JSON.stringify(data.user))
          );
          window.localStorage.setItem('_userId', data.userId);
        }
        this.$store.commit(authTypes.mutations.setUser);
        this.$store.commit(authTypes.mutations.setUserId);
        this.validateSendReservation();
        popup.close();
      });
    },
    closeRecovery () {
      this.$emit('close');
    },
    capturePress (event) {
      if (
        event.charCode === 32 ||
        event.code === 'Space' ||
        event.keyCode === 32 ||
        event.key === ' ' ||
        event.which === 32
      ) {
        event.preventDefault();
      }
    },
    capturePressCode (event) {
      if (this.token.length > 7) {
        event.preventDefault();
      }
    },
    inputNumberChange (value) {
      this.userLogged.bandera = value.countrieInfo.bandera;
      this.userLogged.indicativo = value.countrieInfo.indicativo;
      this.userLogged.country = value.countrieInfo.pais;
      this.userLogged.phone = value.inputValue;
    },
    processLoginInvite (user) {
      if (
        !this.errors.has('email') &&
        !this.errors.has('displayName') &&
        this.userLogged.phone &&
        !this.$validator.errors.items.find(element => element.field === 'number')
      ) {
        this.loginInvite({ user: this.encrypt(13744), deviceId: this.deviceId, version: this.versionApp, system: 'select', data: user, userId: 13744 })
          .then(() => {
            this.$store.commit(
              whiteLabelTypes.mutations.setShowModalLogin,
              false
            );
            this.validateSendReservation();
          })
          .catch((error) => {
            this.$buefy.snackbar.open({
              duration: 10000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
                error.response.data.message +
                '</span></strong>',
              type: 'is-warning',
              position: 'is-top-right',
              actionText: 'X',
              queue: false
            });
          });
      }
    },
    processSubmit () {
      if (!this.disabledSubmit) {
        this.userLogged.deviceId = this.deviceId;
        this.userLogged.version = this.versionApp;
        if (this.userLogged.email === null || this.userLogged.phone === null) {
          this.$buefy.snackbar.open({
            duration: 10000,
            message: '<i class="fa fa-exclamation-circle alerticon"></i><strong>El campo email y el campo teléfono son obligatorios.</strong>',
            type: 'is-warning',
            position: 'is-top-right',
            actionText: 'X',
            queue: false
          });
          return;
        }
        if (this.guestLogin) {
          this.processLoginInvite(this.userLogged);
        } else if (this.registerModal) {
          if (this.params.enableIdentityDocument === 0) {
            this.userLogged.documentType = null;
          }
          this.registerUser(this.userLogged);
        } else {
          this.processLogin(this.userLogged);
        }
      }
      if (this.disabledSubmit && this.registerModal) {
        this.registerDanger = true;
      } else {
        this.registerDanger = false;
      }
    },
    registerUser (user) {
      if (
        !this.errors.has('email') &&
        !this.errors.has('displayName') &&
        !this.errors.has('password') &&
        !this.errors.has('repassword') &&
        this.userLogged.phone &&
        !this.$validator.errors.items.find(element => element.field === 'number') &&
        this.userLogged.repassword === this.userLogged.password
      ) {
        this.register(user)
          .then(() => {
            this.$store.commit(
              whiteLabelTypes.mutations.setShowModalLogin,
              false
            );
            this.validateSendReservation();
          })
          .catch((err) => {
            this.$buefy.snackbar.open({
              duration: 10000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
                err.response.data.message +
                '</span></strong>',
              type: 'is-warning',
              position: 'is-top-right',
              actionText: 'X',
              queue: false
            });
          });
      } else if (this.userLogged.repassword !== this.userLogged.password) {
        alert('la clave no coincide en los dos campos');
      }
    },
    processReset () {
      const mail = this.forgotPasswordEmail;
      this.reset({
        email: mail,
        deviceId: this.deviceId,
        version: this.versionApp,
        system: 'select'
      })
        .then((response) => {
          if (response.status === 200) {
            this.validationToken = true;
          }
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 10000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
              'Error inesperado' +
              '</span></strong>',
            type: 'is-warning',
            position: 'is-top-right',
            actionText: 'X',
            queue: false
          });
        });
    },
    checkToken () {
      this.validateToken(this.token)
        .then(response => {
          this.tokenUser = response.data.user;
          this.validationToken = false;
          this.showChangedPassword = true;
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 10000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
              'token Invalido' +
              '</span></strong>',
            type: 'is-warning',
            position: 'is-top-right',
            actionText: 'X',
            queue: false
          });
        });
    },
    changedPasswordUser () {
      if (this.newPasswordContainer === this.repeatNewPasswordContainer) {
        if (!this.errors.has('repasswordToken')) {
          this.changed({
            userId: this.tokenUser,
            password: this.newPasswordContainer,
            password_confirmation: this.repeatNewPasswordContainer,
            deviceId: this.deviceId,
            version: this.versionApp,
            system: 'select'
          }).then(response => {
            if (response.user.email !== undefined) {
              this.processLogin({ email: response.user.email, password: this.newPasswordContainer });
            } else {
              this.$buefy.snackbar.open({
                duration: 10000,
                message:
                  "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
                  response +
                  '</span></strong>',
                type: 'is-warning',
                position: 'is-top-right',
                actionText: 'X',
                queue: false
              });
            }
          }).catch(fallo => {
            this.$buefy.snackbar.open({
              duration: 10000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
                fallo +
                '</span></strong>',
              type: 'is-warning',
              position: 'is-top-right',
              actionText: 'X',
              queue: false
            });
          });
        }
      } else {
        this.$buefy.snackbar.open({
          duration: 10000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" +
            'Las contrasenas no coinciden' +
            '</span></strong>',
          type: 'is-warning',
          position: 'is-top-right',
          actionText: 'X',
          queue: false
        });
      }
    },

    ...mapActions({
      login: authTypes.actions.login,
      register: authTypes.actions.register,
      loginInvite: authTypes.actions.loginInvite,
      reset: authTypes.actions.reset,
      validateToken: authTypes.actions.validateToken,
      changed: authTypes.actions.changedPassword
    })
  },
  computed: {
    title () {
      if (this.guestLogin) {
        return 'Ingresa tus datos';
      } else if (this.registerModal) {
        return 'Regístrate';
      } else {
        return 'Iniciar sesión';
      }
    },
    disabledSubmit () {
      if (!this.guestLogin && !this.registerModal) {
        if (!this.termsAccepted || this.errors.has('email') || this.errors.has('password') || this.userLogged.password.length <= 5 || this.userLogged.email.length <= 4) {
          return true;
        } else {
          return false;
        }
      } else if (this.guestLogin) {
        if (!this.termsAccepted || this.errors.has('email') || this.errors.has('displayName') || !this.userLogged.phone || this.$validator.errors.items.find(element => element.field === 'number') || this.userLogged.email.length <= 4 || this.userLogged.name.length <= 2 || (this.params.enableIdentityDocument && (!this.userLogged.identityDocument || this.userLogged.identityDocument === ''))) {
          return true;
        } else {
          return false;
        }
      } else if (this.registerModal) {
        if (!this.termsAccepted || this.errors.has('email') || this.errors.has('password') || this.errors.has('displayName') || this.userLogged.password !== this.userLogged.repassword || !this.userLogged.phone || this.$validator.errors.items.find(element => element.field === 'number') || this.userLogged.password.length <= 5 || this.userLogged.name.length <= 2 || (this.params.enableIdentityDocument && (!this.userLogged.identityDocument || this.userLogged.identityDocument === ''))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    guestLogin () {
      this.userLogged = {
        email: '',
        password: '',
        repassword: '',
        name: '',
        phone: '',
        indicativo: '',
        bandera: '',
        version: this.versionApp,
        deviceId: this.deviceId,
        system: 'select',
        documentType: 'CC',
        identityDocument: null
      };
    },
    registerModal () {
      this.userLogged = {
        ...this.userLogged,
        repassword: '',
        name: '',
        phone: '',
        indicativo: '',
        bandera: '',
        documentType: 'CC',
        identityDocument: null
      };
    },
    forgotPassword () {
      this.forgotPasswordEmail = '';
      this.token = '';
      this.tokenUser = null;
      this.newPasswordContainer = '';
      this.repeatNewPasswordContainer = '';
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/Select/_loginModal.scss";
</style>
