<template>
  <div id="modalDecoration">
    <div class="header_modal">
      <div class="div_close">
        <i class="icons icon_close_x" @click="close()"></i>
      </div>
      <span class="title_modal fw700">
        {{(params.activateMultipleDecorations === 0) ? $t('chooseDecoration') : $t('chooseDecorationMultiple') }}
      </span>
    </div>
    <div class="content_modal columns is-multiline mx-0">
      <div class="column is-6" v-for="(item, i) in allDecorations" :key="'okL' + i" :class="{'card_left' : !(i % 2), 'card_right' : i % 2}">
        <div class="item_decoration">
          <div class="left_side">
            <div class="content_img" :style="'background-image: url('+ item.imageURL +')'" @click="openImagen(item.imageURL)">
              <i class="icons icon_zoom"></i>
            </div>
          </div>
          <div class="right_side" @click="selectDeco(item)">
            <div class="content_check">
              <b-checkbox
                class="check_deco"
                :false-value="false"
                :true-value="item.id"
                @input="selectDecoration(item)"
                size="is-small"
                style="width: auto !important; margin-top: 5px"
                v-if="params.activateMultipleDecorations === 0"
                v-model="select"
                disabled
              ></b-checkbox>
              <b-checkbox
                class="check_deco"
                :false-value="false"
                :true-value="true"
                size="is-small"
                style="width: auto !important; margin-top: 5px"
                v-else
                v-model="item.selected"
                @input="selectItem(item)"
                disabled
              ></b-checkbox>
            </div>
            <div class="cont_info">
              <div class="title_decoration fw600">
                {{ item.name }}
              </div>
              <div class="description_decoration fw400">
                {{ item.description }}
              </div>
            </div>
            <div class="price_decoration fw600" v-if="item.price > 0">
              {{item.price | currency('$', 0, { thousandsSeparator: '.' })}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_modal">
      <div
        :class="{'disable': disable}"
        @click="decorationSubmit()"
        class="btn_done"
      >
        {{$t('continue')}}
      </div>
    </div>
  </div>
</template>
<script>
import modalDecorationImg from '@/components/Checkout/modalDecorationImg';
import reservationTypes from '@/store/types/reservation';
import { mapGetters } from 'vuex';
export default {
  props: ['decorationId'],
  data () {
    return {
      decorations: [],
      allDecorations: [],
      select: null,
      data: null,
      imageOpen: false,
      dataError: null,
      disable: true
    };
  },
  mounted () {
    this.getDecorations();
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser,
      decorationCost: reservationTypes.getters.decorationCosto
    })
  },
  methods: {
    selectItem (item) {
      item.selected = !item.selected;
      const tempArray = { ...this.allDecorations };
      this.allDecorations = tempArray;
      this.getDisabled();
    },
    getSelectedDecorations () {
      const filtered = [];
      this.decorations.forEach(data => {
        const selected = data.filter(child => child.selected);
        selected.forEach(sel => {
          filtered.push(sel);
        });
      });
      return filtered;
    },
    getDisabled () {
      if (this.params.activateMultipleDecorations === 1 && this.getSelectedDecorations().length > 0) {
        this.disable = false;
        return;
      }
      if (this.data) {
        this.disable = false;
        return;
      }
      this.disable = true;
    },
    getDecorations () {
      this.$http.get('/reservation/getDecorations/' + this.vendor.id).then(({ data }) => {
        this.allDecorations = data.data;
        let index = 0;
        let chunkSize = 3;
        let arrayLength = data.data.length;
        let tempArray = [];
        if (this.params.activateMultipleDecorations === 1) {
          data.data = data.data.map(data => {
            data.selected = false;
            return data;
          });
        }
        for (index = 0; index < arrayLength; index += chunkSize) {
          let myChunk = data.data.slice(index, index + chunkSize);
          tempArray.push(myChunk);
        }
        this.decorations = tempArray;
      }).catch(() => {
        this.loading = false;
        this.$toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    openImagen (img) {
      if (img && this.imageOpen === false) {
        this.imageOpen = true;
        var instance = this;
        this.$buefy.modal.open({
          parent: instance,
          props: {
            img: img
          },
          component: modalDecorationImg,
          canCancel: false,
          events: {
            close () {
              instance.imageOpen = false;
            }
          },
          customClass: 'main_img_decoration'
        });
      }
    },
    selectDeco (item) {
      if (this.params.activateMultipleDecorations === 0) {
        this.selectDecoration(item);
      } else {
        this.selectItem(item);
      }
    },
    selectDecoration (item) {
      if (this.data === item) {
        this.select = null;
        this.data = null;
      } else {
        this.select = item.id;
        this.data = item;
      }
      this.getDisabled();
    },
    decorationSubmit () {
      const selectedDecorations = this.getSelectedDecorations();
      if (this.params.activateMultipleDecorations === 1 && selectedDecorations.length > 0) {
        for (let index = 0; index < selectedDecorations.length; index++) {
          const data = selectedDecorations[index];
          if ((data.anticipation != null && data.anticipation > 0)) {
            const today = this.$moment().unix();
            const timeSold = (((this.reservation.dateTime / 1000) - today) / 60) / 60;
            if (timeSold < data.anticipation) {
              this.dataError = data;
              break;
            } else {
              this.dataError = null;
            }
          } else {
            this.dataError = null;
          }
        }
        if (this.dataError != null) {
          this.loading = false;
          this.$buefy.toast.open({
            message: `La decoración tiene un tiempo de anticipación de ${this.dataError.anticipation} por lo tanto su decoración no puede estar lista para la la fecha seleccionada. Esta Debe ser seleccionada un minimo de ${this.dataError.anticipation} horas antes`,
            type: 'is-danger'
          });
          return;
        }
        const { price } = selectedDecorations.reduce((a, b) => ({ price: a.price + b.price }));
        this.$store.commit(reservationTypes.mutations.setDecorationCosto, price);
        this.$emit('setMultiple', selectedDecorations);
        this.$emit('deco', price);
        window.localStorage.setItem('_decoration_cost', this.decorationCost);
        this.$parent.close();
        return;
      }
      if (this.data) {
        if ((this.data.anticipation != null && this.data.anticipation > 0)) {
          const today = this.$moment().unix();
          const timeSold = (((this.reservation.dateTime / 1000) - today) / 60) / 60;
          if (timeSold < this.data.anticipation) {
            this.loading = false;
            this.$buefy.toast.open({
              message: `La decoración tiene un tiempo de anticipación de ${this.data.anticipation} por lo tanto su decoración no puede estar lista para la la fecha seleccionada. Esta Debe ser seleccionada un minimo de ${this.data.anticipation} horas antes`,
              type: 'is-danger'
            });
            return;
          }
        }
        this.$store.commit(reservationTypes.mutations.setDecorationCosto, this.data.price);
        this.$emit('setId', this.data);
        this.$emit('deco', this.data.price);
        window.localStorage.setItem('_decoration_cost', this.decorationCost);
        this.$parent.close();
      }
    },
    close () {
      this.$emit('close', true);
      this.$parent.close();
    }
  },
  watch: {
    allDecorations () {
      if (this.decorationId) {
        const decorationSelected = this.allDecorations.find((decoration) => decoration.id === this.decorationId);
        if (decorationSelected) {
          this.selectDecoration(decorationSelected);
        }
      }
    }
  }
};
</script>
<style lang="scss">
  @import '@/assets/styles/Decoration/modalDecoration.scss';
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700');
</style>
