export default {
  return: `Return`,
  selectData: `Select data`,
  select: `Select`,
  book: `Book`,
  finishBookin: `Finish booking`,
  numberDiners: `Number of diners`,
  needEvent: `If you need an event`,
  contactEvent: `Contact us`,
  biggerReservation: `Bigger reservation`,
  phone: `Phone`,
  email: `Email`,
  date: `Date`,
  chooseAvailableDate: `Choose a date for your reservation`,
  january: `january`,
  february: `february`,
  march: `march`,
  april: `april`,
  may: `may`,
  june: `june`,
  july: `july`,
  august: `august`,
  september: `september`,
  october: `october`,
  november: `november`,
  december: `december`,
  area: `Area`,
  availableArea: `Select an available area`,
  noAvailableArea: `No areas available`,
  close: `Close`,
  unavailable: `Unavailable`,
  hour: `Hour`,
  monday: `Monday`,
  tuesday: `Tuesday`,
  wednesday: `Wednesday`,
  thursday: `Thursday`,
  friday: `Friday`,
  saturday: `Saturday`,
  sunday: `Sunday`,
  availableHour: `Available hours`,
  noAvailableHour: `Unavailable hours`,
  loading: `Loading`,
  chooseAvailableHour: `Choose an available hour`,
  completeFilds: `Complete all fields`,
  continue: `Continue`,
  continueUpper: `CONTINUE`,
  name: `Name`,
  country: `Country`,
  mobile: `Mobile`,
  idNumber: `ID number`,
  diners: `Diners`,
  littleCompleteReservation: `Almost there to complete you reservation`,
  verifyData: `Verify the data and click on “confirm reservation"`,
  clientInformation: `Client information`,
  bookingInformation: `Booking information`,
  notification: `Notification`,
  bookNow: `Book now`,
  againReservation: `The established time to confirm your reservation has been fulfilled, you must start the process again to make your reservation`,
  agree: `Agree`,
  timeOverReservation: `The time to pay your reservation has ended, it will be canceled!`,
  generateNewLink: `If you want to have a new payment link, contact us`,
  subscribe: `Subscribe`,
  comments: `Comments`,
  comments_alergy: `Do you have any alimentary restriction or alergy?`,
  typeCelebration: `If you have any type of celebration, tell us`,
  reservationConfirm: `CONFIRM RESERVATION`,
  singOff: `Log out`,
  myAccount: `My account`,
  congratulations: `Congratulations`,
  reservationComplete: `Your reservation has been made and confirmed successfully`,
  emailInformation: `In a few minutes you’ll receive a with your booking information`,
  waitYou: `We will wait foy you`,
  seeBooking: `See booking`,
  myBooking: `My bookings`,
  updateField: `Update information`,
  myProfile: `My profile`,
  pleaseProfileInformation: `Please fill in your profile information`,
  fullName: `Full name`,
  saveProfile: `Save profile`,
  adults: `Adults`,
  kids: `Kids`,
  kids2Years: `Kids under 2 years`,
  kids3Years: `Kids under 3 years`,
  contact11And9: `Contact us between 11am and 9pm`,
  peopleRecommend10: `For groups of 10 people onwards we recommend designing a menu`,
  contributionArtist: `The contribution to the artist (cover) is the amount that is canceled at the time of reservation and is not consumable.`,
  contributionArtist1: `The`,
  contributionArtist2: `contribution to the artist (cover)`,
  contributionArtist3: `is the amount that is canceled at the time of reservation and`,
  contributionArtist4: `is not consumable`,
  advanceReservation1: `To guarantee your reservation, an advance of`,
  advanceReservation2: `per Adult or Children over 10 years old and`,
  advanceReservation3: `for Children between 4 and 9 years old is made. Children under 3 years old do not pay`,
  breakfastShow: `Includes Breakfast and Show`,
  eventChargePerson1: `This event has a charge`,
  eventChargePerson2: `of person`,
  contactNeedNumberPerson: `Contact us if you need an event or if you require a reservation that exceeds the number of people`,
  eventDay: `Event day`,
  selectAvailableHour: `Select an available hour`,
  selectWhereBook: `Select where you want to book`,
  noSchedulesAvailable: `There are no schedules available`,
  connectionError: `Request Time out pls watch your internet connection`,
  loadingHours: `Searching for hours`,
  normalBooking: `Normal booking`,
  noFindAvailable: `Can't find availability?`,
  seeAvailable: `See availability`,
  inHere: `in here`,
  lookingAvailable: `Looking for availability in`,
  findAvailableOther: `We find availability in other restaurants`,
  goToRestaurant: `Go to the restaurant`,
  foundReservation: `We have found a reservation for this date`,
  continueReservation: `Are you sure to continue with the current reservation?`,
  rumbaInfo: `Rumba information`,
  rumbaNoTable: `Reservations for the rumba do not include a table, they will only give you access to the show restaurant and minors will not be accepted`,
  completeData: `COMPLETE THE DATA`,
  next: `NEXT`,
  timeNoAvailable: `Sorry, but this time is not available`,
  locationCocktail: `LOCATION IN COCKTAIL TABLE`,
  locationTable: ` LOCATION IN TABLE`,
  locationMuch: `LOCATION WITHOUT TABLE / BAR / LOUNGES`,
  sendGiftSpecial: `Send a gift card to someone special`,
  sendGiftSpecialBiferia: `Envia un bono de La Biferia`,
  givePresent: `Give a present`,
  login: `Log in`,
  passRecovery: `Password recovery`,
  emailAndSubmit: `Enter the email address and click Submit`,
  passReset: `We will email you instructions on how to reset your password.`,
  send: `Send`,
  sending: `Sending`,
  tokenResetPass: `A token to reset the password has been sent to your email, please copy and paste it below`,
  check: `Check`,
  checking: `Checking`,
  logInHere: `If you already have an account, log in here`,
  incorrectCredentials: `Incorrect credentials`,
  forgotPass: `Forgot your password?`,
  getIn: `Get in`,
  registerHere: `If you do not have an account, register here`,
  selectOption: `Select an option`,
  strongOption: `STRONG DISH OPTIONS`,
  hamburge3Cordi: `3 Cordilleras hamburger with potato chips`,
  sanduche3Cordi: `Sanduche Pulled Pork with potato chips`,
  noAvailableTime: `There are no available times`,
  noAvailableZone: `There are no zones with availability`,
  payReservation: `Pay Reservation`,
  typeReservation: `Type of Reservation`,
  cancelReservation: `Cancel reservation`,
  areSure: `Are you sure?`,
  emailDetails: `You will receive an email with all the details, remember that once canceled you will not be able to return to your reservation`,
  termsAndCondi: `Accept cancellation terms and conditions here.`,
  confirm: `Confirm`,
  selectDataReservation: `Select`,
  modifyReservaton: `Modify your reservation here`,
  haveBonus: `You have a bonus of`,
  cancel: `Cancel`,
  noAssotaciatedR: `It has no associated reserves`,
  user: `User`,
  hi: `Hi`,
  reservationOf: `What do you want to do with your reservation of`,
  editBooking: `Edit booking`,
  cancelBooking: `Cancel booking`,
  diners2: `Diners`,
  choosePayment: `Choose a payment method and then click on pay to complete your reservation`,
  termsReservation: `By clicking on "Confirm reservation" you declare that you know and accept the treatment of personal data previously informed and by sending this form to Precompro you accept our Terms and Conditions and the Privacy Policy *. To fulfill the corporate purpose of Precompro, when making the reservation, your information will be shared with the partner restaurant.`,
  termsReservation1: `By clicking on "Confirm reservation" you declare that you know and accept the treatment of personal data previously informed and by sending this form to Precompro you accept our`,
  termsReservation2: `and`,
  termsReservation3: `To fulfill the corporate purpose of Precompro, when making the reservation, your information will be shared with the partner restaurant.`,
  termsReservationPay: `By clicking on "Pay" you declare that you know and accept the treatment of personal data previously informed and by sending this form to Precompro you accept our Terms and Conditions and the Privacy Policy *. To fulfill the corporate purpose of Precompro, when making the reservation, your information will be shared with the partner restaurant.`,
  termsReservationPay1: `By clicking on "Pay" and continue you declare that you know and accept the treatment of personal data previously informed and you accept our`,
  termsReservationPay2: `and the`,
  termsReservationPay2Aux: `of Precompro and the restaurant`,
  termsReservationPay3: `To fulfill the corporate purpose of Precompro, when making the reservation, your information will be shared with the partner restaurant.`,
  termsPaymentWompi: `I agree to the`,
  termsPaymentWompi1: `terms and conditions and the privacy policy of Wompi`,
  termsPaymentWompi2: `to make this purchase.`,
  addCard: `Add card`,
  registerPhoneReservation: `Register the cell phone number of the person who reserves`,
  preCompraSucces: `Your Pre-purchase has been made successfully`,
  finish: `Finish`,
  confirmReservation: `To confirm your reservation you have`,
  informationReservation: `You will only receive information about your reservation in this email`,
  informationReservationPayment: `Information of your pre-purchase`,
  informationReservationFree: `Information of your reservation`,
  informationReservationContact: `If you have any questions about this, please contact`,
  sms: `SMS`,
  whatsapp: `WhatsApp`,
  other: 'Other',
  address: `Address`,
  order: `Order`,
  notificationReservationQ: `Where do you want to receive notifications?`,
  notificationReservationQResponsive: `Where do you want to receive notifications?`,
  notificationReservationRememberEmail: `*Remember check the inbox and SPAM`,
  notificationReservationRememberSMS: `*SMS only apply for colombian numbers`,
  legalPersonLabel: `is the client a legal person?`,
  companyName: `Company name`,
  notificationReservation: `You will receive notifications of your reservation via email.`,
  updateBirthday: `Birthday`,
  dayMonthYear: `DAY/MONTH/YEAR`,
  day: `Day`,
  month: `Month`,
  bioSegurity: `For bio security measures we need the name of your companions`,
  addCompanions: `Add your companions`,
  addCompa: `Add companion`,
  addCompas: `Add companions`,
  suscribTo: `Suscribe to`,
  acceptInfoEvents: `I accept the sending of information related to (events, discounts, promotions)`,
  chooseMainPlate: `Choose the main dishes for your guests.`,
  rememberChoosePlate1: `Remember that you can only choose`,
  rememberChoosePlate2: `main dishes`,
  selectedMainPlate: `Selected main dishes`,
  person: `Person`,
  typeCustomer: `What type of customer are you?`,
  howFind: `How did you find out about us?`,
  experience: `Experience`,
  discountCode: `Add discount code`,
  enterCodeGift: `Enter the code of your coupon or giftcard`,
  giftCard: `Gift Card`,
  eventsTabOpen: 'Open events',
  eventsTabClose: 'Close events',
  eventsCalendar: 'Events calendar',
  selectOneOurEvents: 'Select one of our events',
  selectOneOurEvents_1: 'Select the event',
  selectOneOurEvents_2: 'Upcoming events',
  lastQuotas: 'LastQuotas',
  perPeople: 'per people',
  moreInfo: 'More info',
  noHoursEvent: 'There are no times available',
  scheduleEvent: 'Schedule:',
  eventValue: 'Event value:',
  advanceValue: 'Advance value:',
  bookEvent: 'Book',
  bookInThisEvent: 'Book in this event',
  closeEvent: 'Close',
  availabilityEventText: "There's no available events",
  availabilityEventTextAux: 'in',
  menu: `Menu`,
  addCode: `Add code`,
  celebrateSomething: `Do you celebrate something?`,
  includes: `Includes`,
  beerMainD: `5 BEERS + 1 MAIN DISH`,
  beerMainMusic: `5 BEERS + 1 main DISH + live music`,
  mainOption: `main dish options`,
  potato3Cordi: `3 cordilleras wing with rustic potatoes`,
  burgePotate3Cordi: `3 cordilleras burger with rustic potatoes`,
  veggieBurger: `Veggie Burger (grilled portobello) with rustic potatoes`,
  porkSandwich: `Shredded pork sandwich with rustic potatoes`,
  decoration: `Decoration`,
  personConsumable: `Person Consumables`,
  total: `Total`,
  details: `Details`,
  bottle4People: `One bottle for every 4 people`,
  costMenu: `750mil cost menu`,
  costMenu2: `590mil cost menu`,
  costMenu3: `400mil cost menu`,
  paidOut: `Paid out`,
  cover: `Cover`,
  mixer: `Mixers`,
  services: `Services`,
  pendingPayment: `Pending for payment`,
  save: `Save`,
  chooseDecoration: `Choose the decoration you want`,
  chooseDecorationMultiple: `Choose the decorations you want`,
  chooseMainD: `Choose the main dishe you want`,
  mainDishe: `Our`,
  mainDishe2: `main dishes are`,
  chooseOptionPerson: `Choose the option per person`,
  selectedDishes: `The`,
  selectedDishes2: `dishes were selected successfully.`,
  returnSelectPayment: `Return to select payment method`,
  creditCard: `Credit card`,
  payerDetails: `Payer details`,
  cardholder: `Cardholder name as printed on card`,
  idType: `ID type`,
  cardNumber: `Card number`,
  securityCode: `Security code`,
  year: `Year`,
  nCuota: `Number of installments`,
  saveCard: `You want to save this card for future purchases`,
  termsAndCondiW: `Terms and Conditions`,
  privacyPolicyW: `Privacy policy`,
  securePayments: `Secure payments through`,
  addCardPay: `Pay`,
  pay: `Pay`,
  payment: `Payment`,
  city: `City`,
  select2: `Select`,
  cuota: `Cuota`,
  debitCard: `Debit card`,
  chooseBank: `Choose Bank entity`,
  information: `Information`,
  naturalPerson: `Natural person`,
  legalPerson: `Legal person`,
  bank: `Bank`,
  confirmPass: `Confirm password`,
  orSing: `Or sing in with`,
  continueGuest: `Continue as a guest`,
  rememberCreatingAccount: `Remember that when creating an account you will not have to re-enter your data`,
  return2: `Return`,
  modify: `Modify`,
  locateBar: `If your reservation is for two (2) people, you will be located at the bar`,
  corporateEvent: `If you need a business dinner or a corporate event`,
  personConsumable1: `To guarantee your reservation you must make an advance of`,
  personConsumable2: `per person 100% consumables`,
  guaranteeReser1: `To guarantee your reservation, an advance of`,
  guaranteeReser2: `per Adult and`,
  guaranteeReser3: `per Child must be made`,
  keepMind: `Keep in mind`,
  noAvailableNumnberPerson: `Sorry there is no availability for this number of people`,
  selectedNumberPerson: `Select number of diners`,
  selectDate: `Select date`,
  selectHour: `Select hour`,
  add: `Add`,
  cardData: `Card data`,
  password: `Password`,
  signUp: `Sign up`,
  register: `Register`,
  waitingList: `Waiting list`,
  waitingListText1: ` we don't have available tables. You can leave us your information and you will be placed on the waiting list.`,
  waitingListText2: ` However, this does `,
  waitingListText3: `NOT`,
  waitingListText4: ` mean that you will have a table in the restaurant.`,
  waitingListText5: `If we have a table available, we will contact you.`,
  waitingListTextDate: `For the day `,
  waitingListTextHour: ` and time `,
  waitingListOptionRestaurant: ` `,
  waitingListOption: ` `,
  modalExperiences: {
    text1: `Select an experience for each person`,
    text2: `See description`,
    text3: `per person`,
    text4: `To select an experience you must click on`,
    text5: `“Add experience”`,
    text6: `for each person`
  },
  stepper: {
    pay: `Pay`
  },
  modalWaitingList: {
    continue: `Continue`,
    textPhone: `Indicative + phone`,
    send: `Send`
  },
  modalSuccessWaitingList: {
    successWaitingList: `The data has been entered on the waiting list.`,
    people: `People`,
    thanks: `Thanks for having us`
  },
  modalInformationAction: {
    text1: `At this time we do not have availability!`,
    text2: `The time you are selecting is not available!`,
    text3: `Select a time that is available`,
    text4: `Do you want to register on our waiting list?`,
    text5: `You can see the availability in other restaurants`,
    text6: `What would you like to do?`,
    text7: `Close`,
    text8: `Sign up for the waiting list`,
    text9: `See availability in other restaurants`
  },
  giftCardView: {
    title: `Personalized your gift card`,
    value: `Amount gift card`,
    form: {
      shippingDate: `ShippingDate`,
      shippingDate2: `Gift card mailing date`,
      to: `To: who receives the gift`,
      emailTo: `Email: where we will send it`,
      from: `From: your name`,
      emailFrom: `Your email`,
      message: `Write a message for the car`,
      action: `Pay`
    },
    payment: {
      method: `Payment method`,
      text1: `Choose a payment method and then click`,
      text2: `pay to complete your reservation.`,
      text3: `Credit card`,
      text4: `Debit card`,
      text5: `PSE inactive, try again later`,
      text6: `Back`,
      text7: `*By clicking on Pay you are accepting`,
      text8: `the terms and conditions`,
      text9: `privacy policy`,
      text10: `of Precompro; as well as the cancellation and/or modification policies of the restaurant.`,
      text11: `Back to means of payment`,
      text12: `CREDIT CARD`,
      text13: `PAY WITH YOUR`,
      text14: `CREDIT CARD`,
      text15: `Fill out the form`,
      text16: `and then click pay.`,
      text17: `Customer's information`,
      text18: `Name as it appears on card`,
      text19: `Document number`,
      text20: `Cell phone`,
      text21: `Address`,
      text22: `The field value is required`,
      text23: `City`,
      text24: `card data`,
      text25: `card number`,
      text26: `Month`,
      text27: `Year`,
      text28: `Dues`,
      text29: `*By clicking on Pay you are accepting`,
      text30: `the terms and conditions`,
      text31: `and`,
      text32: `privacy policy`,
      text33: `of Precompro; as well as the cancellation and/or modification policies of the restaurant.`,
      text34: `OWNER'S NAME`,
      text35: `Mail`,
      text36: `Document type`,
      text37: `PAY WITH YOUR DEBIT CARD`,
      text38: `Select your bank`,
      text39: `Name of owner`,
      text40: `Natural person`,
      text41: `Pay`,
      text42: `Secure payments through`,
      text43: `Legal Person`,
      text44: `Cancellation and/or modification policies`,
      text45: `He`,
      text46: `USER`,
      text47: `You can use your gift voucher...`,
      text48: `Accept`
    },
    confirmation: {
      text1: `Congratulations!`,
      text2: `Transaction status`,
      text3: `Generated and will be sent to`,
      text4: `To the email address`,
      text5: `On the chosen date`,
      text6: `Accept`,
      text7: `Policy Treatment of Personal Data of Click2buy S.A.S.`,
      text8: `Terms and Conditions.`,
      text9: `Accept`
    }
  },
  checkoutView: {
    text1: `Policy Treatment of Personal Data of Click2buy S.A.S.`,
    text2: `terms and Conditions`,
    components: {
      text1: `per person`,
      text2: `Price includes taxes.`,
      text3: `Suggested tip (optional):`,
      text4: `In the Chef's House.`,
      text5: `Cinema and Kitchen.`,
      text6: `Cooking lessons`,
      text7: `Home store.`,
      text8: `Questions`,
      text9: `* (Optional)`,
      text10: `Have you been in contact with people with acute respiratory infection or people diagnosed with COVID-19?`,
      text11: `Yes`,
      text12: `No`,
      text13: `Do you have any of the following symptoms: sore throat, fever over 38 degrees, dry and persistent cough, shortness of breath, muscle headache and/or joint pain?`,
      text14: `By adding the data of your companions, you declare that you have their prior authorization and they are accepting the data processing`,
      text15: `Click2buy S.A.S. Personal Data Processing Policy`,
      text16: `terms and Conditions`,
      text17: `Information`,
      text18: `Choose Entity`,
      text19: `Cancellation and/or modification policies`,
      text20: `He`,
      text21: `USER`,
      text22: `You can cancel or modify the reservation, as long as it is with`,
      text23: `hour(s) in advance of the selected time with the purchase of the Ticket. Once this time has elapsed, the reservation is considered "confirmed" and there will be no right to refund or modifications by the restaurant.`,
      text24: `Accept`,
      text25: `Transaction declined:`,
      text26: `Reason`,
      text27: `Please check another payment method`,
      text28: `You can still make the payment as long as you still have time and thus confirm your reservation.`,
      text29: `We will wait for you!`,
      text30: `Try again with another means`,
      text31: `Transaction in Process:`,
      text32: `We are validating the payment`,
      text33: `The approval of the transaction may take a few minutes, so your reservation may take a few minutes to be reflected or if it is rejected, try again with another means of payment.`,
      text34: `Payment terms and conditions`,
      text35: `You can cancel or modify your reservation`,
      text36: `Reservation time`,
      text37: `To guarantee your reservation, an advance payment is made per person, 100% consumables. If you do not attend or do not cancel at least {hours} hour{plur} in advance, it will not be refunded. To see all the`,
      text38: `Terms and Conditions of payment click `,
      text39: `here `
    }
  },
  errorView: {
    text1: `Oops! Page not found.`,
    text2: `We couldn't find the page you were looking for.`
  },
  eventsView: {
    text1: `EVENTS`,
    text2: `Experiences`,
    text3: `Event information`,
    text4: `No events found for the next dates.`
  },
  maintenance: {
    text1: `Excuse me!`,
    text2: `The establishment is not enabled.`
  },
  pollView: {
    text1: `Send`,
    text2: `Sending...`,
    text3: `If you have any questions in this regard, please contact`,
    text4: `or write to`,
    text5: `Thank you!`,
    text6: `for your response and help us improve`,
    text7: `Comparte tu calificación a través de:`
  },
  pollOldView: {
    text1: `Your answer has been sent successfully, Thank you for rating us!`,
    text2: `We invite you to rate us on TripAdvisor`,
    text3: `* Please answer this question`,
    text4: `(Optional)`,
    text5: `If you have any questions about it, please`,
    text6: `Submit Rating`,
    text7: `Sending Rating...`,
    text8: `If you have any questions in this regard, please contact`,
    text9: `or write to`
  },
  pollQrView: {
    text1: `*I know and accept the policy for the treatment of my personal data`,
    text2: `*You must accept the data treatment policy`,
    text3: `Did you arrive for a reservation?`,
    text4: `Yes`,
    text5: `No`,
    text6: `Send`,
    text7: `Sending...`,
    text8: `If you have any questions in this regard, please contact`,
    text9: `or write to`,
    text10: `Thank you!`,
    text11: `for your response and help us improve`,
    name: 'Name',
    identification: 'Identification card',
    cellphone: 'Cellphone',
    email: 'Email',
    validations: {
      phoneRequired: 'The phone field is required'
    }
  },
  prepagoView: {
    text1: `Pre-purchase data`,
    text2: `To pay your reservation you have left`,
    text3: `People`,
    text4: `Date`,
    text5: `Hour`,
    text6: `Value per person`,
    text7: `Total people:`,
    text8: `full value`,
    text9: `*To guarantee your reservation, an advance payment is made per person, 100% consumables.`,
    text10: `Client data`,
    text11: `accept`,
    text12: `terms and Conditions`,
    text13: `I authorize the`,
    text14: `processing of my personal data`,
    text15: `with the following conditions.`,
    text16: `Select a payment method`,
    text17: `Credit card`,
    text18: `Debit card`,
    text19: `Name as it appears on card`,
    text20: `Document type`,
    text21: `Document number`,
    text22: `Mail`,
    text23: `Phone number`,
    text24: `Card data`,
    text25: `Card number`,
    text26: `Month`,
    text27: `Year`,
    text28: `Dues`,
    text29: `Secure payments through`,
    text30: `Pay`,
    text31: `Select your bank`,
    text32: `Name`,
    text33: `Natural person`,
    text34: `Legal person`,
    text35: `*By clicking on Pay you are accepting the terms and conditions and the privacy policy of Precompro; as well as the cancellation and/or modification policies of the restaurant.`,
    text36: `Secure payments through`,
    text37: `Pay`,
    text38: `This data is not modifiable`
  },
  reservationView: {
    text1: `What do you want to do with your pre-purchase?`,
    text2: `The reservation you had for him`,
    text3: `has been successfully cancelled.`,
    text4: `Reconfirm`,
    text5: `rebook`,
    text6: `If you have any questions in this regard, please contact`,
    text7: `This reservation already`,
    text8: `it's found`,
    text9: `This reservation is no longer within the time to modify or cancel.`
  },
  selectView: {
    login: {
      text1: `Password recovery`,
      text2: `Enter the email address and click`,
      text3: `Send.`,
      text4: `Enter the code you received in your email`,
      text5: `If you already have an account with us you will receive instructions by email on how to reset your password.`,
      text6: `Find out`,
      text7: `Change Password`,
      text8: `New Password`,
      text9: `Repeat your new password`,
      text10: `Return`,
      text11: `Name`,
      text12: `Password`,
      text13: `Forgot your password?`,
      text14: `Login`,
      text15: `If you already have an account you can`,
      text16: `If you still don't have you can`,
      text17: `To register`,
      text18: `Check in`,
      text19: `*Remember that when creating an account you will not have to re-enter your data`,
      text20: `You can also login with`,
      text21: `Register now.`,
      text22: `By entering the data and continuing, you declare that you know and accept the treatment of personal data previously informed and you are accepting`,
      text23: `and`,
      text24: `from Precompro and from the restaurant.`,
      text25: `Click2buy S.A.S. Personal Data Processing Policy`,
      text26: `Terms and Conditions`,
      text27: `Document number`,
      text28: `Confirm password`
    },

    information: {
      text1: `Looking for availability in other restaurants!`
    },
    schedule: {
      text1: `Hour`
    }
  },
  customizeReservation: `Customize your reservation`,
  babys: `Babys`,
  addDecoration: `Add decoration`,
  addExperience: `Add experience`,
  celebrationQuestion: `What do you celebrate?`,
  placeholderDate: `Day/Month/Year`,
  addMainPlates: `Add main plate`,
  paymentSummary: `Payment summary`,
  prePurchase: `Pre-purchase`,
  event: `Event`,
  documentNumber: `Id`,
  lunes: `Monday`,
  martes: `Tuesday`,
  miércoles: `Wednesday`,
  jueves: `Thursday`,
  viernes: `Friday`,
  sábado: `Saturday`,
  domingo: `Sunday`,
  preCompra: `Pre-purchase`,
  newEditReservation: {
    today: `Today`,
    tomorrow: `Tomorrow`,
    currentReservation: `This is your current reservation`,
    modifiedReservation: `This is your modified reservation`,
    currentDateReservation: `Current reservation date`,
    changeZoneQuestion: `Do you want to change your current zone?`,
    currentZoneSelected: `This is your current zone`,
    availableZones: `These are the available zones`,
    yourModifiedZone: `This is your modified zone`,
    selectNewZone: `Select your new zone`,
    yourNewZone: `Your new zone is:`,
    newDateReservation: `New date`,
    seeMoreDates: `See more dates`,
    modifiedSuccess: `Your reservation was updated successfully`,
    updatedData: `These are the details of your reservation with the new modification`,
    currentPeople: `Current number of people`,
    aditionalPeople: `Aditional people`,
    valueToPay: `Amount to be paid`,
    paidValue: `Paid value was`,
    aditionalValue: `Aditional amount to be paid`,
    previous: `Previous`,
    next: 'Next',
    aditionalsReservation: `In addition to your reservation you have`,
    modifyAditionals: `If you want to modify the experiences or the decoration click on the button`,
    hourCurrent: 'Current hour',
    hourNew: 'New hour'
  },
  morePeopleMessageDefault: `If you want to reservate for more people, please contact:`,
  morePeopleMessage: `The maximum number of people allowed per reservation at this time is <strong>({peopleMaxTranslate})</strong>. Please select a different time or adjust the number of people.`,
  morePeopleMessageDate: `The maximum number of people allowed for this day is <strong>({peopleMaxTranslate})</strong>. Please select a different day or adjust the number of people.`,
  checkoutValidations: {
    phone: {
      required: 'The phone number is required',
      numeric: 'Enter numbers only',
      min: 'You must enter a minimum of characters',
      max: 'Ha superado el límite de caracteres'
    },
    documentNumber: {
      required: 'The document field is required',
      number: 'Only numbers is required'
    },
    bank: {
      required: 'The bank field is required'
    },
    cardNumber: {
      required: 'The card number is required',
      max: 'The card number should be of length between 8 and 19'
    },
    dateCard: {
      required: 'Field is required',
      invalid: 'Field invalid'
    },
    field: {
      required: 'Field required'
    }
  }
};
