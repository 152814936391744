<template>
  <section>
    <div id="calendar" v-if="!loadCalendar">
      <div class="date-options-container" v-if="editPeople && !showMoreDates">
        <!-- First Suggestion -->
        <div class="date-option" v-if="sugDay1" @click="onSelectedDate(sugDay1)"
          :style="{backgroundColor: currentBackground(sugDay1)}"
          :class="{daySelectedSug: (sugDay1 === selectedDate), 'date-option-edit': editPeople}">
          <template v-if="parseInt(sugDay1) === getTodayF(today)">
            <strong class="date-option-text">{{ $t('newEditReservation.today') }}</strong>
            <p class="date-option-text">{{ new Date(sugDay1) | moment('timezone', vendorTimezone, 'dddd D MMMM')}}</p>
          </template>
          <template v-else-if="parseInt(sugDay1) === parseInt(tomorrow.format('x'))">
            <strong class="date-option-text">{{ $t('newEditReservation.tomorrow') }}</strong>
            <p class="date-option-text">{{ new Date(sugDay1) | moment('timezone', vendorTimezone, 'dddd D MMMM')}}</p>
          </template>
          <template v-else>
            <strong class="date-option-text">{{ new Date(sugDay1) | moment('timezone', vendorTimezone, 'dddd')}}</strong>
            <p class="date-option-text">{{ new Date(sugDay1) | moment('timezone', vendorTimezone, 'D MMMM')}}</p>
          </template>
        </div>
        <!-- Second Suggestion -->
        <div class="date-option" v-if="sugDay2" @click="onSelectedDate(sugDay2)"
          :style="{backgroundColor: currentBackground(sugDay2)}"
          :class="{daySelectedSug: (sugDay2 === selectedDate), 'date-option-edit': editPeople}">
          <template v-if="sugDay2 === parseInt(tomorrow.format('x'))">
            <strong class="date-option-text">{{ $t('newEditReservation.tomorrow') }}</strong>
            <p class="date-option-text">{{ new Date(sugDay2) | moment('timezone', vendorTimezone, 'dddd D MMMM')}}</p>
          </template>
          <template v-else>
            <strong class="date-option-text">{{ new Date(sugDay2) | moment('timezone', vendorTimezone, 'dddd')}}</strong>
            <p class="date-option-text">{{ new Date(sugDay2) | moment('timezone', vendorTimezone, 'D MMMM')}}</p>
          </template>
        </div>
        <!-- Third Suggestion -->
        <div class="date-option" v-if="sugDay3" @click="onSelectedDate(sugDay3)"
          :style="{backgroundColor: currentBackground(sugDay3)}"
          :class="{daySelectedSug: (sugDay3 === selectedDate), 'date-option-edit': editPeople}"
        >
          <strong class="date-option-text">{{ new Date(sugDay3) | moment('timezone', vendorTimezone, 'dddd')}}</strong>
          <p class="date-option-text">{{ new Date(sugDay3) | moment('timezone', vendorTimezone, 'D MMMM')}}</p>
        </div>
        <div class="legend-date-status">
          <div class="legend-date-current" :style="{backgroundColor: oldSelected()}"></div>
          <p class="small-text">{{ $t('newEditReservation.currentDateReservation') }}</p>
          <div class="legend-date-new"></div>
          <p class="small-text">{{ $t('newEditReservation.newDateReservation') }}</p>
        </div>
      </div>
      <template v-if="!editPeople || showMoreDates">
        <div class="columns is-mobile" v-if="eventCalendar">
          <div
            class="monthName md-subhead column is-half has-text-centered item-calendar is-uppercase"
            :class="{editIconCalendar: editPeople}"
          >
            {{ monthName }}
          </div>
          <div class="arrows-container-events">
            <div
              @click.prevent.stop="changeMonthsCalendar('before')"
              class="pointer column item-calendar has-text-left arrows"
              :class="{editIconCalendar: editPeople}"
            >
              <i class="fa fa-angle-left"></i>
            </div>
            <div
              @click.prevent.stop="changeMonthsCalendar('next')"
              class="pointer column item-calendar has-text-right arrows"
              :class="{editIconCalendar: editPeople}"
            >
              <i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div class="columns is-mobile" v-else>
          <div
            @click.prevent.stop="changeMonthsCalendar('before')"
            class="pointer column item-calendar has-text-left arrows"
            :class="{editIconCalendar: editPeople}"
          >
            <i class="fa fa-angle-left"></i>
          </div>
          <div
            class="monthName md-subhead column is-half has-text-centered item-calendar is-uppercase"
            :class="{editIconCalendar: editPeople}"
          >{{ monthName }}</div>
          <div
            @click.prevent.stop="changeMonthsCalendar('next')"
            class="pointer column item-calendar has-text-right arrows"
            :class="{editIconCalendar: editPeople}"
          >
            <i class="fa fa-angle-right"></i>
          </div>
        </div>
        <div class="calendar" :class="{editBorderCalendar: editPeople}">
          <div class="header-row columns is-gapless is-multiline v" :class="{editBorderCalendar: editPeople}">
            <div class="cell header column" :class="{editBorderCalendar: editPeople}">
              <div class="md-caption is-uppercase">{{ $t('sunday').substring(0, eventCalendar ? 2 : 3) }}</div>
            </div>
            <div class="cell header column" :class="{editBorderCalendar: editPeople}">
              <div class="md-caption is-uppercase">{{ $t('monday').substring(0, eventCalendar ? 2 : 3) }}</div>
            </div>
            <div class="cell header column" :class="{editBorderCalendar: editPeople}">
              <div class="md-caption is-uppercase">{{ $t('tuesday').substring(0, eventCalendar ? 2 : 3) }}</div>
            </div>
            <div class="cell header column" :class="{editBorderCalendar: editPeople}">
              <div class="md-caption is-uppercase">{{ $t('wednesday').substring(0, eventCalendar ? 2 : 3) }}</div>
            </div>
            <div class="cell header column" :class="{editBorderCalendar: editPeople}">
              <div class="md-caption is-uppercase">{{ $t('thursday').substring(0, eventCalendar ? 2 : 3) }}</div>
            </div>
            <div class="cell header column" :class="{editBorderCalendar: editPeople}">
              <div class="md-caption is-uppercase">{{ $t('friday').substring(0, eventCalendar ? 2 : 3) }}</div>
            </div>
            <div class="cell header column" :class="{editBorderCalendar: editPeople}">
              <div class="md-caption is-uppercase">{{ $t('saturday').substring(0, eventCalendar ? 2 : 3) }}</div>
            </div>
          </div>
          <div
            :key="row"
            class="header-row columns is-gapless is-multiline is-mobile"
            :class="{editBorderCalendar: editPeople}"
            v-for="row in (isLargeMonth ? 6 : 5)"
          >
            <div :key="cell" class="column" v-for="cell in 7">
              <div class="cell column" :class="{editBorderCalendar: editPeople}" v-show="!dates[row - 1][cell - 1]"></div>
              <div
                :id="'value' + (anticipationTime(timestamps[row - 1][cell - 1]))"
                :class="{
                  'currentDay': isCurrentDayVal(timestamps[row - 1][cell - 1]) && eventCalendar,
                  'dayNotAvailableInEventsView': eventCalendar,
                  editDaySelected: ((timestamps[row - 1][cell - 1] === selectedDate || timestamps[row - 1][cell - 1] === dateStyle) && editPeople),
                  daySelected: ((timestamps[row - 1][cell - 1] === selectedDate) && !isSameDate(timestamps[row - 1][cell - 1]) && !editPeople),
                  dayDisabled: (noSchedule[row - 1][cell - 1] === 0) || usingMoment(timestamps[row - 1][cell - 1]) || anticipationTime(timestamps[row - 1][cell - 1]) || limitAvailabilityCalendar(timestamps[row - 1][cell - 1]) || rumbaDay(timestamps[row - 1][cell - 1]),
                  'dayNotAvailable': AvailableDate(timestamps[row - 1][cell - 1]),
                  'blockDay': blockedDates(timestamps[row - 1][cell - 1]),
                  editBorderCalendar: editPeople
                }"
                :style="{backgroundColor: currentBackground(timestamps[row - 1][cell - 1])}"
                @click.prevent.stop="disabledCalendarEvents ? ()=> null : onSelectedDate(timestamps[row - 1][cell - 1])"
                class="cell date relative available pointer"
                v-if="dates[row - 1][cell - 1] && timestamps[row - 1][cell - 1]"
              >
                <div
                  class="md-caption dot-text2"
                  v-show="reservationAmounts[timestamps[row - 1][cell - 1]]"
                >{{reservationAmounts[timestamps[row - 1][cell - 1]]}}</div>
                <div
                  :class="{
                    daySelected: (timestamps[row - 1][cell - 1] === selectedDate && !isSameDate(timestamps[row - 1][cell - 1]) && !editPeople),
                    editDaySelected: (timestamps[row - 1][cell - 1] === selectedDate && editPeople)
                  }"
                  class="md-caption day"
                >{{days[row - 1][cell - 1]}}</div>
                <div class="icon-event" v-if="allEvents[timestamps[row - 1][cell - 1]]">
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="legend-date-status" :class="{'mt-10': showMoreDates }" v-if="editPeople && showMoreDates">
          <div class="legend-date-current" :style="{backgroundColor: oldSelected()}"></div>
          <p class="small-text">{{ $t('newEditReservation.currentDateReservation') }}</p>
          <div class="legend-date-new"></div>
          <p class="small-text">{{ $t('newEditReservation.newDateReservation') }}</p>
        </div>
      </template>
      <div>
        <div class="event-message colorEvent" v-if="selectedDate && allEvents[selectedDate]">
        </div>
        <div class="info-footer-calendar">
          <div class="info-footer-calendar-event colorEvent" v-if="selectedDate && eventMonth">
            <i class="fa fa-star colorEvent"></i>
            <span class="info-footer-calendar-start">{{ $t('eventDay') }}</span>
          </div>
          <div class="info-footer-calendar-not-avail" v-if="dayBlock">
            <div class="info-footer-calendar-not-avail-logo"></div>
            <span class="info-footer-calendar-not-avail-text">Días sin disponibilidad</span>
          </div>
        </div>
        <div class="more-dates" @click="showMoreDates = true" v-if="editPeople && !showMoreDates">
          <i class="icon_calendar icon-mks"></i>
          <span class="text-date" >
            {{ $t('newEditReservation.seeMoreDates') }}
          </span>
        </div>
      </div>
    </div>
    <i class="icon-mks icon-precompro-loader-new snipperDateTime" v-if="loadCalendar"></i>
  </section>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import eventTypes from '@/store/types/event';
import dateNotAvailable from './dateNotAvailable';

export default {
  props: ['colorCalendar', 'color1', 'color2', 'editPeople', 'editDaySelected', 'eventCalendar', 'actionCalendarChangeMonthsFromEvents', 'disabledCalendarEvents', 'dateEventPicker'],
  data: () => ({
    day1: null,
    day2: null,
    day3: null,
    availabilityAttempts: 0,
    currentMonth: null,
    currentYear: null,
    availabilityMonth: null,
    selectedDat: null,
    now: null,
    zone: false,
    mesa: null,
    dateStyle: null,
    today: null,
    tomorrow: null,
    dayAfterTomorrow: null,
    showMoreDates: false,
    eventMonth: false,
    dayBlock: false,
    todayDate: null,
    serverTime: null,
    actualHora: null,
    loadCalendar: false
  }),
  created () {
    if (this.vendor.timezone !== 'America/Bogota') {
      this.getHourTimezone().then((data) => {
        this.now = this.$moment(data.datetime).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DD');
        this.todayDate = parseInt(this.$moment(data.datetime).hours(0).minutes(0).seconds(0).milliseconds(0).format('x'));
        // se reemplaza currentMonth por un estado global currentMonthGlobal
        /* this.currentMonth = parseInt(this.$moment(data.datetime).hours(0).minutes(0).seconds(0).milliseconds(0).format('M')) - 1; */
        this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, parseInt(this.$moment(data.datetime).hours(0).minutes(0).seconds(0).milliseconds(0).format('M')) - 1);
        this.currentYear = parseInt(this.$moment(data.datetime).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY'));
        this.today = this.$moment(data.datetime).hours(0).minutes(0).seconds(0).milliseconds(0);
        this.tomorrow = this.today.clone().add(1, 'days');
        this.dayAfterTomorrow = this.today.clone().add(2, 'days');
        this.actualHora = parseInt(this.$moment(data.datetime).format('x'));
        this.serverTime = data.datetime;
      });
    } else {
      this.$store.dispatch({
        type: 'global:getServerTime'
      }).then((data) => {
        this.now = this.$moment(data.serverTime).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DD');
        this.todayDate = parseInt(this.$moment(data.serverTime).hours(0).minutes(0).seconds(0).milliseconds(0).format('x'));
        // se reemplaza currentMonth por un estado global currentMonthGlobal
        /* this.currentMonth = parseInt(this.$moment(data.serverTime).hours(0).minutes(0).seconds(0).milliseconds(0).format('M')) - 1; */
        this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, parseInt(this.$moment(data.serverTime).hours(0).minutes(0).seconds(0).milliseconds(0).format('M')) - 1);
        this.currentYear = parseInt(this.$moment(data.serverTime).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY'));
        this.today = this.$moment(data.serverTime).hours(0).minutes(0).seconds(0).milliseconds(0);
        this.tomorrow = this.today.clone().add(1, 'days');
        this.dayAfterTomorrow = this.today.clone().add(2, 'days');
        this.actualHora = parseInt(this.$moment(data.serverTime).format('x'));
        this.serverTime = data.serverTime;
      });
    }
    if (!this.eventCalendar) {
      if (this.params.isActiveAvailabilityRange === 1) {
        this.loadCalendar = true;
      } else {
        this.loadCalendar = false;
      }
    }
  },
  mounted () {
    if (this.dataReservationEdit.zoneId && this.editPeople === true && this.dataReservationEdit.balancePaid > 0) {
      this.$store.commit(reservationTypes.mutations.setSelectedZone, this.dataReservationEdit.zoneId);
      this.zone = this.dataReservationEdit.zoneId;
    } else {
      this.zone = this.selectedZone;
    }
    this.selectedDat = this.selectedDate ? this.selectedDate : null;
    if (this.selectedDat !== null) {
      // se reemplaza currentMonth por un estado global currentMonthGlobal
      /* this.currentMonth = parseInt(this.$moment(this.selectedDat).format('M')) - 1; */
      this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, parseInt(this.$moment(this.selectedDat).format('M')) - 1);
      this.currentYear = parseInt(this.$moment(this.selectedDat).format('YYYY'));
    }
    if (this.selectedDate !== null) {
      // se reemplaza currentMonth por un estado global currentMonthGlobal
      /* this.currentMonth = parseInt(this.$moment(this.selectedDate).format('M') - 1); */
      this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, parseInt(this.$moment(this.selectedDate).format('M') - 1));
    }
    /* this.availabilityMonth = this.currentMonth; */
    this.availabilityMonth = this.currentMonthGlobal;
    if (this.editPeople) {
      setTimeout(() => {
        this.getSuggestedDays();
      }, 500);
    }
  },
  computed: {
    ...mapGetters({
      currentMonthGlobal: eventTypes.getters.currentMonthGlobal
    }),
    monthName () {
      // se reemplaza currentMonth por un estado global currentMonthGlobal
      /* const firstOfThisMonth = new Date(this.currentYear, this.currentMonth + 1, 0, 0, 0, 0, 0); */
      const firstOfThisMonth = new Date(this.currentYear, this.currentMonthGlobal + 1, 0, 0, 0, 0, 0);
      return this.eventCalendar ? this.$moment(firstOfThisMonth).format('MMMM') : this.$moment(firstOfThisMonth).format('MMMM, YYYY');
    },
    dayOfWeekOfFirst () {
      // se reemplaza currentMonth por un estado global currentMonthGlobal
      /* const date = new Date(this.currentYear, this.currentMonth, 1); */
      const date = new Date(this.currentYear, this.currentMonthGlobal, 1);
      return this.$moment(date).day();
    },
    dates () {
      const dates = _.map(_.range(6), row =>
        _.map(_.range(7), cell => {
          // se reemplaza currentMonth por un estado global currentMonthGlobal
          /* const initialDate = new Date(this.currentYear, this.currentMonth, 0, 0, 0, 0, 0); */
          const initialDate = new Date(this.currentYear, this.currentMonthGlobal, 0, 0, 0, 0, 0);
          const date = this.$moment(initialDate).add(cell - this.dayOfWeekOfFirst + (7 * row + 1), 'days').startOf('day');
          const mon = this.$moment(date).month() === 0 ? 12 : this.$moment(date).month();
          return mon === this.$moment(initialDate).month() + 1 ? this.$moment(date).hours(0).minutes(0).seconds(0).milliseconds(0) : null;
        })
      );
      return dates;
    },
    datesAvailability () {
      const dates = _.map(_.range(6), row =>
        _.map(_.range(7), cell => {
          const initialDate = new Date(this.currentYear, this.availabilityMonth, 0, 0, 0, 0, 0);
          const date = this.$moment(initialDate).add(cell - this.dayOfWeekOfFirst + (7 * row + 1), 'days').startOf('day');
          const mon = this.$moment(date).month() === 0 ? 12 : this.$moment(date).month();
          return mon === this.$moment(initialDate).month() + 1 ? this.$moment(date).hours(0).minutes(0).seconds(0).milliseconds(0) : null;
        })
      );
      return dates;
    },
    days () {
      return _.map(this.dates, row =>
        _.map(row, cell => (cell ? cell.date() : null))
      );
    },
    timestamps () {
      return _.map(this.dates, row =>
        _.map(row, cell => (cell ? cell.valueOf() : null))
      );
    },
    timestampsAvailability () {
      return _.map(this.datesAvailability, row =>
        _.map(row, cell => (cell ? cell.valueOf() : null))
      );
    },
    isLargeMonth () {
      return _.some(this.dates[5], date => !!date);
    },
    noSchedule () {
      return _.map(this.dates, row =>
        _.map(row, date => (date ? this.getInSchedule(date) : null))
      );
    },
    noScheduleAvailability () {
      return _.map(this.datesAvailability, row =>
        _.map(row, date => (date ? this.getInSchedule(date) : null))
      );
    },
    reservationAmounts () {
      const dates = _.compact(_.flattenDeep(this.dates));
      const amounts = _.map(dates, date =>
        _(this.reservations[date.valueOf()])
          .filter(
            reservation =>
              reservation.codeStatus === 0 ||
              reservation.codeStatus === 1 ||
              reservation.codeStatus === 2
          )
          .size()
      );
      return _.zipObject(_.map(dates, date => date.valueOf()), amounts);
    },
    sugDay1 () {
      if (this.day1 != null) {
        return parseInt(this.day1);
      }
      if (this.today != null) {
        return parseInt(this.today.format('x'));
      }
      return 0;
    },
    sugDay2 () {
      if (this.day2 != null) {
        return parseInt(this.day2);
      }
      if (this.tomorrow) {
        return parseInt(this.tomorrow.format('x'));
      }
      return 0;
    },
    sugDay3 () {
      if (this.day3 != null) {
        return parseInt(this.day3);
      }
      if (this.dayAfterTomorrow) {
        return parseInt(this.dayAfterTomorrow.format('x'));
      }
      return 0;
    },
    isCurrentDayVal () {
      return (timestamp) => {
        const fecha = new Date(timestamp);
        const hoy = new Date();
        return fecha.getDate() === hoy.getDate() &&
              fecha.getMonth() === hoy.getMonth() &&
              fecha.getFullYear() === hoy.getFullYear();
      };
    }
  },
  methods: {
    getTodayF (today) {
      if (today != null) {
        const format = today.format('x');
        return parseInt(format);
      }
    },
    async getSuggestedDays () {
      if (this.params.isActiveAvailabilityRange === 1) {
        const date = this.selectedDate;
        let availableDays = []; // Array para almacenar días disponibles

        // Primero, agregamos el día seleccionado
        const selectedTimestamp = this.$moment(date).format('x');
        availableDays.push(selectedTimestamp); // Agregamos el día seleccionado

        for (let index = 1; index <= 15; index++) {
          // Salimos del bucle si ya tenemos 2 días adicionales disponibles
          if (availableDays.length >= 3) {
            break;
          }

          const fecha = this.$moment(date).add(index, 'days').format('YYYY-MM-DD');
          const times = this.$moment(date).add(index, 'days').format('x');

          // Verificamos si la fecha está bloqueada
          const exists = this.blocked.find((i) => i.date === fecha);
          if (exists !== undefined) {
            continue; // Si la fecha está bloqueada, saltamos a la siguiente
          }

          // Comprobamos la disponibilidad
          const available = this.availabilityCalendar[fecha];
          if (available && available.status === true) {
            if (available.minSize !== 0 && available.maxSize !== 0) {
              if (this.selectedPeople >= available.minSize && this.selectedPeople <= available.maxSize) {
                // Guardamos la fecha disponible solo si no está ya en el array
                if (!availableDays.includes(times)) {
                  availableDays.push(times); // Agregamos el tiempo al array
                }
              }
            }
          } else if (available === undefined) {
            // Si la disponibilidad no se encuentra, hacemos la solicitud para obtenerla
            try {
              const response = await this.$store.dispatch({
                type: 'reservation:availabilityCalendar',
                data: { vendorId: this.vendor.id, date: fecha, unique: 1 }
              });
              const avail = response[fecha];
              if (avail && avail.status === true) {
                if (avail.minSize !== 0 && avail.maxSize !== 0) {
                  if (this.selectedPeople >= avail.minSize && this.selectedPeople <= avail.maxSize) {
                    // Guardamos la fecha disponible solo si no está ya en el array
                    if (!availableDays.includes(times)) {
                      availableDays.push(times); // Agregamos el tiempo al array
                    }
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        }

        // Si hay menos de 3 fechas disponibles, aseguramos que day1 y day2 estén asignadas
        if (availableDays.length > 0) {
          this.day1 = availableDays[0]; // Día seleccionado
        }
        if (availableDays.length > 1) {
          this.day2 = availableDays[1]; // Primer día adicional
        }
        if (availableDays.length > 2) {
          this.day3 = availableDays[2]; // Segundo día adicional
        }

        // Si no se encuentra suficiente disponibilidad, asignamos null
        this.day1 = this.day1 || null;
        this.day2 = this.day2 || null;
        this.day3 = this.day3 || null;
      } else {
        for (let i = 0; i < this.timestampsAvailability.length; i++) {
          for (let j = 0; j < this.timestampsAvailability[i].length; j++) {
            if (this.timestampsAvailability[i][j] !== null && this.timestampsAvailability[i][j] >= parseInt(this.selectedDate) && this.noScheduleAvailability[i][j] === 1) {
              if (this.day1 == null) {
                this.day1 = this.timestampsAvailability[i][j];
              } else if (this.day2 == null && this.timestampsAvailability[i][j] !== this.day1) {
                this.day2 = this.timestampsAvailability[i][j];
              } else if (this.day3 == null && this.timestampsAvailability[i][j] !== this.day1 && this.timestampsAvailability[i][j] !== this.day2) {
                this.day3 = this.timestampsAvailability[i][j];
              }
            }
            if (this.day1 !== null && this.day2 !== null && this.day3 !== null) {
              return;
            }
          }
        }
        if ((!this.day1 || !this.day2 || this.day3) && this.availabilityAttempts <= 6) {
          this.availabilityMonth++;
          this.availabilityAttempts++;
          this.getSuggestedDays();
        }
      }
    },
    currentBackground (date) {
      const current = parseInt(this.$moment(parseInt(this.dataReservationEdit.date)).minutes(0).seconds(0).hours(0).milliseconds(0).format('x'));
      if (this.isCurrentDate(date, current)) {
        if (date === this.selectedDate) {
          return this.colorWithOpacity(this.editPeople);
        } else {
          let colorObject = this.hexToRgb(this.design.bgColorCheckout);
          if (colorObject === null) {
            colorObject = this.hexToRgb(this.design.color1);
          }
          return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
        }
      }
      return '';
    },
    isSameDate (timestamp1, timestamp2 = this.dataReservationEdit.date) {
      const date1 = this.$moment(parseInt(timestamp1));
      const date2 = this.$moment(parseInt(timestamp2));
      return date1.isSame(date2, 'day');
    },
    isCurrentDate (timestamp1, timestamp2 = this.dataReservationEdit.date) {
      return parseInt(timestamp1) === parseInt(timestamp2);
    },
    oldSelected () {
      let colorObject = this.hexToRgb(this.design.bgColorCheckout);
      if (colorObject === null) {
        colorObject = this.hexToRgb(this.design.color1);
      }
      return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
    },
    blockedDates (date) {
      if (this.params.isActiveAvailabilityRange === 1) {
        const info = this.blocked.find((i) => i.date === this.$moment(date).format('YYYY-MM-DD'));
        if (info) {
          this.dayBlock = true;
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    AvailableDate (date) {
      if (this.params.isActiveAvailabilityRange === 1) {
        if (date >= parseInt(this.$moment().format('x'))) {
          const info = this.availabilityCalendar[this.$moment(date).format('YYYY-MM-DD')];
          if (info && (info.status === true || info.status == null)) {
            return false;
          }
          this.dayBlock = true;
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeHours () {
      if (this.selectedDate) {
        let date = new Date(this.selectedDate);
        this.selectedDat = date.getTime();
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
        let dateTime = date.getTime();
        this.$store
          .dispatch({
            type: 'whiteLabel:avalibilityHour',
            data: {
              timestamp: dateTime,
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              people: this.selectedPeople,
              zone: this.selectedZone,
              typeReservation: this.selectedTypeReservation,
              tableId: this.selectedTable
            }
          })
          .then(() => {
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          });
        if (this.vendor.id === 334) {
          if (this.$moment(this.selectedDate).format('YYYY-MM-DD') === '2022-12-24') {
            this.$emit('show-modal-tramonti', true);
          }
        }
        if (this.user.id !== 13744) {
          this.$store.dispatch({
            type: 'reservation:reservationUserDate',
            data: {
              userId: this.user.id,
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              timestamp: this.$moment(date).format('YYYY-MM-DD')
            }
          });
        }
        if (this.selectedTypeReservation === 'Cena') {
          let valid = false;
          if (this.selectedDate) {
            const day = parseInt(this.$moment(this.selectedDate).format('d'));
            if (day === 5 || day === 6) {
              valid = true;
            }
          }
          this.$emit('show-modal-gaira-message', valid);
        }
      }
    },
    usingMoment (current) {
      if (parseInt(this.$moment(current).format('x')) < parseInt(this.$moment(this.now).format('x'))) {
        return true;
      } else {
        return false;
      }
    },
    limitAvailabilityCalendar (date) {
      let limitDay = this.params.dayCalendarAvailability;
      let datesAvailables = [];
      if (limitDay > 0) {
        for (var i = 0; i < limitDay; i++) {
          datesAvailables.push(
            parseInt(
              this.$moment()
                .add(i, 'days')
                .seconds(0)
                .minutes(0)
                .hours(0)
                .milliseconds(0)
                .format('x')
            )
          );
        }
        if (_.indexOf(datesAvailables, date) >= 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    rumbaDay (date) {
      if (this.editPeople === true) {
        let selectedDayEdit = parseInt(
          this.$moment(this.dataReservationEdit.fechaCompleta)
            .seconds(0)
            .minutes(0)
            .hours(0)
            .milliseconds(0)
            .format('d')
        );
        if (this.dataReservationEdit.typeReservation === 'Rumba' && this.dataReservationEdit.vendorId === 72 && (selectedDayEdit === 5 || selectedDayEdit === 6)) {
          let dayOfWeek = parseInt(
            this.$moment(date)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          let dayOfWeekSelected = parseInt(
            this.$moment(this.selectedDate)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          if (dayOfWeekSelected === 5 || dayOfWeekSelected === 6) {
            if (dayOfWeek !== 5 && dayOfWeek !== 6) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else if (this.dataReservationEdit.typeReservation === 'Rumba' && this.dataReservationEdit.vendorId === 72 && (selectedDayEdit !== 5 || selectedDayEdit !== 6)) {
          let dayOfWeek = parseInt(
            this.$moment(date)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          if (selectedDayEdit === dayOfWeek) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async getNext (date) {
      let info = {
        format: null,
        clean: null
      };
      let status = false;
      for (let index = 1; index <= 15; index++) {
        const fecha = this.$moment(date).add(index, 'days').format('YYYY-MM-DD');
        const clean = this.$moment(date).add(index, 'days').format('x');
        const format = this.$moment(date).add(index, 'days').format('DD MMM YYYY');
        const exists = this.blocked.find((i) => i.date === fecha);
        if (exists === undefined) {
          const available = this.availabilityCalendar[fecha];
          if (available && available.status === true) {
            if (available.minSize !== 0 && available.maxSize !== 0) {
              if (this.selectedPeople >= available.minSize && this.selectedPeople <= available.maxSize) {
                info.clean = clean;
                info.format = format;
                break;
              }
            }
          } else if (available === undefined) {
            if (status) {
              break;
            }
            try {
              // Esperamos a que la promesa del dispatch se resuelva
              const response = await this.$store.dispatch({
                type: 'reservation:availabilityCalendar',
                data: { vendorId: this.vendor.id, date: fecha, unique: 1 }
              });
              const avail = response[fecha];
              // Si el status cambia a true, ejecutamos la lógica
              if (avail && avail.status === true) {
                if (avail.minSize !== 0 && avail.maxSize !== 0) {
                  if (this.selectedPeople >= avail.minSize && this.selectedPeople <= avail.maxSize) {
                    info.clean = clean;
                    info.format = format;
                    // Asignamos el valor a status
                    status = avail.status;
                    break; // Salimos del bucle si encontramos disponibilidad
                  }
                }
              }
            } catch (error) {
              status = false;
            }
          }
        }
      }
      return info;
    },
    async onSelectedDate (date, next = 0) {
      this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      let blockedData = false;
      if (this.params.isActiveAvailabilityRange === 1) {
        const fecha = this.$moment(date).format('YYYY-MM-DD');
        blockedData = this.blocked.find((i) => i.date === fecha);
        if (blockedData === undefined) {
          const dayAvailable = this.availabilityCalendar[fecha];
          if (dayAvailable) {
            if (dayAvailable.status === false) {
              blockedData = {
                isWaitinglistBlocked: 0
              };
            } else if (dayAvailable.status === true) {
              if (parseInt(dayAvailable.minSize) !== 0 && parseInt(dayAvailable.maxSize) !== 0) {
                if (parseInt(this.selectedPeople) < parseInt(dayAvailable.minSize) || parseInt(this.selectedPeople) > parseInt(dayAvailable.maxSize)) {
                  blockedData = {
                    isWaitinglistBlocked: 0
                  };
                }
              } else {
                blockedData = {
                  isWaitinglistBlocked: 0
                };
              }
            }
          }
        }
      }
      if (blockedData && next === 0) {
        const f = await this.getNext(date);
        this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        const thes = this;
        thes.$buefy.modal.open({
          parent: thes,
          component: dateNotAvailable,
          hasModalCard: true,
          trapFocus: true,
          props: {
            datePrevius: thes.$moment(date).format('DD MMM YYYY'),
            dateNext: f.format,
            dateIn: parseInt(date),
            dateOut: parseInt(f.clean),
            info: blockedData
          },
          customClass: 'animationFull',
          events: {
            accept (d) {
              thes.onSelectedDate(d, 1);
            },
            nextAccept (d) {
              thes.onSelectedDate(d);
              const s = parseInt(thes.$moment(d).format('MM'));
              const c = parseInt(thes.$moment(date).format('MM'));
              const nD = thes.$moment(d).format('YYYY-MM-DD');
              if (c < 12) {
                if (s > c) {
                  const y = s - c;
                  // se reemplaza currentMonth por un estado global currentMonthGlobal
                  /* thes.currentMonth = thes.currentMonth + y; */
                  thes.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, thes.currentMonthGlobal + y);
                  thes.getAvailabilityMonth(nD);
                }
              } else {
                if (s < c) {
                  // se reemplaza currentMonth por un estado global currentMonthGlobal
                  /* thes.currentMonth++; */
                  thes.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, thes.currentMonthGlobal + 1);
                  thes.getAvailabilityMonth(nD);
                }
              }
            }
          }
        });
      } else {
        this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        this.dateStyle = date;
        const sameDate = (this.selectedDat === date) && true;
        this.$store.commit(eventTypes.mutations.setEventPrice, 0);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
        if (!this.params.isActiveSelectedZone) {
          let dayOfWeek = parseInt(
            this.$moment(date)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          this.$emit('closeAlerts', true);
          if (
            this.vendor.id === 72 &&
            this.selectedTypeReservation === 'Rumba' &&
            (dayOfWeek === 5 || dayOfWeek === 6) &&
            this.params.isMapped === 1 && !this.allEvents[date]
          ) {
            if (this.zone !== false && this.editPeople === true) {
              this.$store.commit(reservationTypes.mutations.setSelectedTable, this.dataReservationEdit.tableId);
              this.$store.commit(reservationTypes.mutations.setSelectedZone, this.zone);
              this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
              this.$emit('change-date', true);
              let response = this.setSelectedDates(date, this.now, this.allEvents);
              this.$emit('experiences', this.findExperience());

              // se utiliza el commit de la variable global isEventDay
              this.$parent.$emit('isEventDay', response.evento);
              this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, response.evento);

              this.selectedDat = date;
            } else {
              this.$emit('show-modal-select-zone-map', date);
            }
          } else {
            if (!this.params.listReservationType && this.vendor.id !== 170) {
              this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
            }
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
            this.$emit('change-date', true);
            let response = this.setSelectedDates(date, this.now, this.allEvents);
            this.$emit('experiences', this.findExperience());

            // se utiliza el commit de la variable global isEventDay
            this.$parent.$emit('isEventDay', response.evento);
            this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, response.evento);
            this.selectedDat = date;
          }
        } else {
          if (this.selectedZone !== false && this.editPeople === true && this.dataReservationEdit.balancePaid > 0) {
            this.$emit('closeAlerts', true);
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.$store.commit(reservationTypes.mutations.setSelectedZone, this.zone);
            this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
            this.$emit('change-date', true);
            let response = this.setSelectedDates(date, this.now, this.allEvents);
            this.$emit('experiences', this.findExperience());
            // se utiliza el commit de la variable global isEventDay
            this.$parent.$emit('isEventDay', response.evento);
            this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, response.evento);
          } else {
            this.$emit('show-modal-select-zone', date);
          }
        }
        if (sameDate) {
          this.changeHours();
        }
      }
    },
    anticipationTime (time) {
      if (this.getTimeAtentionWl != null) {
        const cena = (this.schedules || {}).cena || {};
        const pombo = (this.schedules || {}).pombo || {};
        const rumba = (this.schedules || {}).rumba || {};
        const brunch = (this.schedules || {}).brunch || {};
        let at = (this.params.anticipationTime >= 0) ? parseInt(this.params.anticipationTime) : 0;
        let dataHour = [];
        if (this.todayDate === time) {
          let actualHora = this.actualHora + at;
          let serverTime = this.serverTime;
          let dayOfWeek = this.$moment(actualHora).format('d');
          let extraFreeFlag = false;
          if (Object.keys(this.getTimeAtentionWl['extraFree']).length > 0) {
            if (this.getTimeAtentionWl['extraFree'][time] !== undefined) {
              let ultimo = this.getTimeAtentionWl['extraFree'][time].slice(
                -1
              )[0];
              let hora = ultimo.hora.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (
            Object.keys(this.getTimeAtentionWl['extraReservations']).length >
            0 &&
            !extraFreeFlag
          ) {
            if (
              this.getTimeAtentionWl['extraReservations'][time] !== undefined
            ) {
              let ultimo = this.getTimeAtentionWl['extraReservations'][time].slice(-1)[0];
              let hora = ultimo.hora.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Cena') {
            let aux = [];
            cena.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Pombo') {
            let aux = [];
            pombo.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Rumba') {
            let aux = [];
            rumba.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Brunch') {
            let aux = [];
            brunch.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (dataHour.length > 0 && extraFreeFlag) {
            dataHour = _.sortedUniq(dataHour);
            let ultimoHabil = dataHour.slice(-1)[0];
            let diferencia = ultimoHabil - actualHora;
            if (diferencia >= 0) {
              return false;
            } else {
              return true;
            }
          } else if (this.getTimeAtentionWl[dayOfWeek].length > 0) {
            let ultimoRegistro = this.getTimeAtentionWl[dayOfWeek][this.getTimeAtentionWl[dayOfWeek].length - 1];
            let horaUltimoRegistro = ultimoRegistro.hora.split(':');
            let horaCompletaUltimoRegistro = this.$moment(serverTime)
              .hours(horaUltimoRegistro[0])
              .minutes(horaUltimoRegistro[1])
              .seconds(0)
              .milliseconds(0)
              .format('x');
            let diferencia = horaCompletaUltimoRegistro - actualHora;
            if (diferencia >= 0) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getInSchedule (date) {
      const extraFree = (this.schedules || {}).extraFree || {};
      const extraReservations = (this.schedules || {}).extraReservations || {};
      const prePurchase = (this.schedules || {}).reservations || {};
      const free = (this.schedules || {}).free || {};
      const pombo = (this.schedules || {}).pombo || {};
      const rumba = (this.schedules || {}).rumba || {};
      const brunch = (this.schedules || {}).brunch || {};
      const cena = (this.schedules || {}).cena || {};
      if (this.selectedTypeReservation === 'Pombo') {
        const dayOfWeek = parseInt(this.$moment(date).format('d'));
        let aux = [];
        pombo.map(function (data) {
          if (data.dayOfWeek === dayOfWeek) {
            aux.push(data.dayOfWeek);
          }
        });
        aux = _.sortedUniq(aux);
        if (aux.indexOf(dayOfWeek) !== -1) {
          return 1;
        } else {
          return 0;
        }
      } else if (this.selectedTypeReservation === 'Brunch') {
        const dayOfWeek = parseInt(this.$moment(date).format('d'));
        let aux = [];
        brunch.map(function (data) {
          if (data.dayOfWeek === dayOfWeek) {
            aux.push(data.dayOfWeek);
          }
        });
        aux = _.sortedUniq(aux);
        if (aux.indexOf(dayOfWeek) !== -1) {
          return 1;
        } else {
          return 0;
        }
      } else if (this.selectedTypeReservation === 'Rumba') {
        const dayOfWeek = parseInt(this.$moment(date).format('d'));
        let aux = [];
        rumba.map(function (data) {
          if (data.dayOfWeek === dayOfWeek) {
            aux.push(data.dayOfWeek);
          }
        });
        aux = _.sortedUniq(aux);
        if (aux.indexOf(dayOfWeek) !== -1) {
          return 1;
        } else {
          return 0;
        }
      } else if (this.selectedTypeReservation === 'Cena') {
        const dayOfWeek = parseInt(this.$moment(date).format('d'));
        let aux = [];
        cena.map(function (data) {
          if (data.dayOfWeek === dayOfWeek) {
            aux.push(data.dayOfWeek);
          }
        });
        aux = _.sortedUniq(aux);
        if (aux.indexOf(dayOfWeek) !== -1) {
          return 1;
        } else {
          return 0;
        }
      } else {
        const dayOfWeek = parseInt(this.$moment(date).format('d'));
        let times = parseInt(this.$moment(date).format('x'));
        let aux = [];
        let isFree = 0;
        let isPurchase = 0;
        if (extraFree.length > 0) {
          extraFree.map(function (data) {
            if (data.dayOfWeek === dayOfWeek && parseInt(data.date) === times) {
              aux.push(data.dayOfWeek);
              isFree = 1;
            }
          });
        }
        if (extraReservations.length > 0) {
          extraReservations.map(function (data) {
            if (data.dayOfWeek === dayOfWeek && parseInt(data.date) === times) {
              aux.push(data.dayOfWeek);
              isPurchase = 1;
            }
          });
        }
        if (prePurchase.length > 0 && isPurchase === 0) {
          prePurchase.map(function (data) {
            if (data.dayOfWeek === dayOfWeek && !data.date) {
              aux.push(data.dayOfWeek);
            }
          });
        }
        if (free.length > 0 && isFree === 0) {
          free.map(function (data) {
            if (data.dayOfWeek === dayOfWeek && !data.date) {
              aux.push(data.dayOfWeek);
            }
          });
        }
        aux = _.sortedUniq(aux);
        if (aux.indexOf(dayOfWeek) !== -1) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    findExperience () {
      let experience = _.map(this.allEvents, item => {
        if (item.type === 'experience' && item.alwaysActive) return item;
      });
      if (experience[0] !== undefined) {
        return experience;
      } else {
        return false;
      }
    },
    isEventForDay (timestamps) {
      const have = this.allEvents[timestamps];
      if (have) {
        this.eventMonth = true;
        return true;
      }
      return false;
    },
    changeMonthsCalendar (type = '') {
      const limit = this.$moment().format('YYYY-MM-DD');
      if (type === 'next') {
        // se reemplaza currentMonth por un estado global currentMonthGlobal
        /* this.currentMonth++; */
        this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, this.currentMonthGlobal + 1);
        const firstOfThisMonth = new Date(this.currentYear, this.currentMonthGlobal + 1, 0, 0, 0, 0, 0);
        const mon = parseInt(firstOfThisMonth.getMonth() + 1);
        const dat = parseInt(this.$moment().format('DD'));
        const dateSearch = firstOfThisMonth.getFullYear() + '-' + (mon <= 9 ? '0' + mon : mon) + '-01';
        if (dateSearch >= limit) {
          this.getAvailabilityMonth(dateSearch, 0);
        } else if (mon === parseInt(this.$moment().format('MM'))) {
          const dateSearchTwo = firstOfThisMonth.getFullYear() + '-' + (mon <= 9 ? '0' + mon : mon) + '-' + (dat <= 9 ? '0' + dat : dat);
          this.getAvailabilityMonth(dateSearchTwo, 0);
        }
      } else {
        // se reemplaza currentMonth por un estado global currentMonthGlobal
        /* this.currentMonth--; */
        this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, this.currentMonthGlobal - 1);
        const firstOfThisMonth = new Date(this.currentYear, this.currentMonthGlobal + 1, 0, 0, 0, 0, 0);
        const mon = parseInt(firstOfThisMonth.getMonth() + 1);
        const dat = parseInt(this.$moment().format('DD'));
        const dateSearch = firstOfThisMonth.getFullYear() + '-' + (mon <= 9 ? '0' + mon : mon) + '-' + (dat <= 9 ? '0' + dat : dat);
        if (dateSearch >= limit) {
          if (mon !== parseInt(this.$moment().format('MM'))) {
            const dateSearchTwo = firstOfThisMonth.getFullYear() + '-' + (mon <= 9 ? '0' + mon : mon) + '-01';
            this.getAvailabilityMonth(dateSearchTwo, 0);
          } else {
            this.getAvailabilityMonth(dateSearch, 0);
          }
        }
      }
      if (this.actionCalendarChangeMonthsFromEvents && this.actionCalendarChangeMonthsFromEvents === true) {
        // se reemplaza currentMonth por un estado global currentMonthGlobal
        /* const actualDateChanged = this.$moment.tz(this.vendorTimezone).month(this.currentMonth).valueOf(); */
        const actualDateChanged = this.$moment.tz(this.vendorTimezone).month(this.currentMonthGlobal).valueOf();
        this.$emit('getEventsFromActualMonth', actualDateChanged);
        this.$emit('updateDateFromCalendarPrecompro', actualDateChanged);
      }
    },
    getAvailabilityMonth (fecha = null, unique = 0) {
      const thes = this;
      thes.dayBlock = false;
      if (fecha) {
        if (unique === 1) {
          thes.loadCalendar = true;
          thes.$store.dispatch({ type: 'reservation:availabilityCalendar', data: { vendorId: this.vendor.id, date: fecha, unique: 1 } }).then(response => {
            const info = JSON.parse(JSON.stringify(this.availabilityCalendar));
            if (fecha in info) {
              info[fecha] = response[fecha];
              thes.$store.commit(reservationTypes.mutations.setAvailabilityCalendar, info);
              thes.loadCalendar = false;
            }
            thes.loadCalendar = false;
          }).catch(() => {
            thes.loadCalendar = false;
          }).finally(() => {
            thes.loadCalendar = false;
          });
        } else {
          thes.loadCalendar = true;
          thes.$store.dispatch({ type: 'reservation:availabilityCalendar', data: { vendorId: this.vendor.id, date: fecha } }).catch(() => {
            thes.loadCalendar = false;
          }).finally(() => {
            thes.loadCalendar = false;
          });
        }
      } else {
        thes.loadCalendar = true;
        thes.$store.dispatch({ type: 'reservation:availabilityCalendar', data: { vendorId: this.vendor.id } }).catch(() => {
          thes.loadCalendar = false;
        }).finally(() => {
          thes.loadCalendar = false;
        });
      }
    }
  },
  sockets: {
    newBlockedDay: function (data) {
      const thes = this;
      if ((parseInt(data.vendorId) === parseInt(thes.vendor.id))) {
        if (thes.params.isActiveAvailabilityRange === 1) {
          if (thes.selectedDate !== null) {
            thes.$store.commit(reservationTypes.mutations.setSelectedDate, false);
          }
          if (data.blockedSelect) {
            thes.$store.commit(reservationTypes.mutations.setBlocked, data.blockedSelect);
          }
          thes.getAvailabilityMonth(data.fecha, 1);
        }
      }
    },
    newUnBlockedDay: function (data) {
      const thes = this;
      if ((parseInt(data.vendorId) === parseInt(thes.vendor.id))) {
        if (thes.params.isActiveAvailabilityRange === 1) {
          if (thes.selectedDate !== null) {
            thes.$store.commit(reservationTypes.mutations.setSelectedDate, false);
          }
          if (data.blockedSelect) {
            thes.$store.commit(reservationTypes.mutations.setBlocked, data.blockedSelect);
          }
          thes.getAvailabilityMonth(data.fecha, 1);
        }
      }
    },
    newBlockedHour: function (data) {
      const thes = this;
      if ((parseInt(data.vendorId) === parseInt(thes.vendor.id))) {
        if (thes.params.isActiveAvailabilityRange === 1) {
          if (data.blockedSelect) {
            thes.$store.commit(reservationTypes.mutations.setBlocked, data.blockedSelect);
          }
          thes.getAvailabilityMonth(data.fecha, 1);
        }
      }
    },
    newUnBlockedHour: function (data) {
      const thes = this;
      if ((parseInt(data.vendorId) === parseInt(thes.vendor.id))) {
        if (thes.params.isActiveAvailabilityRange === 1) {
          if (data.blockedSelect) {
            thes.$store.commit(reservationTypes.mutations.setBlocked, data.blockedSelect);
          }
          thes.getAvailabilityMonth(data.fecha, 1);
        }
      }
    }
  },
  watch: {
    selectedDate () {
      this.$emit('error', {
        errorTimeOut: false
      });
      if (this.selectedDate) {
        this.selectedDat = this.selectedDate;
        const timeSelect = this.$moment(this.selectedDate).format('YYYY-MM-DD');
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
        this.$store
          .dispatch({
            type: 'whiteLabel:avalibilityHour',
            data: {
              timestamp: timeSelect,
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              people: this.selectedPeople,
              zone: this.selectedZone,
              typeReservation: this.selectedTypeReservation,
              tableId: this.selectedTable
            }
          })
          .then(() => {
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          }).catch((e) => {
            let errorTimeOut = false;
            if (e.type === 'timeOut') {
              errorTimeOut = true;
            }
            this.$emit('error', {
              errorTimeOut
            });
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          });
        if (this.vendor.id === 334) {
          if (this.$moment(this.selectedDate).format('YYYY-MM-DD') === '2022-12-24') {
            this.$emit('show-modal-tramonti', true);
          }
        }
        if (this.user.id !== 13744) {
          this.$store.dispatch({
            type: 'reservation:reservationUserDate',
            data: {
              userId: this.user.id,
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              timestamp: this.$moment(this.selectedDate).format('YYYY-MM-DD')
            }
          });
        }
        if (this.selectedTypeReservation === 'Cena' || this.selectedTypeReservation === 'Rumba') {
          let valid = false;
          if (this.selectedDate) {
            const day = parseInt(this.$moment(this.selectedDate).format('d'));
            if (day === 5 || day === 6) {
              valid = true;
            }
          }
          this.$emit('show-modal-gaira-message', valid);
        }
      }
    },
    showMoreDates () {
      this.$emit('moreDates', this.showMoreDates);
    },
    dateEventPicker () {
      const actualDateChanged = this.$moment.tz(this.dateEventPicker, this.vendorTimezone).month();
      // se reemplaza currentMonth por un estado global currentMonthGlobal
      /* this.currentMonth = actualDateChanged; */
      this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, actualDateChanged);
    },
    availabilityCalendar () {
      this.loadCalendar = false;
    }
  }
};
</script>
<style lang="scss">
.legend-date-status{
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  color: var(--bgColorCheckout);

  &.mt-10{
    margin-top: 10px;
  }

  .legend-date-current, .legend-date-new{
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 5px;
  }

  .legend-date-current{
    border: 1px solid var(--colorCheckout);
  }

  .legend-date-new{
    border: 1px solid var(--colorCheckout);
    background-color: var(--bgColorCheckout);
    margin-left: 10px;
  }
}
.date-options-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 12px;
  .subtitle-date, .text-date{
    color: var(--color1);
    font-size: 14px;
    line-height: 1.2;
  }
  .subtitle-date{
    font-weight: 600;
  }
  .date-option {
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 40px;
    justify-content: center;
    width: 230px;
    &-text{
      font-size: 14px;
      color: var(--color1);
      line-height: 1;
      text-transform: capitalize;
    }
    &-edit {
      background-color: var(--colorCheckout);
      border-color: var(--bgColorCheckout);
      p, strong {
        color: var(--bgColorCheckout);
      }
    }
  }
  .daySelectedSug {
    background-color: var(--bgColorCheckout) !important;
    p, strong {
      color: var(--colorCheckout) !important;
      background-color: var(--bgColorCheckout) !important;
    }
  }
}
.more-dates{
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--bgColorCheckout);
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
  padding: 6px;
  width: 100%;
  color: var(--bgColorCheckout);
  font-size: 14px;

  .icon_calendar{
    width: 22px;
    height: 22px;
    background-color: var(--bgColorCheckout) !important;
    margin-top: 1px;
  }
}
.arrows {
  font-size: 22px;
}
#calendar .cell {
  color: var(--color1);
  border-right: 1px solid var(--calendarColor);
}
.daySelected {
  color: var(--color2) !important;
  background-color: var(--color1) !important;
}
.date:hover {
  color: var(--color2) !important;
  background: var(--color1);
}
.monthName {
  align-items: center;
  justify-content: center;
  display: flex;
}
.icon-event {
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 4px;
}
.dayNotAvailableInEventsView{
  cursor: none;
  .day{
    opacity: 1!important;
  }
  &.currentDay{
    background: #9D9D9D;
    color: white!important;
    border-radius: 50%;
  }
}
.blockDay:not(.dayNotAvailableInEventsView) {
  background:rgba(181, 179, 179, 0.34) !important;
  user-select: none;
  &:hover {
    color: #FFF!important;
  }
  .day {
    opacity: 0.3;
    font-size: 12px;
    font-weight: 400;
  }
}
.dayNotAvailable:not(.dayNotAvailableInEventsView) {
  background:rgba(181, 179, 179, 0.34) !important;
  user-select: none;
  &:hover {
    color: #FFF!important;
  }
  .day {
    opacity: 0.3;
    font-size: 12px;
    font-weight: 400;
  }
}
.header-row {
  margin: 0 -1px;
  border-bottom: 1px solid var(--calendarColor);
}
.white--text {
  color: #fff !important;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.center {
  text-align: center;
}
.z5 {
  z-index: 5;
}
.padding-half {
  padding: 8px;
  padding: 0.5rem;
}
.cell {
  color: black;
}
.dayDisabled {
  color: black;
  pointer-events: none;
  opacity: 1;
  .day {
    opacity: 0.5;
  }
}
.daySelect {
  font-family: "Source Sans Pro";
  font-size: 16px !important;
  font-weight: 700;
  color: white;
}
.calendar {
  background: transparent;
  color: var(--calendarColor);
  border: 1px solid var(--calendarColor);
  border-bottom: none;
  border-right: none;
  .header {
    color: var(--calendarColor);
    border-bottom: 1px solid var(--calendarColor);
  }
}
.item-calendar {
  color: var(--calendarColor);
}
.editIconCalendar {
  color: var(--bgColorCheckout);
}
.editBorderCalendar {
  border-color: var(--bgColorCheckout) !important;
  color: var(--bgColorCheckout) !important;
  .date:hover {
    color: var(--colorCheckout) !important;
    background-color: var(--bgColorCheckout);
  }
}
.editDaySelected {
  color: var(--colorCheckout) !important;
  background-color: var(--bgColorCheckout) !important;
  &:hover {
    color: var(--colorCheckout) !important;
    background-color: var(--bgColorCheckout) !important;
  }
}
.wrapper {
  overflow: hidden;
  border-radius: 1rem;
}
.wrapper.disabled {
  border: 0;
  background: #eee;
}
.separator {
  width: 4rem;
  height: 1px;
}
.dot2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  width: 20px;
  height: 28px;
}
.dot-text2 {
  position: absolute;
  top: -12px;
  right: -8px;
  z-index: 10;
  font-weight: bold;
  font-size: 12px;
  font-family: "Source Sans Pro";
}
.cell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  background: transparent;
  transition: all 0.3s;
}
.cell.header {
  height: 28px;
  border: 0;
  margin: 0 -1px;
}
.cell.blocked {
  background: #fcd9df !important;
  background-color: #ffcdd2 !important;
  color: #fff !important;
}
.cell.blocked.selected {
  background-color: #f44466 !important;
  background: #f44336 !important;
}
.cell.available {
  transition: all 0.3s;
}

.cell.available.blocked:hover {
  background: #f44336 !important;
}
.no-uppercase {
  text-transform: none;
}
.md-caption.day {
  font-size: 14px;
  font-weight: 600;
}
@media (min-width: 1402px) {
  .dot-text2 {
    position: absolute !important;
    top: -14px !important;
    right: -9px !important;
    z-index: 10 !important;
    font-weight: bold !important;
    font-size: 11px !important;
    font-family: "Source Sans Pro" !important;
  }
}
@media (max-width: 1401px) {
  .dot-text2 {
    position: absolute !important;
    top: -13px !important;
    right: -10px !important;
    z-index: 10 !important;
    font-weight: bold !important;
    font-size: 11px !important;
    font-family: "Source Sans Pro" !important;
  }
  .header {
    display: flex;
  }
  .header-row {
    margin: 0 -1px;
    display: flex;
  }
}
.info-footer-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  &-start {
    color: var(--cardTitleColor);
    font-family: 'Source Sans Pro';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &-avail {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-logo {
      margin-right: 4px;
      width: 10px;
      height: 10px;
      flex-shrink: 0;
      border-radius: 2px;
      border: 1px solid var(--calendarColor);
      background: var(--cardBackgroundColor);
    }
    &-text {
      color: var(--bgColorCheckout);
      font-family: 'Source Sans Pro';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &-not-avail {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    &-logo {
      margin-right: 4px;
      width: 10px;
      height: 10px;
      flex-shrink: 0;
      border-radius: 2px;
      border: 1px solid #838383;
      background: rgba(181, 179, 179, 0.34) !important;
    }
    &-text {
      color: var(--bgColorCheckout);
      font-family: 'Source Sans Pro';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.colorEvent {
  color: var(--calendarColor);
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-right: 5px;
  }
}
@media (min-width: 200px) and (max-width: 768px) {
  .md-caption.day {
    &.daySelect {
      width: 100%;
      height: 100%;
    }
  }
}
.animationFull {
  .animation-content {
    max-width: 960px !important;
  }
}

.snipperDateTime {
  opacity: 0.5;
  width: 60px;
  margin: 80px auto;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
