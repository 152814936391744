import namespace from '@/helpers/namespace';

export default namespace('reservation', {
  getters: [
    'listReservationTypes',
    'groupUrlVendor',
    'groupVendor',
    'blocked',
    'availabilityCalendar',
    'availability',
    'reservations',
    'schedules',
    'selectedAdult',
    'selectedBoy',
    'dataReservationEdit',
    'minimunPeoples',
    'sendReservation',
    'selectedBaby',
    'selectedDate',
    'selectedHour',
    'selectedHourAction',
    'selectedTable',
    'selectedPeople',
    'decorationCosto',
    'getExperienceCost',
    'getTip',
    'reservationsUser',
    'reservationsUserLink',
    'cancelarReservation',
    'reservationDayUser',
    'reservationsList',
    'reservationsExecuted',
    'selectedTypeReservation',
    'times',
    'selectedZone',
    'editReservation'
  ],
  actions: [
    'blocked',
    'availabilityCalendar',
    'availability',
    'reservations',
    'reservationNew',
    'schedules',
    'times',
    'setUserConfirmReservation',
    'preReservation',
    'reservationsUser',
    'updatePreReservation',
    'updateReservation',
    'deletePreReservation',
    'cancelarReservation',
    'cancelarReservationEmail',
    'reservationUserDate',
    'confirmReservation',
    'confirmGiftCard',
    'reservationsList',
    'reservationDetailById',
    'getMaxPeople',
    'addGiftCard',
    'companionUpdate',
    'getGiftCardParams',
    'validateGiftCard',
    'companionList',
    'companions',
    'companionDelete'
  ],
  mutations: [
    'setGroupUrlVendor',
    'setGroupVendor',
    'setSelectedAdult',
    'setSelectedBoy',
    'setSelectedBaby',
    'setSelectedPeople',
    'setSelectedTable',
    'setSelectedHour',
    'setSelectedHourAction',
    'setSelectedZone',
    'setMinimunPeoples',
    'setSendReservation',
    'setSelectedTypeReservation',
    'setLoadingReservation',
    'setBlocked',
    'setAvailabilityCalendar',
    'setSelectedMonth',
    'setTimes',
    'setSelectedDate',
    'setSchedules',
    'setDecorationCosto',
    'setExperienceCost',
    'setEditReservation',
    'setTip',
    'setDataReservationEdit',
    'setReservationTypes',
    'setReservationsUser',
    'setReservationsUserLink',
    'setCancelarReservation',
    'setReservationDayUser',
    'setReservationsExecuted',
    'setReservationsList'
  ]
});
