<template>
  <div id="cancelarReservationModal" v-if="cancelarReservations">
    <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></b-loading>
    <div class="content_card">
      <div class="cont_icon">
        <i class="icons icon_exclamation"></i>
      </div>
      <div class="text_modal ffr fw500" v-if="cancelarReservations.balancePaid > 0">
        ¿Estas seguro que deseas
        <br />cancelar tu precompra?
      </div>
      <div class="text_modal ffr fw500" v-else>
        ¿Estas seguro que deseas
        <br />cancelar tu reserva?
      </div>
      <div class="cont_res">
        <div class="cont_text_reservation">
          <div class="cont_icon">
            <i class="icons icon_reservations"></i>
          </div>
          <span
            class="text_card ffr fw500"
            v-if="cancelarReservations.balancePaid > 0"
          >Información de tu precompra</span>
          <span class="text_card ffr fw500" v-else>Información de tu reserva</span>
        </div>
        <div
          :class="{'precompra' : cancelarReservations.balancePaid > 0}"
          class="info_reserve columns"
        >
          <div class="main_info column is-3 is-2-mobile p-0">
            <div class="cont_icon">
              <i class="icons icon_people"></i>
            </div>
            <span class="text_res people_res ffr fw500">{{ cancelarReservations.people }}</span>
          </div>
          <div class="main_info column is-5 p-0">
            <div class="cont_icon">
              <i class="icons icon_calendar"></i>
            </div>
            <span
              class="text_res date_res ffr fw500"
            >{{ parseInt(cancelarReservations.date) | moment('timezone', vendorTimezone, 'MMMM DD') }}</span>
          </div>
          <div class="main_info column is-4 is-5-mobile p-0">
            <div class="cont_icon">
              <i class="icons icon_clock"></i>
            </div>
            <span
              class="text_res hour_res ffr fw500"
            >{{ parseInt(cancelarReservations.date) | moment('timezone', vendorTimezone, 'hh:mm a') }}</span>
          </div>
        </div>
        <div class="cont_img_info"></div>
        <div :class="{'precompra' : cancelarReservations.balancePaid > 0}" class="cont_footer">
          <span
            class="text_email ffr fw400"
          >Recibirás un correo con todos los detalles de la cancelación.</span>
          <div class="cont_check" v-if="cancelarReservations.balancePaid > 0">
            <b-checkbox
              class="check_terms ffr fw500"
              v-model="condition"
            >Aceptar términos y condiciones de cancelación aquí.</b-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="cont_btns">
      <button @click="boolDialog" class="btn_modal_cancel close_cancel ffr fw500">No</button>
      <button
        :class="{ 'disabled': !condition }"
        :disabled="!condition"
        :loading="modified"
        @click="cancelarReservation"
        class="btn_modal_cancel ffr fw500"
      >Si</button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import authTypes from '@/store/types/auth';

export default {
  name: 'cancelReserva',
  props: ['type'],
  data () {
    return {
      condition: false,
      modified: false,
      isImageModalActive: false,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      user: authTypes.getters.user,
      cancelarReservations: reservationTypes.getters.cancelarReservation,
      selectedPeople: reservationTypes.getters.selectedPeople,
      selectedDate: reservationTypes.getters.selectedDate,
      selectedHour: reservationTypes.getters.selectedHour,
      showCancelar: whiteLabelTypes.getters.showCancelar
    })
  },
  mounted () {
    if (this.cancelarReservations.balancePaid <= 0) {
      this.condition = true;
    }
  },
  methods: {
    boolDialog () {
      this.modified = false;
      this.condition = false;
      this.$store.commit(
        reservationTypes.mutations.setCancelarReservation,
        null
      );
      this.$parent.close();
    },
    cancelarReservation () {
      this.isLoading = true;
      let info = this.cancelarReservations;
      this.modified = true;
      if (this.type === 'email') {
        if (info && info.id) {
          this.$store
            .dispatch({ type: 'reservation:cancelarReservationEmail', data: info })
            .then(({ data }) => {
              if (parseInt(data.code) === 200) {
                this.isLoading = false;
                this.$emit('cancel');
                this.boolDialog();
                this.$notify({
                  group: 'notifyAccount',
                  title: 'Operación Exitosa',
                  text: 'Se cancelo la reserva',
                  type: 'success'
                });
                this.$store.dispatch({
                  type: 'reservation:reservationsList',
                  data: {
                    userId: this.user.id,
                    vendorId: this.$store.getters['whiteLabel:vendorId']
                  }
                });
              }
            });
        }
      } else {
        this.$store
          .dispatch({ type: 'reservation:cancelarReservation', data: info })
          .then(({ data }) => {
            if (parseInt(data.code) === 200) {
              this.isLoading = false;
              this.boolDialog();
              this.$notify({
                group: 'notifyAccount',
                title: 'Operación Exitosa',
                text: 'Se cancelo la reserva',
                type: 'success'
              });
              this.$store.dispatch({
                type: 'reservation:reservationsList',
                data: {
                  userId: this.user.id,
                  vendorId: this.$store.getters['whiteLabel:vendorId']
                }
              });
            }
          });
      }
      this.$emit('reservation-select', true);
    }
  },
  watch: {
    showCancelar () {
      if (this.isImageModalActive) {
        this.boolDialog();
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Reservation/cancelarReservationModal.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
