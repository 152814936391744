|<template>
  <b-modal :active.sync="value" :can-cancel="false" id="modal-show-action">
    <button
      @click="close()"
      class="btn-close-modal-plate"
      type="button"
      v-if="(params.activateWaitingList || params.saleCrusade) && !error"
    >
      <i class="icon-mks icon_alert_cl" style="height: 12px; width: 12px;"></i>
    </button>
    <div class="modals-bosy">
      <div class="center-icon" v-if="!loading || error">
        <i class="icon-mks icon_alert_red" style="height: 47px; width: 47px;"></i>
      </div>
      <div
        style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
        v-if="!loading || error"
      >
        <div
          class="text-waitinglist"
          v-if="(params.activateWaitingList || params.saleCrusade) && (vendor.id !== 784 && vendor.id !== 786) && !error"
        >
          <!--cuando hay espera o cruzada y esta off la hora-->
          {{ $t('modalInformationAction.text1') }}
        </div>
        <div
          class="text-waitinglist"
          v-if="(params.activateWaitingList || params.saleCrusade) && (vendor.id === 784 || vendor.id === 786) && !error"
        >
          <!--cuando hay espera o cruzada y esta off la hora-->
          En este momento, todas nuestras mesas tienen dueño, pero igual te esperamos!
        </div>
        <div
          class="text-waitinglist"
          v-if="!params.activateWaitingList && !params.saleCrusade || error"
        >
          <!--cuando no hay espera ni cruzada y esta off la hora-->
          {{ $t('modalInformationAction.text2') }}
        </div>
        <div
          class="sub-text-waitinglist"
          style="margin-top: 15px;"
          v-if="!params.activateWaitingList && !params.saleCrusade || error"
        >
          <!--cuando no hay espera ni cruzada y esta off la hora-->
          {{ $t('modalInformationAction.text3') }}
        </div>
        <div
          class="sub-text-waitinglist"
          style="margin-top: 15px;"
          v-if="params.activateWaitingList && !params.saleCrusade && !error && !blockedList && (vendor.id !== 784 && vendor.id !== 786)"
        >
          <!--si hay lista y no hay cruzada-->
          {{ $t('modalInformationAction.text4') }}
        </div>
        <div
          class="sub-text-waitinglist"
          style="margin-top: 15px;"
          v-if="!params.activateWaitingList && params.saleCrusade && (vendor.id !== 784 && vendor.id !== 786) && !error"
        >
          <!--si hay cruzada y no hay lista-->
          {{ $t('modalInformationAction.text5') }}
        </div>
        <div
          class="sub-text-waitinglist"
          style="margin-top: 15px;"
          v-if="!params.activateWaitingList && params.saleCrusade && (vendor.id === 784 || vendor.id === 786) && !error"
        >
          <!--si hay cruzada y no hay lista-->
          Dejar un Anuncio de visita o Reservar en otra de nuestras Casas
        </div>
        <div
          class="sub-text-waitinglist"
          style="margin-top: 15px;"
          v-if="params.activateWaitingList && params.saleCrusade && (vendor.id !== 784 && vendor.id !== 786) && !error"
        >
          <!--si hay cruzada y si hay lista-->
          {{ $t('modalInformationAction.text6') }}
        </div>
        <div
          class="sub-text-waitinglist"
          style="margin-top: 15px;"
          v-if="params.activateWaitingList && params.saleCrusade && (vendor.id === 784 || vendor.id === 786) && !error"
        >
          <!--si hay cruzada y si hay lista-->
          ¿Qué quieres hacer?
        </div>
        <div class="bottom-modal-show-action" style="margin-top: 20px; margin-right: 5px">
          <div
            @click.prevent="close()"
            class="button-whitelist"
            style="padding: 6px 24px;"
            v-if="!params.activateWaitingList && !params.saleCrusade || error"
          >
            <!--cuando no hay espera ni cruzada y esta off la hora-->
            {{ $t('modalInformationAction.text7') }}
          </div>
          <div
            @click.prevent="showList()"
            class="button-whitelist right"
            v-if="params.activateWaitingList && !error && !blockedList && (vendor.id !== 784 && vendor.id !== 786)"
          >{{ $t('modalInformationAction.text8') }}</div>
          <div
            @click.prevent="showList()"
            class="button-whitelist right"
            v-if="params.activateWaitingList && !error && !blockedList && (vendor.id === 784 || vendor.id === 786)"
          >Dejar un Anuncio de visita</div>
          <div
            @click.prevent="showCrusale()"
            class="button-whitelist left"
            v-if="params.saleCrusade && !error && (vendor.id !== 784 && vendor.id !== 786)"
          >{{ $t('modalInformationAction.text9') }}</div>
          <div
            @click.prevent="showCrusale()"
            class="button-whitelist left"
            v-if="params.saleCrusade && !error && (vendor.id === 784 || vendor.id === 786)"
          >Reservar en otra de nuestras Casas</div>
        </div>
      </div>
      <transition name="fade" v-if="loading && !error">
        <div style="display: flex; justify-content: center; align-items: center;">
          <div
            style="display: flex; justify-content: center; align-items: center; flex-direction: column; min-height: 187px; text-align: center; line-height: 20px;"
          >
            <div class="icon-mks icon_reservations loading rotating"></div>
            <span
              style="color: #897454; font-family: sourceSansPro; font-weight: 500;"
            >{{ $t('selectView.information.text1') }}</span>
          </div>
        </div>
      </transition>
    </div>
  </b-modal>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import { mapActions } from 'vuex';
export default {
  props: ['value', 'blockedList'],
  data () {
    return {
      loading: false,
      error: false
    };
  },
  methods: {
    ...mapActions({
      getAvalibilityGroup: whiteLabelTypes.actions.getAvalibilityGroup
    }),
    close () {
      this.$emit('closeShowAction', false);
    },
    showList () {
      this.$emit('closeShowAction', false);
      this.$store.commit(whiteLabelTypes.mutations.setPopupWaitingList, true);
    },
    showCrusale () {
      this.loading = true;
      let datos = {
        vendorId: this.vendorId,
        people: this.selectedPeople,
        datetime: this.selectedHourAction,
        date: this.selectedDate
      };
      this.$store.commit(whiteLabelTypes.mutations.setAvalabilityGroup, false);
      this.getAvalibilityGroup(datos).then(data => {
        if (Object.keys(data).length > 0) {
          this.loading = false;
          this.$emit('closeShowAction', false);
        } else {
          this.loading = false;
          this.error = true;
        }
      }).catch(() => {
        this.loading = false;
        this.error = true;
      });
    }
  }
};
</script>
<style lang="scss">
#modal-show-action {
  .button-whitelist {
    padding: 6px 12px;
    outline: unset;
    border-radius: 10px;
    border-color: #897454;
    font-size: 12px;
    color: #897454;
    font-weight: bold;
    background-color: white;
    border: 1px solid #897454;
    text-align: center;
    cursor: pointer;
    &.left {
      margin-left: 5px;
    }
    &.right {
      margin-right: 5px;
    }
  }
  .animation-content.modal-content {
    max-width: 575px !important;
  }
  .bottom-modal-show-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .center-icon {
    display: flex;
    justify-content: center;
    padding: 0px 0px 22px 0px;
  }
  .text-waitinglist {
    display: flex;
    text-align: center;
    align-content: center;
    justify-content: center;
    line-height: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #897454;
  }
  .sub-text-waitinglist {
    display: flex;
    text-align: center;
    align-content: center;
    font-size: 16px;
    font-weight: 400;
    color: #897454;
  }
  .btn-close-modal-plate {
    float: right;
    position: absolute;
    right: 10px;
    top: 20px;
    border: 0;
    background: transparent;
    cursor: pointer;
  }
  .loading {
    width: 100px;
    height: 100px;
    background: rgba(67, 74, 86, 0.8);
    margin-bottom: 40px;
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}
@media (max-width: 468px) {
  .text-waitinglist {
    font-size: 16px !important;
  }
  .bottom-modal-show-action {
    flex-direction: column !important;
  }
  .right {
    margin-right: unset !important;
    text-align: center;
    margin-bottom: 10px;
  }
  .left {
    margin-left: unset !important;
    text-align: center;
  }
}
@media (max-width: 518px) {
  .bottom-modal-show-action {
    flex-direction: column !important;
  }
  .right {
    margin-right: unset !important;
    margin-bottom: 10px;
    text-align: center;
  }
  .left {
    margin-left: unset !important;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .modals-bosy {
    padding: 30px 40px 40px !important;
  }
  .text-waitinglist {
    text-align: center !important;
  }
}
</style>
