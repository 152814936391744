<template>
  <footer
    :style="{ 'background': rgbaBackground(design.footerBackgroundColor), 'opacity' : 1 }"
    class="footer"
    v-if="vendor && design && params"
  >
    <div class="container overflow-h">
      <div class="padding-button">
        <button
          :disabled="(selectedHour === null) || (selectedHour !== null && sendReservation) || disabledOnLog"
          :loading="sendReservation"
          @click="processReservation"
          class="btn-siguiente"
          id="reservationButton"
        >{{ (disabledOnLog) ? $t('loading')+'...' : (selectedHour) ? $t('continueUpper') : $t('completeData') }}</button>
      </div>
      <div
        :class="{'social' : vendor.facebook || vendor.instagram || vendor.twitter || vendor.whatsapp}"
        class="build-bottom columns"
      >
        <div
          id="socialRedesFooter"
          v-if="vendor.facebook || vendor.instagram || vendor.twitter || vendor.whatsapp"
        >
          <i @click="openSocial('facebook')" class="icon_facebook" v-if="vendor.facebook"></i>
          <i @click="openSocial('twitter')" class="icon_twitter" v-if="vendor.twitter"></i>
          <i @click="openSocial('instagram')" class="icon_instagram" v-if="vendor.instagram"></i>
          <i @click="openSocial('whatsapp')" class="icon_whatsapp" v-if="vendor.whatsapp"></i>
        </div>
        <div class="column">
          <p class="info-footer">
            <i class="fa fa-phone"></i>
            {{ vendor.phone }} |
            <i class="fa fa-envelope-square"></i>
            {{ vendor.email }}
            <br />
            <i class="fa fa-map-marker"></i>
            {{ vendor.address1 }}
            <span v-if="vendor.address2">{{ vendor.address2 }}</span>
            <br />
            {{ vendor.city.name+', '+vendor.country }}
          </p>
        </div>
        <div class="column footer-logo">
          BUILT BY |
          <i class="icon-mks icon-logo-precompro"></i>
          {{versionApp}}
        </div>
        <button
          :disabled="validateDisabled() === 1 || disabledOnLog"
          @click="next"
          class="btn-siguiente styleButton"
          id="siguienteButton"
        >{{ (disabledOnLog) ? $t('loading')+'...' : (selectedHour) ? $t('next') : $t('continueUpper') }}</button>
      </div>
    </div>

    <div app-data="true" id="Horario no disponible" max-width="20rem" v-if="dialog">
      <div :style="{ backgroundColor: '#fff' }" class="padding">
        <div>
          <iconInfo fill="#00ADC6" width="25em"></iconInfo>
        </div>
        <div>
          <span :style="{color: '#000'}">{{ $t('timeNoAvailable') }}</span>
        </div>
        <div>
          <button @click="dialog = false" data-dismiss="modal" type="button">{{ $t('agree') }}</button>
        </div>
      </div>
    </div>
    <b-loading :active="loader" :can-cancel="false" :is-full-page="isFullPage"></b-loading>
  </footer>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';
export default {
  data () {
    return {
      dialog: false,
      sendReserva: false,
      isFullPage: true
    };
  },
  props: ['value'],
  mounted () {
    this.validateDisabled();
  },
  methods: {
    rgbaBackground (color) {
      if (color === null) {
        return 'transparent !important';
      }
      if (color.length > 6) {
        return `${color} !important`;
      }
      if (color.length <= 4) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        color = color.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });
      }
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      const rgb = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
      return `rgba(${rgb['r']}, ${rgb['g']}, ${rgb['b']}, 0.7) !important`;
    },
    setKeyEventsAndroid () {
      setTimeout(() => {
        const ele = document.getElementById('emailTest1');
        const ele2 = document.getElementById('emailTest2');
        const ele3 = document.getElementById('emailTest3');
        if (ele != null) {
          ele.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
        if (ele2 != null) {
          ele2.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
        if (ele3 != null) {
          ele3.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
      }, 800);
    },
    processReservation () {
      this.$store.commit(whiteLabelTypes.mutations.setDisabledOnLog, true);
      this.setKeyEventsAndroid();
      if (this.logged) {
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      }
      let dataBrowser = this.userAgentBrowser().parsedResult;
      let referrer = 'whitelabel';
      let provider = 'precompro';
      let source = this.$route.query;
      let zoneBoyCost = null;
      if (Object.keys(source).length > 0) {
        if (source.source) {
          referrer = source.source;
        }
        if (source.provider) {
          provider = source.provider;
        }
      }
      let date = this.$moment(this.selectedDate).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSendReservation, true);
      let details = {};
      let payWindow = window.localStorage.getItem('payReservations');
      let payWindowBoy = window.localStorage.getItem('payReservationBoys');
      let payWindowFixed = window.localStorage.getItem('payReservationsFixed');
      let balancePaid = 0;
      if ((payWindowFixed !== undefined && payWindowFixed !== null)) {
        balancePaid = parseInt(payWindowFixed);
      } else if ((payWindow !== undefined && payWindow !== null)) {
        if (this.params.activeSelectorBoy) {
          if (payWindowBoy !== undefined && payWindowBoy !== null) {
            if (payWindowBoy > 0) {
              balancePaid = this.selectedBoy * parseInt(payWindowBoy);
              details = {
                adult: parseInt(payWindow),
                boy: parseInt(payWindowBoy)
              };
            }
          }
          if (payWindow > 0 && (payWindowBoy === undefined || payWindowBoy === null)) {
            balancePaid = this.selectedPeople * parseInt(payWindow);
          }
          if (payWindow > 0 && payWindowBoy > 0) {
            balancePaid = balancePaid + (this.selectedAdult * parseInt(payWindow));
          }
        } else {
          if (payWindow > 0) {
            balancePaid = this.selectedPeople * parseInt(payWindow);
          }
        }
      }
      /** GAIRA CONFIG */
      if (this.selectedTypeReservation === 'Pombo') {
        details = {
          adult: parseInt(this.showSchedules('pombo', this.selectedDate, 'purchaseAdult')),
          boy: parseInt(this.showSchedules('pombo', this.selectedDate, 'purchaseBoy'))
        };
      }
      if (this.selectedTypeReservation === 'Brunch') {
        details = {
          cover: parseInt(this.showSchedules('brunch', this.selectedDate, 'cover'))
        };
      }
      if (this.selectedTypeReservation === 'Rumba') {
        details = {
          adult: parseInt(this.showSchedules('rumba', this.selectedDate, 'purchaseAdult')),
          cover: parseInt(this.showSchedules('rumba', this.selectedDate, 'cover')),
          cost: 0
        };
      }
      if (this.selectedTypeReservation === 'Cena') {
        details = {
          adult: parseInt(this.showSchedules('cena', this.selectedDate, 'purchaseAdult')),
          cover: parseInt(this.showSchedules('cena', this.selectedDate, 'cover'))
        };
      }
      /** LA JUGADA, LA MOVIDA, SIBARITAS CONFIG */
      /** revisar activeZoneCostPeople en estos id 150,149,118 */
      if (this.params.activeZoneCostPeople === 1) {
        const zone = _.find(this.zones, { id: this.selectedZone });
        if (zone !== undefined && parseInt(zone.costBoy) > 0) {
          balancePaid = (this.selectedBoy * parseInt(zone.costBoy)) + (this.selectedAdult * parseInt(zone.cost));
          zoneBoyCost = parseInt(zone.costBoy);
          details = {
            costZone: parseInt(zone.cost),
            costBoy: parseInt(zone.costBoy)
          };
        } else if (zone !== undefined && parseInt(zone.cost) > 0) {
          balancePaid = zone.cost * this.selectedPeople;
          details = {
            costZone: parseInt(zone.cost)
          };
        } else {
          balancePaid = null;
        }
        if (balancePaid > 0) {
          this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
        } else {
          this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
        }
      }
      /** MAREA CONFIG */
      if (this.vendor.id === 231) {
        let costAdult = 0;
        let costBoy = 0;
        let dataPrice = _.find(this.avalibilityHour, { date: this.selectedHour });
        if (dataPrice.costAdult > 0) {
          costAdult = dataPrice.costAdult;
        }
        if (dataPrice.costBoy > 0) {
          costBoy = dataPrice.costBoy;
        }
        balancePaid = (parseInt(this.selectedBoy) * parseInt(costBoy)) + (parseInt(this.selectedAdult) * parseInt(costAdult));
      }
      var data = {
        people: this.selectedPeople,
        adult: this.selectedAdult,
        boy: this.selectedBoy,
        baby: this.selectedBaby,
        date: date,
        vendorId: this.$store.getters['whiteLabel:vendorId'],
        isFree: this.isFree,
        balance: balancePaid,
        totalBalance: balancePaid,
        payDetails: details,
        dateTime: this.selectedHour,
        referrer: referrer,
        provider: provider,
        comments: '',
        isEvent: false,
        userId: '',
        email: '',
        phone: '',
        name: '',
        decoration: false,
        dataBrowser: dataBrowser,
        zone: this.selectedZone,
        costBoy: (zoneBoyCost) ? parseInt(zoneBoyCost) : null,
        documentType: null,
        identityDocument: null,
        typeReservation: this.selectedTypeReservation,
        tableId: this.selectedTable,
        giftcardId: null,
        giftcardPrice: 0
      };
      if (this.reservationEvent) {
        if (this.reservationEvent.purchaseCommission === null || this.reservationEvent.purchaseCommission === 0) {
          data.isFree = true;
          data.balance = 0;
        } else {
          data.isFree = false;
          data.balance =
            this.selectedPeople *
            parseInt(this.reservationEvent.purchaseCommission);
          data.payDetails = {
            adult: parseInt(this.reservationEvent.purchaseCommission)
          };
        }
        data.eventDateId = this.reservationEvent.eventDateId;
        data.eventId = this.reservationEvent.eventId;
        data.isEvent = true;
        data.reservationEvent = this.reservationEvent.isCover;
      }
      if (this.selectedExperience) {
        data.experience = this.selectedExperience.displayName;
        if (parseInt(this.selectedExperience.purchaseCommission) > 0) {
          data.isFree = false;
          data.balance =
            this.selectedPeople *
            parseInt(this.selectedExperience.purchaseCommission);
          data.payDetails = {
            adult: parseInt(this.selectedExperience.purchaseCommission)
          };
        }
      }
      window.localStorage.setItem('_reservation', btoa(JSON.stringify(data)));
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
      this.validateSendReservation();
    },
    next () {
      if (
        this.selectedPeople !== null &&
        this.selectedDate === null &&
        this.selectedHour === null
      ) {
        document.getElementById('selectPeople').style.display = 'none';
        document.getElementById('selectDate').style.display = 'block';
        document.getElementById('siguienteButton').style.disabled = true;
        this.$store.commit(whiteLabelTypes.mutations.setSection, 2);
      } else if (
        this.selectedPeople !== null &&
        this.selectedDate !== null &&
        this.selectedHour === null
      ) {
        document.getElementById('selectPeople').style.display = 'none';
        document.getElementById('selectDate').style.display = 'none';
        document.getElementById('selectHour').style.display = 'flex';
        this.$store.commit(whiteLabelTypes.mutations.setSection, 3);
        if (document.getElementById('SwipeActive')) {
          setTimeout(() => {
            document.getElementById('SwipeActive').scrollIntoView();
          }, 100);
        }
      } else if (
        this.selectedPeople !== null &&
        this.selectedDate !== null &&
        this.selectedHour !== null
      ) {
        if (this.section === 1) {
          document.getElementById('selectPeople').style.display = 'none';
          document.getElementById('selectDate').style.display = 'block';
          document.getElementById('siguienteButton').style.disabled = true;
          this.$store.commit(whiteLabelTypes.mutations.setSection, 2);
        } else if (this.section === 2) {
          document.getElementById('selectPeople').style.display = 'none';
          document.getElementById('selectDate').style.display = 'none';
          document.getElementById('selectHour').style.display = 'flex';
          this.$store.commit(whiteLabelTypes.mutations.setSection, 3);
          if (document.getElementById('SwipeActive')) {
            setTimeout(() => {
              document.getElementById('SwipeActive').scrollIntoView();
            }, 100);
          }
        } else if (this.section === 3 && this.selectedHour !== null) {
          this.processReservation();
        }
      } else if (this.section === 3 && this.selectedHour !== null) {
        this.processReservation();
      }
    },
    validateDisabled () {
      if (this.section === 2) {
        return this.selectedDate ? 0 : 1;
      }
      if (this.section === 3) {
        return this.selectedHour ? 0 : 1;
      }
      if (this.value) {
        return 1;
      }
    },
    openSocial (social) {
      if (social === 'facebook') {
        window.open(this.vendor.facebook);
      } else if (social === 'twitter') {
        window.open(this.vendor.twitter);
      } else if (social === 'instagram') {
        window.open(this.vendor.instagram);
      } else if (social === 'whatsapp') {
        window.open(`https://wa.me/${this.vendor.whatsapp}`);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  #siguienteButton {
    margin-left: 0;
    border-radius: 5px;
    width: 95%;
    margin-left: 3%;
    display: flex;
    text-align: center;
    justify-content: center;
    bottom: 10px;
    backface-visibility: hidden;
  }
  .footer {
    padding-bottom: 50px !important;
  }
}
</style>
