<template>
  <div class="NavBar" v-if="vendor && design && params">
    <CustomVars></CustomVars>
    <b-navbar :class="{'paddingBorderLogo': (design.typeLogo == 'border'), 'notBox': isPhoneBurger}" class="container">
      <template slot="brand">
        <div class="volver-mobile">
          <a
            @click="previusSection(vendor.websiteUrl)"
            href="javascript:void(1)"
            v-if="back === 'home' && selectedTypeReservation === 'Normal'  || back === 'home' && selectedTypeReservation !== 'Normal'  "
          >
            <span>
              <i class="icon-mks icon_arrow_left"></i>
              <span>{{ $t('return') }}</span>
            </span>
          </a>
          <a
            @click="volverWeb(vendor.websiteUrl)"
            href="javascript:history.go(-1)"
            v-if="back === 'branch'"
          >
            <span>
              <i class="icon-mks icon_arrow_left"></i>
              <span>{{ $t('return') }}</span>
            </span>
          </a>
          <a
            @click="volverSelect()"
            href="javascript:void(0);"
            v-if="back !== 'home' && back !== 'branch'"
          >
            <span v-on:click="volver">
              <i class="icon-mks icon_arrow_left"></i>
              <span>{{ $t('return') }}</span>
            </span>
          </a>
        </div>
        <div class="navbar-start">
          <b-navbar-item
            :class="{'sinUrl': vendor.websiteUrl == null || vendor.websiteUrl == undefined || vendor.websiteUrl == '/' }"
            @click="volverWeb(vendor.websiteUrl)"
          >
            <div
              :class="{
                'logoBorder': (design.typeLogo == 'border'),
                'logoRibbon': (design.typeLogo == 'ribbon'),
                'padding': (design.typeLogo == 'basic2'),
              }"
              class="logoDiv"
            >
              <div class="centerlog" v-if="design.typeLogo == 'basic'">
                <img :src="vendor.lightLogoURL" />
              </div>
              <div class="centerlog" v-if="design.typeLogo == 'ancho'">
                <img :src="vendor.lightLogoURL" class="mx-auto max-h200" />
              </div>
              <div class="centerlog" v-if="design.typeLogo == 'basic2'">
                <img :src="vendor.lightLogoURL" class="basic2" />
              </div>
              <div class="centerlog" v-if="design.typeLogo == 'basic3'">
                <img :src="vendor.lightLogoURL" class="basic3" />
              </div>
              <div class="centerlog" v-if="design.typeLogo == 'border'">
                <img :src="vendor.lightLogoURL" class="mx-auto d-block img-border logo-border-img" />
              </div>
              <div class="centerlog" v-if="design.typeLogo == 'large'">
                <img :src="vendor.lightLogoURL" class="mx-auto d-block img-large" />
              </div>
              <div class="rabion" v-if="design.typeLogo == 'ribbon'">
                <svg height="160" width="100">
                  <polygon
                    class="bookmark"
                    data-v-368b4645
                    points="0,0 0,150 50,120 100,150 100,0"
                  />
                </svg>
                <img :src="vendor.lightLogoURL " />
              </div>
            </div>
          </b-navbar-item>
          <div class="volver-desktop">
            <a
              @click="changeTypeReservation()"
              href="javascript:void(0)"
              v-if="back === 'home' && selectedTypeReservation !== 'Normal'"
            >
              <span>
                <i class="icon_arrow_left icon-mks"></i>
                <span>{{ $t('return') }}</span>
              </span>
            </a>
            <a
              @click="volverWeb(vendor.websiteUrl)"
              href="javascript:void(1)"
              v-if="back === 'home' && selectedTypeReservation === 'Normal' && !isBranch"
            >
              <span>
                <i class="icon_arrow_left icon-mks"></i>
                <span>{{ $t('return') }}</span>
              </span>
            </a>
            <a
              @click="volverWeb(vendor.websiteUrl)"
              href="javascript:history.go(-1)"
              v-if="back === 'branch'"
            >
              <span>
                <i class="icon_arrow_left icon-mks"></i>
                <span>{{ $t('return') }}</span>
              </span>
            </a>
            <a href="javascript:history.go(-1)" v-if="isBranch">
              <span>
                <i class="icon_arrow_left icon-mks"></i>
                <span>{{ $t('return') }}</span>
              </span>
            </a>
            <a
              @click="volverSelect()"
              href="javascript:void(0);"
              v-if="back !== 'home' && back !== 'branch'"
            >
              <span v-on:click="volver">
                <i class="icon_arrow_left icon-mks"></i>
                <span>{{ $t('return') }}</span>
              </span>
            </a>
          </div>
        </div>
      </template>
      <template slot="start"></template>
      <template slot="end">
        <div
          id="socialRedes"
          v-if="vendor.facebook || vendor.instagram || vendor.twitter || vendor.whatsapp"
        >
          <i @click="openSocial('facebook')" class="icon_facebook" v-if="vendor.facebook"></i>
          <i @click="openSocial('twitter')" class="icon_twitter" v-if="vendor.twitter"></i>
          <i @click="openSocial('instagram')" class="icon_instagram" v-if="vendor.instagram"></i>
          <i @click="openSocial('whatsapp')" class="icon_whatsapp" v-if="vendor.whatsapp"></i>
        </div>
        <div class="navbar-end" v-if="!logged">
          <div class="navbar-item" v-if="isButton && !isPhoneBurger">
            <div @click="showLogin" class="field is-grouped">
              <button class="bd-tw-button button">{{ $t('login') }}</button>
            </div>
          </div>
          <div class="newMenuLogin" v-if="isButton && isPhoneBurger">
            <div class="newMenuLogin-content">
              <div @click="showLogin" class="newMenuLogin-content-item">
                <span class="newMenuLogin-content-item-text">{{ $t('login') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-end" v-if="logged">
          <b-navbar-dropdown
            :class="{'no-BACK': (!design.navBackgroundColor)}"
            :label=" user.displayName"
            class="accout-button"
            v-if="isButton && !isPhoneBurger"
          >
            <b-navbar-item v-if="parseInt(idUser) !== 13744">
              <router-link to="/account">
                <span>{{ $t('myAccount') }}</span>
                <img :src="user.avatar" class="user-image avatar" v-if="user.avatar !== null" />
                <span
                  class="initialCharacter avatar"
                  v-if="user.avatar === null"
                >{{ user.displayName.charAt(0) }}</span>
              </router-link>
            </b-navbar-item>
            <b-navbar-item v-if="logged || validateIsManagerAdmin()">
              <div @click="userLogout()" class="pointer">
                {{ $t('singOff') }} &nbsp;
                <i class="fa fa-sign-out"></i>
              </div>
            </b-navbar-item>
          </b-navbar-dropdown>
          <div class="newMenuLogin" v-if="isButton && isPhoneBurger">
            <div class="newMenuLogin-content">
              <div v-if="parseInt(idUser) !== 13744" @click="goToAccount()" class="newMenuLogin-content-item" style="margin-top: 20px;">
                <span class="newMenuLogin-content-item-text">{{ $t('myAccount') }}</span>
              </div>
              <div v-if="logged || validateIsManagerAdmin()" @click="userLogout()" class="newMenuLogin-content-item" style="margin-bottom: 20px;">
                <span class="newMenuLogin-content-item-text">{{ $t('singOff') }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-navbar>
    <loginModal
      :source="source"
      @close="showLogin"
      @validate-user="validateUser"
      v-if="showModalLogin"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import authTypes from '@/store/types/auth';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import _ from 'lodash';
import CustomVars from '@/components/customVars';
import loginModal from '@/components/Select/loginModal.vue';

export default {
  name: 'navBar',
  props: ['border', 'backUrl', 'isButton', 'back', 'page'],
  components: { CustomVars, loginModal },
  data () {
    return {
      userLogged: {
        email: null,
        password: null,
        repassword: null,
        name: null,
        phone: null,
        indicativo: null,
        bandera: null
      },
      emailReset: '',
      option: null,
      pais: null,
      paises: [],
      error: null,
      loading: false,
      loginSuccess: true,
      emailValid: true,
      AccountSimple: false,
      emailExist: false,
      recoverPassword: false,
      validationToken: false,
      showChangedPassword: false,
      token: '',
      rightLogo: false,
      tokenUser: null,
      resetSend: false,
      source: true,
      continius: false,
      LogSelection: 0,
      registerTab: false,
      isBranch: false,
      validateTel: '',
      isPhoneBurger: false,
      isPhoneBurgerLogin: false
    };
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser
    })
  },
  methods: {
    indicativeParam (indicativo) {
      const selectCountry = this.paises.find(value => value.value === indicativo);
      this.option = selectCountry;
      this.userLogged.indicativo = this.option.value;
      let aux2 = null;
      let ind = this.userLogged.indicativo;
      let label = this.option.label;
      _.forEach(BanderaPais, function (item) {
        let data2 = item.country + ' ( +' + ind + ' )';
        if (data2 === label) {
          aux2 = item.abbreviation.toLowerCase();
        }
      });
      this.userLogged.bandera = aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
    },
    updateData (input) {
      let copy = { ...this.userLogged };
      copy.email = input.replace(/\s+/g, '');
      this.userLogged = copy;
    },
    ...mapActions({
      logout: authTypes.actions.logout,
      loginInvite: authTypes.actions.loginInvite,
      validateToken: authTypes.actions.validateToken
    }),
    capturePress (event) {
      if (event.charCode === 32 || event.code === 'Space' || event.keyCode === 32 || event.key === ' ' || event.which === 32) {
        event.preventDefault();
      }
    },
    changeTypeReservation () {
      this.$parent.$emit('showTypeListReservation', true);
    },
    authInvite () {
      this.LogSelection = 2;
      this.$store.commit(whiteLabelTypes.mutations.setInvite, true);
      this.setKeyEventsAndroid();
    },
    authInviteBack () {
      this.LogSelection = 0;
      this.$store.commit(whiteLabelTypes.mutations.setInvite, false);
      this.setKeyEventsAndroid();
    },
    // ? se puede borrar cuando acabe
    popup (provider) {
      let x = window.location.href;
      x.replace('#block-hour', '');
      let referrer = this.encrypt(x);
      // URL a la que se redirigirá para iniciar sesión social
      const url = process.env.VUE_APP_URL_AUTH + '/login/social/' + provider + '/' + referrer;
      // Configuración de la ventana emergente
      const width = 600;
      const height = 400;
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;
      const options = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
      const popup = window.open(url, 'Login Social', options);
      window.addEventListener('message', event => {
        // Manejar los mensajes recibidos desde la ventana emergente
        const data = event.data;
        window.localStorage.setItem('_token', data.token);
        window.localStorage.setItem(
          '_user',
          btoa(JSON.stringify(data.user))
        );
        window.localStorage.setItem('_userId', data.userId);
        this.$store.commit(authTypes.mutations.setUser);
        this.$store.commit(authTypes.mutations.setUserId);
        this.validateSendReservation();
        popup.close();
      });
    },
    previusSection (websiteUrl) {
      if (this.section === 2) {
        this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
        if (window.localStorage.getItem('dayPeopleLimit') === undefined) {
          this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
        }
        this.$store.commit(whiteLabelTypes.mutations.setSection, 1);
        document.getElementById('selectPeople').style.display = 'flex';
        document.getElementById('selectDate').style.display = 'none';
        document.getElementById('siguienteButton').style.disabled = false;
      } else if (this.section === 3) {
        this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
        if (window.localStorage.getItem('dayPeopleLimit') === undefined) {
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        }
        this.$store.commit(whiteLabelTypes.mutations.setSection, 2);
        document.getElementById('selectDate').style.display = 'flex';
        document.getElementById('selectHour').style.display = 'none';
        document.getElementById('siguienteButton').style.disabled = false;
      } else if (this.selectedTypeReservation !== 'Normal') {
        this.$parent.$emit('showTypeListReservation', true);
      } else {
        this.$store.commit(
          reservationTypes.mutations.setSelectedTypeReservation,
          'Normal'
        );
        this.$store.commit(reservationTypes.mutations.setSelectedPeople, null);
        this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, null);
        this.$store.commit(reservationTypes.mutations.setSendReservation, false);
        window.location.href = websiteUrl;
      }
    },
    changedPaisSelectedNavBar (indicativo) {
      let aux2 = null;
      let aux3 = indicativo.label;
      this.pais = indicativo.label;
      this.userLogged.indicativo = indicativo.value;
      let ind = indicativo.value;
      _.forEach(BanderaPais, function (item) {
        let data2 = item.country + ' ( +' + ind + ' )';
        if (data2 === aux3) {
          aux2 = item.abbreviation.toLowerCase();
        }
      });
      this.userLogged.bandera =
        aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;

      switch (indicativo.value) {
        case 57:
          this.validateTel = 'required|min:10|max:10|numeric';
          break;
        case 507:
          this.validateTel = 'required|min:7|max:8|numeric';
          break;
        default:
          this.validateTel = 'required|min:7|max:11|numeric';
          break;
      }
    },
    validateBeforeLogin () {
      this.loginSuccess = true;
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.emailValid && !this.AccountSimple) {
            this.processLogin(this.userLogged);
          } else {
            if (this.registerTab === true) {
              this.registerUser(this.userLogged);
            }
            this.LogSelection = 1;
            this.registerTab = true;
          }
        }
      });
    },
    validateBeforeLoginInvite () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.processLoginInvite(this.userLogged);
        }
      });
    },
    processLoginInvite (user) {
      this.continius = true;
      this.loginInvite({ user: this.encrypt(13744), deviceId: this.deviceId, system: 'select', data: user })
        .then(response => {
          this.user.displayName = user.name;
          this.user.email = user.email;
          this.user.phone = user.phone;
          this.user.indicativo = user.indicativo;
          this.user.bandera = user.bandera;
          window.localStorage.setItem(
            '_user',
            btoa(JSON.stringify(this.user))
          );
          this.continius = false;
          this.$store.commit(
            whiteLabelTypes.mutations.setShowModalLogin,
            !this.showModalLogin
          );
          this.validateSendReservation();
        })
        .catch((error) => {
          this.continius = false;
          this.continius = false;
          this.loginSuccess = false;
          this.$buefy.snackbar.open({
            duration: 10000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i> <strong>" + error.response.data.message + '</span></strong>',
            type: 'is-warning',
            position: 'is-top-right',
            actionText: 'X',
            queue: false
          });
        });
    },
    removeWhiteSpace () {
      if (this.userLogged.email != null) {
        this.userLogged.email = this.userLogged.email.replace(/\s+/g, '');
        this.userLogged.email = this.userLogged.email.trim();
      }
    },
    validateUser () {
      this.$emit('validate-user', true);
    },
    danger (error) {
      this.$buefy.toast.open({
        duration: 5000,
        message: error,
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
    userLogout () {
      this.logout({ id: this.user.id, deviceId: this.deviceId, version: this.versionApp, system: 'select' }).then(() => {
        this.$store.commit(reservationTypes.mutations.setSendReservation, false);
        this.$store.commit(
          reservationTypes.mutations.setReservationDayUser,
          false
        );
        this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(reservationTypes.mutations.setSelectedTypeReservation, 'Normal');
        this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
        this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
        this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
        window.localStorage.removeItem('_user');
        window.location.href = '/';
      });
    },
    setKeyEventsAndroid () {
      setTimeout(() => {
        const ele = document.getElementById('emailTest1');
        const ele2 = document.getElementById('emailTest2');
        const ele3 = document.getElementById('emailTest3');
        if (ele != null) {
          ele.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
        if (ele2 != null) {
          ele2.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
        if (ele3 != null) {
          ele3.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
      }, 800);
    },
    showLogin () {
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalLogin,
        !this.showModalLogin
      );
      if (this.params.isLoginInvited) {
        this.authInvite();
      }
      this.setKeyEventsAndroid();
      let reservation = JSON.parse(atob(window.localStorage.getItem('_reservation')));
      if (reservation) {
        window.localStorage.setItem('deleteFuture', 1);
      }
      this.$store.commit(whiteLabelTypes.mutations.setDisabledOnLog, false);
    },
    changeAccountSimple (vare) {
      if (vare === 1) {
        this.registerTab = true;
        this.AccountSimple = true;
      }
      if (vare === 0) {
        this.registerTab = false;
        this.AccountSimple = false;
      }
      this.loginSuccess = true;
      this.emailExist = false;
      this.LogSelection = vare;
      this.setKeyEventsAndroid();
    },
    volver () {
      this.$store.commit(
        reservationTypes.mutations.setSelectedTypeReservation,
        'Normal'
      );
      this.$store.commit(reservationTypes.mutations.setSelectedPeople, null);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
      this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, null);
      this.$store.commit(reservationTypes.mutations.setSendReservation, false);
      if (this.page === 'checkout') {
        window.localStorage.setItem('deleteFuture', 1);
        this.$store.commit(whiteLabelTypes.mutations.setDisabledOnLog, false);
      }
    },
    volverWeb (websiteUrl) {
      if (websiteUrl !== null || websiteUrl !== undefined || websiteUrl !== '/') {
        this.$store.commit(
          reservationTypes.mutations.setSelectedTypeReservation,
          'Normal'
        );
        this.$store.commit(reservationTypes.mutations.setSelectedPeople, null);
        this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, null);
        this.$store.commit(reservationTypes.mutations.setSendReservation, false);
        window.location.href = websiteUrl;
      }
    },
    volverSelect () {
      window.location.href = '/';
    },
    validateIsManagerAdmin () {
      if (this.user.vendors && this.idVendor) {
        return _.includes(this.user.vendors, this.idVendor);
      }
      return false;
    },
    openSocial (social) {
      if (social === 'facebook') {
        window.open(this.vendor.facebook);
      } else if (social === 'twitter') {
        window.open(this.vendor.twitter);
      } else if (social === 'instagram') {
        window.open(this.vendor.instagram);
      } else if (social === 'whatsapp') {
        window.open(`https://wa.me/${this.vendor.whatsapp}`);
      }
    },
    goToAccount () {
      window.location.href = '/account';
    }
  },
  mounted () {
    this.isPhoneBurger = ((parseInt(window.innerWidth) <= 1023));
    window.addEventListener('resize', () => {
      this.isPhoneBurger = ((parseInt(window.innerWidth) <= 1023));
    });
    if (this.$route.query.bybranch !== undefined) {
      this.isBranch = 1;
    }
    this.paises = this.listCountry();
    let indicativo = this.$store.getters['whiteLabel:vendor'].params.indicativeRegion;
    if (indicativo === undefined || indicativo == null) {
      indicativo = 57;
    }
    this.indicativeParam(indicativo);
    if (this.params.isLoginInvited) {
      this.authInvite();
    } else {
      this.$store.commit(whiteLabelTypes.mutations.setInvite, false);
    }
    if (this.user.indicativo !== null && this.user.indicativo !== undefined) {
      let countrySelectec = this.changedPais(this.paises, this.user.indicativo);
      if (countrySelectec !== undefined) {
        this.option = countrySelectec.option;
        this.pais = countrySelectec.country;
        this.userLogged.bandera = countrySelectec.flag;
        this.userLogged.indicativo = countrySelectec.indicative;
      }
    } else if (this.params.indicativeRegion) {
      let contCountry = this.changedPais(this.paises, '' + this.params.indicativeRegion);
      if (contCountry) {
        this.option = {
          value: contCountry.indicative,
          label: contCountry.country
        };
        this.userLogged.indicativo = '' + contCountry.indicative;
        this.changedPaisSelectedNavBar(this.option);
      }
    }
    this.setKeyEventsAndroid();
    this.source = this.getInApp(navigator.userAgent || navigator.vendor || window.opera);
    switch (indicativo) {
      case 57:
        this.validateTel = 'required|min:10|max:10|numeric';
        break;
      case 507:
        this.validateTel = 'required|min:7|max:8|numeric';
        break;
      default:
        this.validateTel = 'required|min:7|max:11|numeric';
        break;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/Login/styles";
.volver-desktop,
.volver-mobile {
  a {
    span {
      display: flex;
      align-items: center;
      i {
        background-color: var(--navColor);
      }
      span {
        padding-bottom: 5px;
      }
    }
  }
}
.p-normal-center {
  text-align: center;
}
/* GENERAL */
.avatar {
  border-radius: 99rem;
  height: 1rem;
  width: 1rem;
  margin-left: 5px;
}
.accout-button {
  .navbar-dropdown {
    right: 0;
    left: auto;
    border-radius: 10px;
  }
}
#create-user {
  color: #000;
}
.modal-box {
  background-color: #fff;
  display: flex;
  padding: 50px 20px 40px 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .login-box {
    width: 100%;
  }
  .modal-close {
    display: none;
  }
  .modal-title {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: italic;
    margin-bottom: 20px;
  }
  .sub-text {
    color: #000;
    text-align: center;
    margin-bottom: 10px;
  }
  .error-message {
    text-align: center;
    color: #ff9b9b;
    padding-top: 10px;
  }
}
.rabion {
  position: relative;
  align-self: flex-start;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
  }
}

.close-btn {
  position: absolute;
  background: none;
  border: none;
  font-size: 30px;
  right: 0;
  top: 0;
  margin-top: -5px;
  cursor: pointer;
  color: #000;
}
.btn-verification {
  margin-top: 20px;
}
.botom-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.resetButon {
  font-size: 13px;
}
.btn-invt {
  text-decoration: underline;
  margin-top: 10px;
  font-weight: 500;
}
.titleColor {
  color: #000;
}
.backgColor {
  color: #000;
}
.btn-login {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: var(--cardBackgroundColor);
  background: var(--cardTitleColor);
  border-color: var(--cardBackgroundColor);
  margin-top: 10px;
}
.pre-message {
  color: #000;
  font-size: 15px;
  width: 100%;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.social-box {
  .btn-social {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  .btn-facebook {
    color: #fff;
    background: #3b5998;
    border-color: #3b5998;
  }

  .btn-google {
    color: #fff;
    background: #dd4b39;
    border-color: #dd4b39;
  }
}
.centerlog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.NavBar {
  background: var(--navBackgroundColor) !important;
  position: relative;
  z-index: 9;
  border-bottom: 1px solid #ccc;
  .volver-mobile {
    position: absolute;
    margin-left: 14px;
    display: none;
  }
  .volver-desktop {
    display: flex;
    align-items: center;
  }
  .navbar {
    background-color: var(--navBackgroundColor);
    max-height: 80px;
    .navbar-start {
      width: 100%;
      a {
        color: var(--navColor) !important;
        span {
          color: var(--navColor) !important;
        }
      }
    }
    a {
      color: var(--navColor) !important;
    }
    .navbar-item {
      color: var(--navColor) !important;
      padding: 5px 25px;
      text-align: center;
      &.sinUrl {
        cursor: inherit;
      }
    }
    .logoDiv {
      margin-top: 0px !important;
      text-align: center;
      margin-right: 20px;

      .rabion img {
        height: auto;
        max-width: 100%;
        max-height: 100%;
        padding: 10px;
      }

      img {
        height: auto;
        max-width: 150px;
        max-height: 80px;
        padding: 10px;
      }
    }
    .navbar-item,
    .navbar-link {
      color: var(--navColor);
      button {
        color: var(--navColor) !important;
        background: transparent;
        font-size: 14px;
        padding: 0 15px;
        border: 1px solid var(--navColor) !important;
      }
    }
    .navbar-dropdown {
      z-index: 99999 !important;
      position: absolute;
      li {
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
        color: var(--navColor);
        border: 1px solid var(--navColor);
        background: var(--navBackgroundColor);
        a,
        div {
          text-align: center;
          width: 100%;
        }
      }
    }
    .borderColor {
      border-bottom: 2px solid var(--borderColor) !important;
    }
    .backLightColor {
      background-color: var(--lightColor);
    }
    .backgroundColor {
      background-color: var(--backgroundColor);
    }
    .border-navbar {
      background-color: var(--navBackgroundColor);
      color: var(--navColor);
      border: 1px solid var(--navColor) !important;
    }
    .logo-border-img {
      background-color: var(--navBackgroundColor);
      border-color: var(--borderColor);
    }
  }
}
.select:not(.is-multiple) {
  height: 2.25em;
  width: 100%;
}
.img-border {
  z-index: 2;
  padding: 5px;
  margin-top: 6px;
  border: 2px solid;
}
.img-large {
  padding: 10px;
  top: 0;
  margin-top: -20px;
}
.cont-nav {
  padding: 10px 10px !important;
}

.logoRibbon {
  position: relative;
  align-self: flex-start;
}

.navbar-dropdown.is-right {
  left: auto;
  right: 0;
  background: transparent;
  display: block !important;
  height: auto;
  opacity: 0;
  padding: 10px 0 0 0;
  font-size: 15px;
  z-index: 99999;
}

.logo-right {
  padding: 0 !important;
  width: 100% !important;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.6);
}
@media (max-width: 959px) {
  #giftCard-button {
  }
}
@media (max-height: 710px) {
  .logo-right {
    padding: 0 !important;
    width: 100% !important;
    height: 500px !important;
  }
}
@media (min-width: 750px) and (max-width: 1025px) {
  .recovery-tablet {
    padding: 0 !important;
    width: 100%;
  }
}
@media (min-width: 1025px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media (max-height: 755px) {
  .logo-recovery-tablet {
    height: 140px !important;
  }
}
@media (max-width: 768px) {
  .NavBar {
    .navbar-start {
      align-items: center;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
    }
    .volver-mobile {
      display: block;
    }
    .volver-desktop {
      display: none;
    }
  }
  .logo-right {
    padding: 0 !important;
    width: 100% !important;
    height: 500px !important;
  }
  .logoRiboon {
    margin-top: -15px;
  }
  .navbar .logoDiv img {
    height: auto;
    max-width: 150px;
    padding: 5px;
    margin-bottom: 0px;
  }
  .basic-mq {
    width: 100%;
  }
  .basic2 {
    max-width: 50%;
    margin-top: 15px;
  }
  .paddingBorderLogo {
    padding: 15px 10px;
  }
}
.bookmark {
  fill: #fff; /* sustituir */
  fill-rule: evenodd;
}

.dropdown-item:focus,
.dropdown-item:hover {
  text-decoration: none;
  background-color: transparent !important;
  font-weight: 900;
  font-size: 14px;
}
.img-phone-country {
  background-position: 95%;
}
#socialRedes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  .icon_facebook {
    display: flex;
    margin-right: 5px;
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    height: 20px;
    width: 20px;
    margin-top: 3px;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    mask-size: contain;
    background: var(--navColor);
    -webkit-mask-image: url(../../assets/images/icons/icons8-facebook-old.svg);
    mask-image: url(../../assets/images/icons/icons8-facebook-old.svg);
    cursor: pointer;
  }
  .icon_instagram {
    display: flex;
    margin-right: 5px;
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    height: 20px;
    width: 20px;
    margin-top: 3px;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    mask-size: contain;
    background: var(--navColor);
    -webkit-mask-image: url(../../assets/images/icons/icon-instagram.svg);
    mask-image: url(../../assets/images/icons/icon-instagram.svg);
    cursor: pointer;
  }
  .icon_twitter {
    display: flex;
    margin-right: 5px;
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    height: 20px;
    width: 20px;
    margin-top: 3px;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    mask-size: contain;
    background: var(--navColor);
    -webkit-mask-image: url(../../assets/images/icons/icon-twitter.svg);
    mask-image: url(../../assets/images/icons/icon-twitter.svg);
    cursor: pointer;
  }
  .icon_whatsapp {
    display: flex;
    margin-right: 5px;
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    height: 20px;
    width: 20px;
    margin-top: 3px;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    mask-size: contain;
    background: var(--navColor);
    -webkit-mask-image: url(../../assets/images/icons/icon_whatsapp.svg);
    mask-image: url(../../assets/images/icons/icon_whatsapp.svg);
    cursor: pointer;
  }
}
@media (max-width: 1024px) {
  #socialRedes {
    display: none;
  }
}
@media (max-width: 500px){
  .volver-mobile{
    a {
      span {
        span{
          display: none;
        }
      }
    }
  }
}
.newMenuLogin {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  @media screen and (max-width: 500px) {
    padding-right: unset;
    justify-content: center;
  }
  &-content {
    width: 394px;
    min-height: 70px;
    max-height: 111px;
    flex-shrink: 0;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #FFF;
    background: rgba(0, 0, 0, 0.80);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 413px) {
      width: 355px;
    }
    &-item {
      width: 334px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 6px;
      border: 1px solid #FFF;
      opacity: 0.8;
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &-text {
        color: #FFF;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
</style>
